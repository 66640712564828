import { Fragment, useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import Footer from "../../components/footer";
import Sidebar from "../../components/sidebar";

import routes from "../../routes/routes";

import logo from "../../assets/logo/logo.png";

// demo views
import IconsDemo from "../../demos/Icons";

// CSS
import "./styles.css";

// Views
import PlansView from "../../pages/plans";
import KitsView from "../../pages/kits";
import GraduationsView from "../../pages/graduations";
import { useUser } from "../../contexts/user/UserWrapper";
import ConfigView from "../../pages/config";
import GeneralView from "../../pages/general";
import AffiliatesView from "../../pages/affiliates";
import Language from "../../language/language";
import { useLanguage } from "../../contexts/language/LanguageWrapper";
import { monthlyPlan } from "../../api/plans";
import MonthlyView from "../../pages/monthly";
import FinanceView from "../../pages/finances";
import UsersView from "../../pages/users";
import ProdutsView from "../../pages/produts";
import StoreView from "../../pages/store";
import ProdCategorysView from "../../pages/produts/categorys";
import ExpertView from "../../pages/expert";
import ModulesView from "../../pages/expert/modules";
import CoursesView from "../../pages/courses";
import MyCoursesView from "../../pages/courses/myCourses";
import CourseModulesView from "../../pages/courses/modules";
import ContentView from "../../pages/courses/content";
import CategorysView from "../../pages/categorys";
import RankView from "../../pages/rank";
import ProfileView from "../../pages/profile";
import TopBar from "../../components/topBar";
import { meAuthApp } from "../../api/melhorEnvio";
import { Toast } from "primereact/toast";
import RequestsView from "../../pages/requests";

var ps: any;

function CustomLayout(props: any) {
  const location = useLocation();
  const { user } = useUser();
  const { language } = useLanguage();
  const navigate = useNavigate();

  const mainPanelRef = useRef(null);
  const [pix, setPix] = useState<any>();
  const [sidebarOpened, setsidebarOpened] = useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );

  const toast = useRef<any>(null);

  const monthly = async () => {
    if (!user?._id) {
      alert(`${Language(language).SomethingWrong} ${Language(language).RefreshPage}`);
      return;
    }

    if (!user.plan_id) return;

    await monthlyPlan(user._id, user.plan_id)
      .then((response) => {
        setPix(response.data);
      })
      .catch((e) => {
        console.log('monthlyPlan error', e);
      })
  }
  
  useEffect(() => {
    if (
      user && !user.is_root && 
      !user.is_special && 
      !user.kit_id &&
      user.plan_id && 
      !user.is_active && 
      !pix
    ) {
      monthly();
    }
  }, [user]);
  
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current || '', {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
    // if (mainPanelRef.current) {
    //   mainPanelRef.current.scrollTop = 0;
    // }
  }, [location]);

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };

  const getBrandText = (path: any) => {
    for (let i = routes.length - 1; i >= 0; i--) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        if (routes[i].title) return routes[i].title;

        return routes[i].title || routes[i].name;
      }
    }
    return "Brand";
  };

  useEffect(() => {
    if (!user) navigate('/login');

    else if (
      !user.plan_id && 
      !user.kit_id && 
      !user.last_payment && 
      !user.is_root && 
      !user.is_special
    ) {
      navigate('/selectPlan');
    }

    else if (
      !user.plan_id && 
      user.kit_id && 
      !user.last_payment && 
      !user.is_root && 
      !user.is_special
    ) {
      navigate('/go/requests');
    }
  }, []);

  return (
    <Fragment>
      <Toast ref={toast} />
      
      <div className="wrapper">
        <Sidebar
          routes={routes}
          logo={{
            text: "Go Winner",
            imgSrc: logo,
          }}
          toggleSidebar={toggleSidebar}
        />
        <div className="main-panel" ref={mainPanelRef}>
          <TopBar
            brandText={getBrandText(location.pathname)}
            toggleSidebar={toggleSidebar}
            sidebarOpened={sidebarOpened}
          />
          <Routes>
            <Route path="/affiliates" element={pix?.payload ? <MonthlyView pix={pix} /> : <AffiliatesView />} />
            <Route path="/learn/:id/:course_id" element={pix?.payload ? <MonthlyView pix={pix} /> : <ContentView />} />
            <Route path="/courses/:id" element={pix?.payload ? <MonthlyView pix={pix} /> : <CourseModulesView />} />
            <Route path="/courses" element={pix?.payload ? <MonthlyView pix={pix} /> : <CoursesView />} />
            <Route path="/mycourses" element={pix?.payload ? <MonthlyView pix={pix} /> : <MyCoursesView />} />
            <Route path="/expert/:id" element={pix?.payload ? <MonthlyView pix={pix} /> : <ModulesView />} />
            <Route path="/expert" element={pix?.payload ? <MonthlyView pix={pix} /> : <ExpertView />} />

            <Route path="/requests" element={<StoreView />} />
            <Route path="/logistics" element={<RequestsView />} />
            <Route path="/store" element={<StoreView store />} />
            <Route path="/profile" element={<ProfileView />} />
            <Route path="/plans" element={<PlansView />} />
            <Route path="/kits" element={<KitsView />} />
            <Route path="/produts" element={<ProdutsView />} />
            <Route path="/prodCategorys" element={<ProdCategorysView />} />
            <Route path="/rank" element={<RankView />} />
            <Route path="/graduations" element={<GraduationsView />} />
            <Route path="/categorys" element={<CategorysView />} />
            <Route path="/finance" element={<FinanceView />} />
            <Route path="/users" element={<UsersView />} />
            <Route path="/config" element={<ConfigView />} />
            <Route path="/icons" element={<IconsDemo />} />

            <Route path="/" element={<GeneralView pix={pix} />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

export default CustomLayout;
