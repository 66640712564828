import axios from "axios"
import config from "../../config"

export const getModuleById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}modules/getOne/${id}`)

  return response;
}

export const createModule = async (data: iModule) => {
  const response = await axios.post(`${config.API_URL}modules/post`, data);

  return response;
}

export const getModules = async (course_id: string) => {
  const response = await axios.get(`${config.API_URL}modules/getAll/${course_id}`)

  return response;
}

export const updateModule = async (id: string, data: iModule) => {
  const response = await axios.patch(`${config.API_URL}modules/update/${id}`, data);

  return response;
}

export const deleteModule = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}modules/delete/${id}`);

  return response;
}
