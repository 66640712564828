import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import { createCategory, deleteCategory, getCategorys, updateCategory } from "../../api/categorys";
import SanitizedInput from "../../components/sanitizedInput";

function CategorysView(props: any) {
  const [recordDialog, setRecordDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<iCategory[]>([]);
  const [fieldError, setFieldError] = useState('');
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<string | undefined>();

  const toast = useRef<any>(null);

  const emptyRecord: iCategory = {
    name: '',
  }
  const [record, setRecord] = useState<iCategory>(emptyRecord);

  const hideDialog = () => {
    setRecord(emptyRecord);
    setRecordDialog(false);
  };

  const saveRecord = async () => {
    if (record.name.length < 3) {
      setFieldError('name');
      alert('Informe um nome válido');
      return;
    };

    delete record.__v;

    if (!record._id) {
      // Criando novo registro
      await createCategory(record)
        .then(() => {
          toast.current.show({ 
            severity: "success", 
            summary: "Sucesso", 
            detail: "Registro salvo com sucesso.", 
            life: 3000
          });

          hideDialog();

          loadRecords();

          setRecord(emptyRecord);
        })
        .catch(e => {
          console.log('createCategory error', e);
          if (e.response?.data) alert(e.response.data);
        })
        .finally(() => setLoading(false));
    } else {
      // Editando registro
      await updateCategory(record._id, record)
        .then(() => {
          toast.current.show({ 
            severity: "success", 
            summary: "Sucesso", 
            detail: "Registro salvo com sucesso.", 
            life: 3000
          });

          hideDialog();

          loadRecords();
        })
        .catch(e => {
          console.log('updateCategory error', e);
          if (e.response?.data) alert(e.response.data);
        })
        .finally(() => setLoading(false));
    }
  }

  const loadRecords = async () => {
    await getCategorys()
      .then((response) => {
        setRecords(response.data);
      })
  }

  const deleteRecord = async (id?: string) => {
    if (!id) return;

    setDeleting(true);

    await deleteCategory(id)
      .then(() => {
        toast.current.show({ 
          severity: "success", 
          summary: "Sucesso", 
          detail: "Registro deletado.", 
          life: 3000
        });

        loadRecords();
      })
      .catch(e => {
        console.log('deleteCategory error', e);
        if (e.response?.data) alert(e.response.data);
      })
      .finally(() => {
        setDeleting(false);

        setDeleteID(undefined);
      });
  }

  const recordDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={hideDialog} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={saveRecord} >
        {loading ? "Salvando..." : "Salvar"}
      </Button>
    </>
  );

  const deleteDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => {
        hideDialog();

        setDeleteID(undefined);
      }} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => deleteRecord(deleteID)} >
        {deleting ? "Excluindo..." : "Sim, excluir!"}
      </Button>
    </>
  );

  const _renderEmpty = () => {
    return (<span>Sem registros.</span>)
  }

  const _renderCategorys = () => {
    return records.map((item: iCategory) => {
      return (
        <tr>
          <th>{item.name}</th>
          <th>
            <Button 
              style={{padding: 7}}
              color="success" 
              className="animation-on-hover" 
              onClick={() => {
                setRecord(item);
                setRecordDialog(true);
              }}
            >
              <i className="pi pi-pencil" />
            </Button>
            {" "}
            <Button 
              style={{padding: 7}}
              color="danger" 
              className="animation-on-hover" 
              onClick={() => setDeleteID(item._id)}
            >
              <i className="pi pi-trash" />
            </Button>
          </th>
        </tr>
      );
    })
  }

  useEffect(() => {
    loadRecords();
  }, [])

  return (
    <>
      <div className="content">
        <Toast ref={toast} />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Categorias{"  "}
                  <Button 
                    style={{padding: 7}}
                    color="info" 
                    className="animation-on-hover" 
                    onClick={() => setRecordDialog(true)}
                  >
                    <i className="pi pi-plus" />
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {records.length === 0 ? (
                  _renderEmpty()
                ) : (
                  <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Nome</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_renderCategorys()}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Modal adicionar/editar */}
      <Dialog
        visible={recordDialog}
        style={{ 
          width: "600px",
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={record?._id ? "Editar Categoria" : "Nova Categoria"}
        modal
        className="p-fluid"
        footer={recordDialogFooter}
        onHide={hideDialog}
      >
        <Card style={{padding: 10}}>
          {record._id ? (
            <label>{record._id}</label>
          ) : null}
          <Row>
            <Col md="6">
              <label>Nome *</label>
              <SanitizedInput
                style={fieldError === 'name' ? {borderColor: 'red'} : {}}
                value={record.name}
                onChange={(e: any) => {
                  if (fieldError === 'name') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    }
                  });
                }}
                placeholder={'Nome'}
                type="text"
                size={50}
              />
            </Col>
          </Row>
        </Card>
      </Dialog>

      {/* Modal pra excluir */}
      <Dialog
        visible={!(typeof deleteID === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={"Excluir?"}
        modal
        className="p-fluid"
        footer={deleteDialogFooter}
        onHide={() => {
          hideDialog();
          setDeleteID(undefined);
        }}
      >
        Excluir registro?
      </Dialog>
    </>
  );
}

export default CategorysView;
