import { useContext, useState } from "react";
import { LanguageContext } from "./LanguageContext";

export default function ThemeContextWrapper(props: any) {
  const [language, setLanguage] = useState('ptbr');

  function changeLanguage(language: any) {
    setLanguage(language);
  }

  return (
    <LanguageContext.Provider value={{ language: language, changeLanguage: changeLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
}

export const useLanguage = () => useContext(LanguageContext);
