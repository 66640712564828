import axios from "axios"
import config from "../../config"

export const getProdCategoryById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}prodCategorys/getOne/${id}`)

  return response;
}

export const createProdCategory = async (data: iProdutCategory) => {
  const response = await axios.post(`${config.API_URL}prodCategorys/post`, data);

  return response;
}

export const getProdCategorys = async () => {
  const response = await axios.get(`${config.API_URL}prodCategorys/getAll`)

  return response;
}

export const updateProdCategory = async (id: string, data: iProdutCategory) => {
  const response = await axios.patch(`${config.API_URL}prodCategorys/update/${id}`, data);

  return response;
}

export const deleteProdCategory = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}prodCategorys/delete/${id}`);

  return response;
}
