import axios from "axios"
import config from "../../config"

export const getProdutById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}produts/getOne/${id}`)

  return response;
}

export const getProdutByCod = async (cod: string | number) => {
  const response = await axios.get(`${config.API_URL}produts/findByCod/${cod}`)

  return response;
}

export const createProdut = async (data: iProdut, files?: any) => {
  const formData = new FormData();

  if (files) files.forEach((element: any) => {
    formData.append('file', element);
  });

  formData.append('body', JSON.stringify(data));

  const response = await axios.post(`${config.API_URL}produts/post`, formData);

  return response;
}

export const getProduts = async () => {
  const response = await axios.get(`${config.API_URL}produts/getAll`)

  return response;
}

export const updateProdut = async (id: string, data: iProdut, files?: any) => {
  const formData = new FormData();

  if (files) files.forEach((element: any) => {
    formData.append('file', element);
  });

  formData.append('body', JSON.stringify(data));

  const response = await axios.patch(`${config.API_URL}produts/update/${id}`, formData);

  return response;
}

export const deleteProdut = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}produts/delete/${id}`);

  return response;
}
