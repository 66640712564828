/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Row,
} from "reactstrap";
import { useUser } from "../../contexts/user/UserWrapper";
import Language from "../../language/language";
import { useLanguage } from "../../contexts/language/LanguageWrapper";
import { useNavigate } from "react-router-dom";
import CopyLink from "../copyLink";
import { useConfig } from "../../contexts/config/ConfigWrapper";
import SanitizedInput from "../sanitizedInput";
import "./styles.css"

function TopBar(props: iProps) {
  const { user, handleUser } = useUser();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { config } = useConfig();

  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    // if (window.innerWidth < 993 && collapseOpen) {
    //   setcolor("bg-white");
    // } else {
    //   setcolor("navbar-transparent");
    // }
    setcolor("navbar-transparent");
  };

  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };

  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  const logout = () => {
    handleUser(undefined);

    localStorage.clear();

    navigate('/');
  }

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });

  return (
    <>
      <Navbar className={classNames("navbar-absolute", color)} expand="lg">
        <Container className="top-container" fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
          </div>

          <h5 style={{
            margin: 0,
          }}>{props.brandText}</h5>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <SanitizedInput placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>
    </>
  );
}

export default TopBar;

interface iProps {
  title?: string,
  brandText?: string,
  toggleSidebar?: any,
  sidebarOpened?: boolean,
  showMembersArea?: boolean,
}
