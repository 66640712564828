import axios from "axios"
import config from "../../config"

export const upload = async (file: any, dir: string = 'global') => {
  try {
    const formData = new FormData();

    formData.append('file', file);

    formData.append('dir', dir);

    const response = await axios.post(`${config.API_URL}file/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
    return response.data;
    
  } catch (error) {
    console.log('File error', error);
    throw new Error('File upload error!')
  }
}
