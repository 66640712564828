import axios from "axios"
import config from "../../config"

export const getAddressById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}addresses/getOne/${id}`)

  return response;
}

export const getAddressByUserId = async (id: string) => {
  const response = await axios.get(`${config.API_URL}addresses/findByUserId/${id}`)

  return response;
}

export const createAddress = async (data: iAddress) => {
  const response = await axios.post(`${config.API_URL}addresses/post`, data);

  return response;
}

export const getAddresss = async () => {
  const response = await axios.get(`${config.API_URL}addresses/getAll`)

  return response;
}

export const updateAddress = async (id: string, data: iAddress) => {
  const response = await axios.patch(`${config.API_URL}addresses/update/${id}`, data);

  return response;
}

export const deleteAddress = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}addresses/delete/${id}`);

  return response;
}
