/*eslint-disable*/
import React, { CSSProperties } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, NavLink as ReactstrapNavLink, Row } from "reactstrap";
import { useUser } from "../../contexts/user/UserWrapper";

import "./styles.css"
import Language from "../../language/language";

import leaveIcon from '../../assets/icons/leave.svg';
import whatsappIcon from '../../assets/icons/whatsapp.svg';
import telegramIcon from '../../assets/icons/telegram.svg';
import { useConfig } from "../../contexts/config/ConfigWrapper";

var ps: any;

function Sidebar(props: any) {
  const location = useLocation();
  const { user, handleUser } = useUser();
  const navigate = useNavigate();
  const { config } = useConfig();

  const sidebarRef = React.useRef(null);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: any) => {
    return location.pathname === routeName ? "active" : "";
  };

  const logout = () => {
    handleUser(undefined);

    localStorage.clear();

    navigate('/');
  }

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current || '', {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  const linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };

  const { routes, rtlActive, logo } = props;

  let logoImg = null;
  let logoText = null;

  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </a>
      );

      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </Link>
      );

      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </Link>
      );
    }
  }

  return (
    // @ts-ignore
    <div className="sidebar side-container" data={'blue'}>
      <div style={{ 
        display: 'flex',
        flexDirection: 'column',
      }} className="sidebar-wrapper" ref={sidebarRef}>
        <img style={{
          alignSelf: 'center',
        }} src={logo.imgSrc} className="image-logo" alt="logo" />
        <Nav>
          {/* <li className="menu-text">
            <NavLink
              to=""
              className="nav-link"
              onClick={props.toggleSidebar}
            >
              Menu
            </NavLink>
          </li> */}
          {routes?.map((prop: any, key: any) => {
            if (prop.redirect) return null;

            if (!user?.is_root && prop.is_root) return null;

            if (!prop.store && user?.kit_id && !user.last_payment && !user?.is_root)
              return null;

            return (
              <li
                className={
                  activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                }
                key={key}
              >
                <NavLink
                  to={prop.layout + prop.path}
                  className="nav-link"
                  onClick={props.toggleSidebar}
                >
                  <Row style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    {prop.svg ? (
                      <img style={svgIcon} src={prop.svg} alt='' />
                    ) : (
                      <i className={prop.icon} />
                    )}
                    <h5 style={itemText}>{prop.name}</h5>
                  </Row>
                </NavLink>
              </li>
            );
          })}

          {config && config.whatsapp !== '' ? (
            <li>
              <a href={config.whatsapp}>
                <Row style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <img style={svgIcon} src={whatsappIcon} alt='' />
                  <h5 style={itemText}>{config.whatsapp_title}</h5>
                </Row>
              </a>
            </li>
          ) : null}
                
          {config && config.telegram !== '' ? (
            <li>
              <a href={config.telegram}>
                <Row style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <img style={svgIcon} src={telegramIcon} alt='' />
                  <h5 style={itemText}>{config.telegram_title}</h5>
                </Row>
              </a>
            </li>
          ) : null}

          <li>
            <NavLink
              to={'/'}
              className="nav-link"
              onClick={logout}
            >
              <Row style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <img style={svgIcon} src={leaveIcon} alt='' />
                <h5 style={itemText}>{Language().LogOut}</h5>
              </Row>
            </NavLink>
          </li>

          {user?.is_root ? (
            <>
              <li>
                <ReactstrapNavLink href="https://demos.creative-tim.com/black-dashboard-react/?_ga=2.67663250.225023858.1713029824-1118944140.1712667061#/documentation/tutorial">
                  <i className="tim-icons icon-spaceship" />
                  <p>Dashboard</p>
                </ReactstrapNavLink>
              </li>
              <li>
                <ReactstrapNavLink href="https://www.primefaces.org/diamond/icons.xhtml">
                  <i className="tim-icons icon-spaceship" />
                  <p>Pi Icons</p>
                </ReactstrapNavLink>
              </li>
            </>
          ) : null}
        </Nav>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
  toggleSidebar: PropTypes.func,
};

const svgIcon: CSSProperties = {
  height: '1.5rem',
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 5,
  marginRight: 15,
  color: '#CCAA50',
}

const itemText: CSSProperties = {
  marginBottom: 0,
  marginTop: 0,
  fontWeight: 'bold',
  color: '#CCAA50',
  textTransform: 'none',
}

export default Sidebar;
