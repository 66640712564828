import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { getRank } from "../../api/users";

function RankView() {
  const toast = useRef<any>(null);

  const [rank, setRank] = useState<iRank>();

  const loadRank = async () => {
    await getRank()
      .then((response) => {
        setRank(response.data);
      })
      .catch(e => console.log('getRank error', e));
  }

  useEffect(() => {
    loadRank();
  }, [])
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <div>
          <Card style={{padding: 20}}>
            <CardHeader>
              <h4 className="title">Ranking</h4>
            </CardHeader>
            <CardBody>
              <Row>
                {rank ? (
                  <>
                    <Col style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <h5>
                        Indicações na semana
                      </h5>
                      {rank.weekDirects.map((item, index) => {
                        return (
                          <label className="graduation-text">{`${index + 1}º - ${item.name} ${item.lastname || ''} - ${item.affiliates}`}</label>
                        )
                      })}
                    </Col>

                    <Col style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <h5>
                        Indicados diretos
                      </h5>
                      {rank.directs.map((item, index) => {
                        return (
                          <label className="graduation-text">{`${index + 1}º - ${item.name} ${item.lastname || ''} - ${item.affiliates}`}</label>
                        )
                      })}
                    </Col>

                    <Col style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <h5>
                        Rede
                      </h5>
                      {rank.networks.map((item, index) => {
                        return (
                          <label className="graduation-text">{`${index + 1}º - ${item.name} ${item.lastname || ''} - ${item.affiliates}`}</label>
                        )
                      })}
                    </Col>
                  </>
                ) : null}
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default RankView;
