import { CSSProperties, useEffect, useState } from 'react'
import { Button, CardBody, DropdownItem, NavLink, Row } from 'reactstrap';

import logo from '../../assets/logo/logo.png';
import './styles.css'
import { useLanguage } from '../../contexts/language/LanguageWrapper';
import Language from '../../language/language';
import { userForgotPassword, userLogin } from '../../api/users';
import { useUser } from '../../contexts/user/UserWrapper';
import { useNavigate } from 'react-router-dom';
import SanitizedInput from '../../components/sanitizedInput';
import { Dialog } from 'primereact/dialog';

import userIcon from '../../assets/icons/User.svg';
import lockIcon from '../../assets/icons/Lock.svg';

function Login() {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { user, handleUser, loading } = useUser();

  const [login, setLogin] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [keeppLogged, setKeepLogged] = useState(true);
  const [logging, setLogging] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgetting, setForgetting] = useState(false);
  const [forgotInfo, setForgotInfo] = useState<string>();

  const logUser = async () => {
    setLogging(true);
    if (!login || login.length < 2) {
      setLoginError(true);
      alert(Language(language).EnterEmailOrUsername);
      setLogging(false);
      return;
    }

    if (!password || password.length < 8) {
      setPasswordError(true);
      alert(Language(language).EnterPasswordToLogin);
      setLogging(false);
      return;
    }

    const filter = {
      password: password,
      userlogin: login,
    }

    await userLogin(filter)
      .then(response => {
        if (response.data?.token) {
          const userLogged: any = response.data;
          const logged = {
            id: userLogged._id,
            token: userLogged.token,
          }

          delete userLogged.__v;

          handleUser(userLogged);

          if (keeppLogged) localStorage.setItem('@winner_current', JSON.stringify(logged));
          else localStorage.clear();

          navigate('/go/');

        } else alert(Language(language).SomethingWrongTryAgain)
      })
      .catch(e => {
        console.log('loginError', e);
        if (e.response?.data) alert(e.response?.data)
      })
    .finally(() => setLogging(false));
  };

  const forgotPassword = async () => {
    if (!forgotInfo) return;

    setForgetting(true);

    const filter = {
      userlogin: forgotInfo,
    }

    await userForgotPassword(filter)
      .then((response) => {
        alert(Language(language).EmailWithResetLinkSent(response.data));

        setShowForgotPassword(false);

        setForgotInfo(undefined);
      })
      .catch(e => {
        console.log('userForgotPassword', e);
        if (e.response?.data) alert(e.response?.data)
      })
      .finally(() => setForgetting(false));
  }

  const forgotDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={forgetting} className="animation-on-hover" color="info" onClick={() => setShowForgotPassword(false)} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={forgetting} className="animation-on-hover" color="info" onClick={forgotPassword} >
        {forgetting ? Language(language).Processing : Language(language).Send}
      </Button>
    </>
  );

  useEffect(() => {
    if (user) navigate('/go/');
  }, [user]);

  return (
    <>
      <header className="Login-header">
        <img src={logo} className="App-logo" alt="logo" />

        {loading ?(
          <>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
            <h5 className="title">{Language(language).Loading}</h5>
          </>
        ) : (
          <CardBody
            style={{
              marginTop: 30,
              backgroundColor: 'rgba(255, 255, 255, .1)',
              borderRadius: '41px',
              padding: 50,
            }}
          >
            <Row>
              <SanitizedInput
                style={loginError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                className='input-box'
                value={login}
                onChange={(e: any) => {
                  setLogin(e.target.value.trim())
                  setLoginError(false)
                }}
                placeholder={Language(language).EmailOrUsername}
                type="text"
                size={50}
              />
              <img style={leftIcon} src={userIcon} alt='' />
            </Row>

            <Row>
              <SanitizedInput
                style={passwordError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                className='input-box'
                value={password}
                onChange={(e: any) => {
                  setPassword(e.target.value)
                  setPasswordError(false)
                }}
                placeholder={Language(language).TypePassword}
                type="password"
                size={50}
              />
              <img style={leftIcon} src={lockIcon} alt='' />
            </Row>

            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'stretch',
                justifyContent: 'space-between'
              }}
            >
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyItems: 'center',
                }}
              >
                <input
                  type='checkbox'
                  style={{
                    alignSelf: 'center',
                    marginLeft: 15,
                    marginRight: 5,
                  }}
                  checked={keeppLogged}
                  onChange={() => {
                    setKeepLogged(!keeppLogged)
                  }}
                />
                <span style={{
                  fontSize: '1rem',

                }}>
                  {` ${Language(language).RememberMe}`}
                </span>
              </Row>
              
              <a 
                style={{
                  fontSize: '1rem',
                  alignSelf: 'center',
                  color: '#CCAA50',
                }} 
                href='#' 
                onClick={() => setShowForgotPassword(true)}
              >
                  {`${Language(language).ForgotPassword}`}
              </a>
            </Row>

            <Row>
              { logging ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    justifyItems: 'center',
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
                </div>
              ) : (
                <>
                  <Button style={enterStyle} onClick={logUser}>
                    {Language(language).Enter}
                  </Button>
                </>
              )}
            </Row>
          </CardBody>
        )}
      </header>

      {/* Modal esqueci mminha senha */}
      <Dialog
        visible={showForgotPassword}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={Language(language).ForgotPassword}
        modal
        className="p-fluid"
        onHide={() => {
          setShowForgotPassword(false);
        }}
        footer={forgotDialogFooter}
      >
        <>
          <label>{Language(language).EnterMailORUsername}</label>
          <SanitizedInput
            style={!forgotInfo ? {borderColor: 'red'} : {}}
            value={forgotInfo}
            onChange={(e: any) => {
              setForgotInfo(e.target.value.trim());
            }}
            placeholder={Language(language).EmailORUsername}
            type="text"
            size={50}
          />
        </>
      </Dialog>
    </>
  );
}

const inputStyle: CSSProperties = {
  background: '#D9D9D9',
  borderRadius: '9px',
  padding: 10,
  paddingLeft: 40,
  marginBottom: 20,
  color: 'black',
  fontSize: '1rem',
  width: '20rem',
}

const enterStyle: CSSProperties = {
  background: 'linear-gradient(#E9B157 100%, #CCAA50 100%)',
  color: '#F5F5F5',
  marginTop: 20,
  padding: 10,
  width: '20rem',
  textTransform: 'uppercase',
  border: '1px solid #CCAA50',
}

const leftIcon: CSSProperties = {
  position: 'absolute',
  height: '1.7rem',
  margin: 5,
  color: 'black',
}

export default Login;
