import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import SanitizedInput from "../../components/sanitizedInput";
import { createProdut, deleteProdut, getProduts, updateProdut } from "../../api/produts";
import { useNavigate } from "react-router-dom";
import { getProdCategorys } from "../../api/produts/category";
import ImagCarousel from "../../components/imgCarousel";
import { upload } from "../../api/upload";
import LoadingSpin from "../../components/loading/loading";

function ProdutsView(props: any) {
  const navigate = useNavigate();

  const [recordDialog, setRecordDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [records, setRecords] = useState<iProdut[]>([]);
  const [files, setFiles] = useState<any>();
  const [fieldError, setFieldError] = useState('');
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<string | undefined>();

  const [categorys, setCategorys] = useState<iProdutCategory[]>([]);

  const toast = useRef<any>(null);

  const emptyRecord: iProdut = {
    name: '',
    images: [],
    price: 0,
    score: 0,
    categorys: [],
    sizes: [],
    colors: [],
  }
  const [record, setRecord] = useState<iProdut>(emptyRecord);

  const hideDialog = () => {
    setRecord(emptyRecord);
    setRecordDialog(false);
    setFiles(undefined);
  };

  const saveRecord = async () => {
    if (record.name.length < 3) {
      setFieldError('name');
      alert('Informe um nome válido');
      return;
    };

    if (record.price <= 0) {
      setFieldError('price');
      alert('Informe um preço válido!');
      return;
    };

    delete record.__v;

    setLoading(true);

    if (!record._id) {
      // Criando novo registro
      await createProdut(record, files)
        .then(() => {
          toast.current.show({ 
            severity: "success", 
            summary: "Sucesso", 
            detail: "Registro salvo com sucesso.", 
            life: 3000
          });

          hideDialog();

          loadRecords();

          setRecord(emptyRecord);
        })
        .catch(e => {
          console.log('createProdut error', JSON.stringify(e));
          if (e.response?.data) alert(e.response.data);
        })
        .finally(() => setLoading(false));
    } else {
      // Editando registro
      await updateProdut(record._id, record, files)
        .then(() => {
          toast.current.show({ 
            severity: "success", 
            summary: "Sucesso", 
            detail: "Registro salvo com sucesso.", 
            life: 3000
          });

          hideDialog();

          loadRecords();
        })
        .catch(e => {
          console.log('updateProdut error', e);
          if (e.response?.data) alert(e.response.data);
        })
        .finally(() => setLoading(false));
    }
  }

  const loadRecords = async () => {
    await getProduts()
      .then((response) => {
        setRecords(response.data);
      })
  }

  const loadCategorys = async () => {
    await getProdCategorys()
      .then((response) => {
        setCategorys(response.data);
      })
      .catch((e) => console.log('getProdCategorys error', e));
  }

  const deleteRecord = async (id?: string) => {
    if (!id) return;

    setDeleting(true);

    await deleteProdut(id)
      .then(() => {
        toast.current.show({ 
          severity: "success", 
          summary: "Sucesso", 
          detail: "Registro deletado.", 
          life: 3000
        });

        loadRecords();
      })
      .catch(e => {
        console.log('deleteProdut error', e);
        if (e.response?.data) alert(e.response.data);
      })
      .finally(() => {
        setDeleting(false);

        setDeleteID(undefined);
      });
  }

  const generateMultiStock = () => {
    const multistock: any = record.multistock ?? {};

    if (record.colors.length) {
      record.colors.forEach((color) => {
        let key = color.name;

        if (!key || key === '') return;

        if (record.sizes.length) record.sizes.forEach((size) => {
          key = `${color.name} ${size}`;

          if (!key || key === '') return;

          if (!multistock[key]) multistock[key] = 0
        })

        else if (!multistock[key]) multistock[key] = 0
      })
    }
    else if (record.sizes.length) record.sizes.forEach((size) => {
      let key = size;

      if (!key || key === '') return;

      if (!multistock[key]) multistock[key] = 0
    })

    setRecord((prev) => ({
      ...prev,
      multistock,
    }))
  }

  const recordDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading || uploading} className="animation-on-hover" color="info" onClick={hideDialog} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading || uploading} className="animation-on-hover" color="info" onClick={saveRecord} >
        {loading ? "Salvando..." : "Salvar"}
      </Button>
    </>
  );

  const deleteDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => {
        hideDialog();

        setDeleteID(undefined);
      }} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => deleteRecord(deleteID)} >
        {deleting ? "Excluindo..." : "Sim, excluir!"}
      </Button>
    </>
  );

  const renderCategoryItem = (index: number) => categorys.map((category) => (
    <DropdownItem onClick={() => setRecord((prev) => {
      return {
        ...prev,
        categorys: record.categorys.map((item, i) => i === index ? `${category._id}` : item),
      }
    })}>
      {category.name}
    </DropdownItem>
  ))

  const _renderEmpty = () => {
    return (<span>Sem registros.</span>)
  }

  const _renderProduts = () => {
    return records.map((item: iProdut) => {
      return (
        <tr>
          <th>{item.cod}</th>
          <th>{item.name}</th>
          <th>{item.price}</th>
          <th>{item.reference}</th>
          <th>{item.score}</th>
          <th>{item.stock}</th>
          <th>
            <Button 
              style={{padding: 7}}
              color="success" 
              className="animation-on-hover" 
              onClick={() => {
                setRecord(item);
                setRecordDialog(true);
              }}
            >
              <i className="pi pi-pencil" />
            </Button>
            {" "}
            <Button 
              style={{padding: 7}}
              color="danger" 
              className="animation-on-hover" 
              onClick={() => setDeleteID(item._id)}
            >
              <i className="pi pi-trash" />
            </Button>
          </th>
        </tr>
      );
    })
  }

  useEffect(() => {
    loadRecords();

    loadCategorys();
  }, [])

  return (
    <>
      <div className="content">
        <Toast ref={toast} />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Produtos{"  "}
                  <Button 
                    style={{padding: 7}}
                    color="info" 
                    className="animation-on-hover" 
                    onClick={() => setRecordDialog(true)}
                  >
                    <i className="pi pi-plus" />
                  </Button>

                  <Button 
                    style={{padding: 7}}
                    className="animation-on-hover" 
                    onClick={() => navigate('/go/prodCategorys')}
                  >
                    Categorias
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {records.length === 0 ? (
                  _renderEmpty()
                ) : (
                  <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Cod</th>
                        <th>Nome</th>
                        <th>Preço</th>
                        <th>Ref</th>
                        <th>Score</th>
                        <th>Estoque</th>
                        <th>Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_renderProduts()}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Modal adicionar/editar */}
      <Dialog
        visible={recordDialog}
        style={{ 
          width: "600px",
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={record?._id ? "Editar Produto" : "Novo Produto"}
        modal
        className="p-fluid"
        footer={recordDialogFooter}
        onHide={hideDialog}
      >
        <Card style={{padding: 10}}>
          {record.cod ? (
            <label>Cód: {record.cod}</label>
          ) : null}
          <Row>
            <Col md="6">
              <label>Nome *</label>
              <SanitizedInput
                style={fieldError === 'name' ? {borderColor: 'red'} : {}}
                value={record.name}
                onChange={(e: any) => {
                  if (fieldError === 'name') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    }
                  });
                }}
                placeholder={'Nome'}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <label>Descrição</label>
              <SanitizedInput
                value={record.description}
                onChange={(e: any) => {
                  setRecord((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    }
                  });
                }}
                placeholder={'Descrição'}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <label>Imagens</label>
            </Col>
          </Row>

          <Row style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <ImagCarousel images={record.images} />
          </Row>

          <Row>
            <Col>
              {record.images.length < 10 ? (
                <FileUpload
                  chooseLabel="Procurar"
                  multiple
                  auto
                  name="file"
                  customUpload={true}
                  uploadHandler={e => setFiles(e.files)}
                  accept="image/*"
                  maxFileSize={10000000}
                />
              ) : null}
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <label>Referência</label>
              <SanitizedInput
                value={record.reference}
                onChange={(e: any) => {
                  setRecord((prev) => {
                    return {
                      ...prev,
                      reference: e.target.value,
                    }
                  });
                }}
                placeholder={'Referência'}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <label>Preço *</label>
              <SanitizedInput
                style={fieldError === 'price' ? {borderColor: 'red'} : {}}
                value={record.price}
                onChange={(e: any) => {
                  if (fieldError === 'price') setFieldError('');
                  
                  const value = parseFloat(`${e.target.value}`).toFixed(2);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      price: parseFloat(value),
                    }
                  });
                }}
                placeholder={'Preço'}
                type="number"
                size={50}
              />
            </Col>

            <Col md="4">
              <label>Score</label>
              <SanitizedInput
                style={fieldError === 'score' ? {borderColor: 'red'} : {}}
                value={record.score}
                onChange={(e: any) => {
                  if (fieldError === 'score') setFieldError('');
                  
                  const value = parseInt(`${e.target.value}`);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      score: value,
                    }
                  });
                }}
                placeholder={'Score'}
                type="number"
                size={50}
              />
            </Col>

            {!record.sizes.length && !record.colors.length ? (
              <Col md="4">
                <label>Estoque</label>
                <SanitizedInput
                  style={fieldError === 'stock' ? {borderColor: 'red'} : {}}
                  value={record.stock}
                  onChange={(e: any) => {
                    if (fieldError === 'stock') setFieldError('');
                    
                    const value = parseInt(`${e.target.value}`);

                    setRecord((prev) => {
                      return {
                        ...prev,
                        stock: value,
                      }
                    });
                  }}
                  placeholder={'Estoque'}
                  type="number"
                  size={50}
                />
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md="4">
              <label>Altura (cm)</label>

              <SanitizedInput
                value={record.height}
                onChange={(e: any) => {
                  setRecord((prev) => {
                    return {
                      ...prev,
                      height: e.target.value,
                    }
                  });
                }}
                placeholder={'Altura (cm)'}
                type="number"
                size={50}
              />
            </Col>

            <Col md="4">
              <label>Largura (cm)</label>

              <SanitizedInput
                value={record.width}
                onChange={(e: any) => {
                  setRecord((prev) => {
                    return {
                      ...prev,
                      width: e.target.value,
                    }
                  });
                }}
                placeholder={'Largura (cm)'}
                type="number"
                size={50}
              />
            </Col>

            <Col md="4">
              <label>Comprimento (cm)</label>

              <SanitizedInput
                value={record.length}
                onChange={(e: any) => {
                  setRecord((prev) => {
                    return {
                      ...prev,
                      length: e.target.value,
                    }
                  });
                }}
                placeholder={'Comprimento (cm)'}
                type="number"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <label>Peso (g)</label>
              <SanitizedInput
                value={record.weight}
                onChange={(e: any) => {
                  setRecord((prev) => {
                    return {
                      ...prev,
                      weight: e.target.value,
                    }
                  });
                }}
                placeholder={'Peso (g)'}
                type="number"
                size={50}
              />
            </Col>
          </Row>

          <Row style={{
            display: 'flex',
            alignItems: 'center',
            margin: 10,
          }}>
            <h4 style={{margin: 0}}>Loja?</h4>

            <input
            type='checkbox'
              checked={record.store === true}
              style={{
                margin: 0,
                marginLeft: 5,
              }}
              onChange={() => {
                setRecord((prev) => {
                  return {
                    ...prev,
                    store: !(record.store === true),
                  }
                });
              }}
            />
          </Row>

          <Row style={{
            display: 'flex',
            alignItems: 'center',
            margin: 10,
          }}>
            Tamanhos{"  "}

            <Button 
              style={{padding: 7, marginLeft: 10}}
              color="info" 
              className="animation-on-hover" 
              onClick={() => setRecord((prev) => {
                return {
                  ...prev,
                  sizes: [...record.sizes, ''],
                }
              })}
            >
              <i className="pi pi-plus" />
            </Button>
          </Row>

          <Row>
            {record.sizes.map((size, index) => (
              <Col md="4" style={{
                display: 'flex',
              }} key={`size ${record._id} ${index}`}>
                <SanitizedInput
                  value={size}
                  onChange={(e: any) => {
                    setRecord((prev) => {
                      return {
                        ...prev,
                        sizes: prev.sizes.map((s, i) => i !== index ? s : e.target.value),
                      }
                    });
                  }}
                  placeholder={'Tamanho'}
                  type="text"
                  size={50}
                />

                <Button 
                  style={{padding: 7}}
                  color="danger" 
                  className="animation-on-hover" 
                  onClick={() => {
                    setRecord((prev) => {
                      return {
                        ...prev,
                        sizes: prev.sizes.filter((s, i) => i !== index),
                      }
                    });
                  }}
                >
                  <i className="pi pi-trash" />
                </Button>
              </Col>
            ))}
          </Row>

          {record._id ? (
            <>
              <Row style={{
                display: 'flex',
                alignItems: 'center',
                margin: 10,
              }}>
                Cores{"  "}

                <Button 
                  style={{padding: 7, marginLeft: 10}}
                  color="info" 
                  className="animation-on-hover" 
                  onClick={() => setRecord((prev) => {
                    return {
                      ...prev,
                      colors: [...record.colors, {name: '', images: []}],
                    }
                  })}
                >
                  <i className="pi pi-plus" />
                </Button>
              </Row>

              {record.colors.map((color, index) => (
                <Row style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                  <Col md='6'>
                    <ImagCarousel images={color.images} />

                    <SanitizedInput
                      value={color.name}
                      onChange={(e: any) => {
                        setRecord((prev) => {
                          return {
                            ...prev,
                            colors: prev.colors.map((c, i) => i !== index ? c : {
                              ...c,
                              name: e.target.value,
                            }),
                          }
                        });
                      }}
                      placeholder={'Cor'}
                      type="text"
                      size={50}
                    />

                    {uploading ? (<LoadingSpin spin />) : (
                      <FileUpload
                        chooseLabel="Procurar"
                        auto
                        name="file"
                        customUpload={true}
                        uploadHandler={e => {
                          setUploading(true);
                          upload(e.files[0], `produts/${record._id}/images/color/${color.name}`)
                            .then((response) => {
                              setRecord((prev) => {
                                return {
                                  ...prev,
                                  colors: prev.colors.map((c, i) => i !== index ? c : {
                                    ...c,
                                    images: [...c.images, response],
                                  }),
                                }
                              });
                            })
                            .finally(() => setUploading(false))
                        }}
                        accept="image/*"
                        maxFileSize={10000000}
                      />
                    )}

                    <Button 
                      style={{padding: 7}}
                      color="danger" 
                      className="animation-on-hover" 
                      onClick={() => {
                        setRecord((prev) => {
                          return {
                            ...prev,
                            colors: prev.colors.filter((s, i) => i !== index),
                          }
                        });
                      }}
                    >
                      <i className="pi pi-trash" />
                    </Button>
                  </Col>
                </Row>
              ))}
            </>
          ) : null}

          <Row style={{
            display: 'flex',
            alignItems: 'center',
            margin: 10,
          }}>
            Categorias{"  "}

            <Button 
              style={{padding: 7, marginLeft: 10}}
              color="info" 
              className="animation-on-hover" 
              onClick={() => setRecord((prev) => {
                return {
                  ...prev,
                  categorys: [...record.categorys, ''],
                }
              })}
            >
              <i className="pi pi-plus" />
            </Button>
          </Row>

          <Row style={{
            display: 'flex',
            alignItems: 'center',
            margin: 10,
          }}>
            {record.categorys.map((category, index) => (
              <>
                <UncontrolledDropdown>
                  <DropdownToggle>
                    {category !== '' ? categorys.find((item) => `${item._id}` === category)?.name : 'Categoria'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => {}}/>
                    {renderCategoryItem(index)}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <Button 
                  style={{padding: 7}}
                  color="danger" 
                  className="animation-on-hover" 
                  onClick={() => {
                    setRecord((prev) => {
                      return {
                        ...prev,
                        categorys: prev.categorys.filter((s, i) => i !== index),
                      }
                    });
                  }}
                >
                  <i className="pi pi-trash" />
                </Button>
              </>
            ))}
          </Row>

          {record.sizes.length || record.colors.length ? (
            <>
              <Row style={{
                display: 'flex',
                alignItems: 'center',
                margin: 10,
              }}>
                <Button 
                  style={{padding: 7, marginLeft: 10}}
                  color="info" 
                  className="animation-on-hover" 
                  onClick={generateMultiStock}
                >
                  Gerar Estoque
                </Button>
              </Row>

              {record.multistock ? (
                <Row>
                  {Object.entries(record.multistock).map(([key, value]) => {
                    let have = false;

                    if (key.includes(' ')) {
                      const split = key.split(' ');

                      const color = record.colors.find((c) => c.name === split[0]);

                      const size = record.sizes.find((s) => s === split[1]);

                      if (color && size) have = true;
                    } else {
                      const color = record.colors.find((c) => c.name === key);

                      const size = record.sizes.find((s) => s === key);

                      if (color || size) have = true;
                    }

                    if (!have) return null;

                    return (
                      <Col md="4" key={`size ${record._id} ${key}`}>
                        <label>{`${key}`}</label>
  
                        <SanitizedInput
                          value={value}
                          onChange={(e: any) => {
                            setRecord((prev) => {
                              const stock = parseInt(e.target.value);
                              const multistock: any = record.multistock ?? {};
  
                              multistock[key] = stock;
  
                              return {
                                ...prev,
                                multistock,
                              }
                            });
                          }}
                          placeholder={'Estoque'}
                          type="number"
                          size={50}
                        />
                      </Col>
                    )
                  })}
                </Row>
              ) : null}
            </>
          ) : null}
        </Card>
      </Dialog>

      {/* Modal pra excluir */}
      <Dialog
        visible={!(typeof deleteID === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={"Excluir?"}
        modal
        className="p-fluid"
        footer={deleteDialogFooter}
        onHide={() => {
          hideDialog();
          setDeleteID(undefined);
        }}
      >
        Excluir registro?
      </Dialog>
    </>
  );
}

export default ProdutsView;
