import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Row,
  CardTitle,
} from "reactstrap";
import Language from "../../language/language";

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import LoadingSpin from "../../components/loading/loading";
import { getCoursesByUserCourses } from "../../api/courses";
import { useUser } from "../../contexts/user/UserWrapper";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { Dialog } from "primereact/dialog";
import { userRemoveCourse } from "../../api/users";

function MyCoursesView() {
  const { language } = useLanguage();
  const { user } = useUser();
  const navigate = useNavigate();

  const [records, setRecords] = useState<iCourse[]>([]);
  const [load, setLoad] = useState<boolean>(true);
  const [removeCourseID, setRemoveCourseID] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const toast = useRef<any>(null);

  const loadRecords = async () => {
    if (!user?._id) return;

    setLoad(true);

    await getCoursesByUserCourses(user._id)
      .then((response) => {
        setRecords(response.data);

        setLoad(false);
      })
      .catch((e) => console.log('getCoursesByUser error', e));
  }

  const removeCouse = async () => {
    if (!removeCourseID || !user?._id) return;

    setLoading(true);

    await userRemoveCourse(user._id, removeCourseID)
      .then(() => {
        toast.current.show({ 
          severity: "success", 
          summary: Language(language).Success, 
          detail: Language(language).Removed, 
          life: 3000
        });

        setRemoveCourseID(undefined);
      })
      .catch((e) => console.log('userRemoveCourse error', e))
      .finally(() => {
        setLoading(false);
        loadRecords();
      })
  }

  const renderCourses = () => {
    if (records.length === 0) return(
      <view style={{display: 'flex', flexDirection: 'column'}}>
        <label style={{
          fontWeight: 'bold',
          fontSize: 16,
        }}>
          {Language(language).YouHaventAccessedCourses}
        </label>

        <Button 
          style={{padding: 7, marginLeft: 10}}
          color="info" 
          className="animation-on-hover"
          onClick={
            () => {
              navigate(`/go/courses`);
            }
          }
        >
          {Language(language).Courses}
        </Button>
      </view>
    )

    return records.map((data, index) => {
      // const limit = renderLimit + (showMore * renderLimit);

      // if (index >= limit) return null;

      return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Button 
            style={{
              margin: 0
            }}
            className="course-button"
            onClick={
              () => {
                navigate(`/go/courses/${data._id}`);
              }
            }
          >
            <view style={{display: 'flex', flexDirection: 'column'}}>
              {data.image ? (
                <img  src={data.image} className="course-image" alt="logo" />
              ) : null}
    
              {data.show_name ? (
                <label style={{
                  alignSelf: 'center',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  marginTop: 5,
                  width: '20vmin',
                }}>{data.name}</label>
              ) : null}
            </view>
          </Button>
          <Button 
            style={{
              margin: 5,
              marginTop: 0,
              borderRadius: '5px',
              background: 'linear-gradient(#511010 100%, #902A2B 100%)',
              border: '1px solid #CCAA50',
            }}
            className="animation-on-hover" 
            onClick={() => setRemoveCourseID(data._id)}
          >
            {Language(language).Remove}
          </Button>
        </div>
      )
    })
  }

  const removeCourseFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => {
        setRemoveCourseID(undefined);
      }} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={removeCouse} >
        {loading ? Language(language).Processing : Language(language).YesRemove}
      </Button>
    </>
  );

  useEffect(() => {
    loadRecords();
  }, [user?._id])
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <>
          <>
            <CardTitle tag="h3">
              {Language(language).MyCourses}
            </CardTitle>
          </>
          <>
            {load ? (<LoadingSpin />) : (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <Row>
                  {renderCourses()}
                </Row>
                {/* {records.length > renderLimit + (showMore * renderLimit) ? (
                  <Row>
                    <Button 
                      color="info"
                      onClick={
                        () => {setShowMore(showMore + 1)}
                      }
                    >
                      <span>{Language(language).ShowMore}</span>
                    </Button>
                  </Row>
                ) : null} */}
              </div>
            )}
          </>
        </>
      </div>

      {/* Modal pra remover curso da lista */}
      <Dialog
        visible={!(typeof removeCourseID === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={Language(language).AreYouSure}
        modal
        className="p-fluid"
        footer={removeCourseFooter}
        onHide={() => {
          setRemoveCourseID(undefined);
        }}
      >
        {Language(language).RemoveCouseFromList}
      </Dialog>
    </>
  );
}

export default MyCoursesView;
