// reactstrap components
import { useLanguage } from "../../contexts/language/LanguageWrapper";
import Language from "../../language/language";

function LoadingSpin(props: iProps) {
  const { language } = useLanguage();

  return (
    <div>
      <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
      {!props.spin ? (
        <h5 className="title">{Language(language).Loading}</h5>
      ) : null}
    </div>
  );
}

export default LoadingSpin;

interface iProps {
  spin?: boolean, // Mostrar apenas o spin
}
