import axios from "axios"
import config from "../../config"

export const createRequest = async (
  user_id: string,
  produts: iProdut[],
  address: iAddress,
  shipping?: iMeQuote
) => {
  const data = {
    user_id,
    produts,
    address,
    shipping,
  }

  const response = await axios.post(`${config.API_URL}requests/post`, data);

  return response;
}

export const getRequests = async () => {
  const response = await axios.get(`${config.API_URL}requests/getAll`)

  return response;
}

export const separateRequest = async (id: string, nfkey?: string) => {
  const data = { nfkey };

  const response = await axios.post(`${config.API_URL}requests/separate/${id}`, data)

  return response;
}
