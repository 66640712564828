import * as Dev from './dev';
import * as Prod from './production';

let env = 'prod'; // Hong Li
let config: any = {};

switch (env) {
  case 'prod':
    config = Prod;
    config.environment = 'Production';
    break;
  case 'dev':
    config = Dev;
    config.environment = 'Development';
    break;
  default:
    config = Dev;
    config.environment = 'Development';
}

export { config };
export default config;
