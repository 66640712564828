import { CSSProperties, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import bcrypt from "bcryptjs";

import env from "../../config";

import { Checkbox } from 'primereact/checkbox';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Row,
} from "reactstrap";
import { createUser, getUserById } from "../../api/users";
import Language from "../../language/language";

import logo from "../../assets/logo/logo.png";
import { testCPF } from "../../utils/Functions";
import { useLanguage } from "../../contexts/language/LanguageWrapper";
import { useConfig } from "../../contexts/config/ConfigWrapper";
import { Dialog } from "primereact/dialog";
import SanitizedInput from "../../components/sanitizedInput";
import moment from "moment";

import userIcon from '../../assets/icons/User.svg';
import lockIcon from '../../assets/icons/Lock.svg';
import keyIcon from '../../assets/icons/Key.svg';
import phoneIcon from '../../assets/icons/Phone.svg';

function Register() {
  const params = useParams();
  const navigate = useNavigate();

  const { language } = useLanguage();
  const { config } = useConfig();

  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const [showTerms, setShowTerms] = useState(false);

  const [parentName, setParentName] = useState<string>();

  const [name, setName] = useState<string | undefined>();
  const [lastname, setLastname] = useState<string | undefined>();
  const [birthdate, setBirthdate] = useState<Date> ();
  const [username, setUsername] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [repassword, setRepassword] = useState<string | undefined>();
  const [cpf, setCpf] = useState<string | undefined>();
  const [pix, setPix] = useState<string | undefined>();
  const [repix, setRePix] = useState<string | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const [pixKeyType, setPixKeyType] = useState<'CPF' | 'PHONE' | 'EMAIL' | 'RANDOM' | undefined>();
  const [parent, setParent] = useState<string | undefined>();

  const [nameError, setNameError] = useState(false);
  const [lastnameError, setLastnameError] = useState(false);
  const [birthdateError, setBirthdateError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [cpfError, setCpfError] = useState(false);
  const [pixError, setPixError] = useState(false);
  const [pixKeyTypeError, setPixKeyTypeError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [acceptedError, setAcceptedrror] = useState(false);

  const [accepted, setAccepted] = useState(false);

  const checkReferral = (id: string) => {
    getUserById(id)
      .then((response: any) => {
        if (response?.status === 200) {
          const data: iUser = response.data;

          const today = moment();

          if (!data.is_root && !data.is_special) {
            if (!data?.birthdate || today.diff(data.birthdate, 'years') <= (env?.MINIMUM_AGE || 16)) {
              setLoad(false);
              setMessage(Language(language).InvalidParameter);
              return;
            }
          }

          setLoad(false);
          setParent(params.referral);
          setParentName(`${data?.name} ${data?.lastname}`)
        }
      })
      .catch((e) => {
        console.log('getUserById', e);

        if (e.response?.status === 400) {
          setLoad(false);
          setMessage(Language(language).InvalidParameter);
        }
      })
  }

  const submitRegister = async () => {
    setLoading(true);

    if (!name || name.length < 2) {
      setNameError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    if (!lastname || lastname.length < 2) {
      setLastnameError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    const today = moment();
    if (!birthdate || today.diff(birthdate, 'years') > 150 || today.diff(birthdate, 'years') < 5) {
      setBirthdateError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    if (!email || email.length < 6) {
      setEmailError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    if (!cpf || cpf.length < 10 || !testCPF(cpf)) {
      setCpfError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    if (!username || username.length < 2) {
      setUsernameError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    if (!password || password.length < 8 || password !== repassword) {
      setPasswordError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    if (!pix || pix.length < 6 || pix !== repix) {
      setPixError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    if (!phone || phone.length < 11) {
      setPhoneError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    if (!pixKeyType) {
      setPixKeyTypeError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    if (!accepted) {
      setAcceptedrror(true);
      alert(Language(language).AcceptTermsOfUse);
      setLoading(false);
      return;
    }

    const salt = bcrypt.genSaltSync(10);
    const hash = bcrypt.hashSync(password, salt);

    const user: iUser = {
      name,
      lastname,
      birthdate,
      username,
      email,
      password: hash,
      parent: parent || '',
      cpf,
      pix,
      pix_type: pixKeyType,
      phone,
      courses: [],
    }

    await createUser(user)
      .then(response => {
        alert(Language(language).RegisteredSuccess);
        navigate('/');
      })
      .catch(e => {
        console.log('e', e);
        if (e.response?.data) alert(e.response.data);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (!config) return;

    if (params.referral === 'winner') {
      setLoad(false);
      setParent(config.actual_id);
      return;
    }

    if (!params.referral) {
      setLoad(false);
      setMessage(Language(language).InvalidParameter);
      return;
    }

    // if (params.referral === 'G0w1nN3rF1rsTUs3r') {
    //   setLoad(false);
    //   setParent(params.referral)
    // } else 
    checkReferral(params.referral);
  }, [config])
  
  return (
    <>
      <div style={{
        background: '#050E1E',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#050E1E',
        }}>
          <img style={{
            width: '30vmin',
            margin: 30,
          }} src={logo} alt="logo" />
          <div>
            {load ? (
              <Card>
                <CardHeader>
                  <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
                  {/* <h5 className="title">{Language(language).Loading}</h5> */}
                </CardHeader>
              </Card>
            ) : 
              message ? (
                <div>
                  <CardHeader>
                    <i className="pi pi-ban" style={{ fontSize: '2rem' }} />
                    <h5 className="title">{message}</h5>
                  </CardHeader>
                </div>
              ) : (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: '41px',
                  backgroundColor: 'rgba(255, 255, 255, .1)',
                  padding: 30,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                    <h3
                      style={{
                        textTransform: 'uppercase',
                        marginBottom: 0,
                      }}
                      className="title"
                    >{
                      Language(language).Register}
                    </h3>

                    {parentName ? (
                      <h4>{`Patrocinador: ${parentName}`}</h4>
                    ) : null}
                  </div>
                  <div>
                    <div>
                      <Row style={{
                        display: 'flex',
                        alignContent: 'space-between',
                      }}>
                        <Row>
                            <SanitizedInput
                              style={nameError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                              value={name}
                              onChange={(text: any) => {
                                setName(text.target.value)
                                setNameError(false)
                              }}
                              placeholder={Language(language).Name}
                              type="text"
                            />
                            <img style={leftIcon} src={userIcon} alt='' />
                        </Row>
                        <Row>
                          <SanitizedInput
                            style={lastnameError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                            value={lastname}
                            onChange={(text: any) => {
                              setLastname(text.target.value)
                              setLastnameError(false)
                            }}
                            placeholder={Language(language).Lastname}
                            type="text"
                          />
                          <img style={leftIcon} src={userIcon} alt='' />
                        </Row>
                      </Row>
                      <Row>
                        <div>
                          <h5 style={{
                            marginBottom: 0
                          }}>{Language(language).Birthdate}</h5>
                          <SanitizedInput
                            style={birthdateError ? {borderColor: 'red', ...dateStyle} : {...dateStyle}}
                            value={birthdate}
                            onChange={(text: any) => {
                              setBirthdate(text.target.value)
                              setBirthdateError(false)
                            }}
                            placeholder={Language(language).Birthdate}
                            type="date"
                          />
                        </div>
                        <div>
                          <SanitizedInput
                            style={usernameError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                            value={username}
                            onChange={(text: any) => {
                              setUsername(text.target.value.trim())
                              setUsernameError(false)
                            }}
                            placeholder={Language(language).Username}
                            type="text"
                          />
                          <img style={usernameIcon} src={userIcon} alt='' />
                        </div>
                      </Row>
                      <Row>
                        <Row>
                          <SanitizedInput
                            style={emailError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                            value={email}
                            onChange={(text: any) => {
                              setEmail(text.target.value)
                              setEmailError(false)
                            }}
                            placeholder="E-mail"
                            type="email"
                          />
                          <img style={leftIcon} src={userIcon} alt='' />
                        </Row>
                        <Row>
                          <SanitizedInput
                            style={cpfError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                            value={cpf}
                            onChange={(text: any) => {
                              setCpf(text.target.value)
                              setCpfError(false)
                            }}
                            placeholder="CPF"
                            type="text"
                          />
                          <img style={leftIcon} src={userIcon} alt='' />
                        </Row>
                      </Row>
                      <Row>
                        <Row>
                          <SanitizedInput
                            style={pixError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                            value={pix}
                            onChange={(text: any) => {
                              setPix(text.target.value)
                              setPixError(false)
                            }}
                            placeholder={Language(language).PIXkey}
                            type="text"
                          />
                          <img style={leftIcon} src={keyIcon} alt='' />
                        </Row>
                        <Row>
                          <SanitizedInput
                            style={pixError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                            value={repix}
                            onChange={(text: any) => {
                              setRePix(text.target.value)
                              setPixError(false)
                            }}
                            placeholder={Language(language).PIXkey}
                            type="text"
                          />
                          <img style={leftIcon} src={keyIcon} alt='' />
                        </Row>
                      </Row>
                      <Row>
                        <h5>{Language(language).PIXKeyType}</h5>
                      </Row>
                      <Row style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: 10,
                        marginRight: 0,
                      }}>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                          <Row>
                            <input
                              type='checkbox'
                              checked={pixKeyType === 'CPF'}
                              style={{
                                marginRight: 5,
                              }}
                              onChange={() => {
                                setPixKeyType('CPF');
                                setPixKeyTypeError(false)
                              }}
                            />
                            <h5 style={pixKeyTypeError ? {color: 'red', margin: 0} : {margin: 0}}>
                              {`CPF`}
                            </h5>
                          </Row>

                          <Row>
                            <input
                              type='checkbox' 
                              checked={pixKeyType === 'EMAIL'}
                              style={{
                                marginRight: 5,
                              }}
                              onChange={() => {
                                setPixKeyType('EMAIL');
                                setPixKeyTypeError(false)
                              }}
                            />
                            <h5 style={pixKeyTypeError ? {color: 'red', margin: 0} : {margin: 0}}>
                              {`EMAIL`}
                            </h5>
                          </Row>
                        </div>

                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                          <Row>
                            <input
                              type='checkbox' 
                              checked={pixKeyType === 'PHONE'}
                              style={{
                                marginRight: 5,
                              }}
                              onChange={() => {
                                setPixKeyType('PHONE');
                                setPixKeyTypeError(false)
                              }}
                            />
                            <h5 style={pixKeyTypeError ? {color: 'red', margin: 0} : {margin: 0}}>
                              {`${Language(language).Phone}`}
                            </h5>
                          </Row>
                          
                          <Row>
                            <input
                              type='checkbox'
                              checked={pixKeyType === 'RANDOM'}
                              style={{
                                marginRight: 5,
                              }}
                              onChange={() => {
                                setPixKeyType('RANDOM');
                                setPixKeyTypeError(false)
                              }}
                            />
                            <h5 style={pixKeyTypeError ? {color: 'red', margin: 0} : {margin: 0}}>
                              {`${Language(language).RandomKey}`}
                            </h5>
                          </Row>
                        </div>
                          
                        <Row>
                          <SanitizedInput
                            style={phoneError ? {borderColor: 'red', ...phoneInput} : {...phoneInput}}
                            value={phone}
                            onChange={(text: any) => {
                              setPhone(text.target.value)
                              setPixError(false)
                            }}
                            placeholder={Language(language).Phone}
                            type="text"
                          />
                          <img style={phoneIconStyle} src={phoneIcon} alt='' />
                        </Row>
                      </Row>

                      <Row>
                        <Row>
                          <SanitizedInput
                            style={passwordError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                            value={password}
                            onChange={(text: any) => {
                              setPassword(text.target.value)
                              setPasswordError(false)
                            }}
                            placeholder={Language(language).Password}
                            type="password"
                          />
                          <img style={leftIcon} src={lockIcon} alt='' />
                        </Row>
                        <Row>
                          <SanitizedInput
                            style={passwordError ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                            value={repassword}
                            onChange={(text: any) => {
                              setRepassword(text.target.value)
                              setPasswordError(false)
                            }}
                            placeholder={Language(language).RetypePassword}
                            type="password"
                          />
                          <img style={leftIcon} src={lockIcon} alt='' />
                        </Row>
                      </Row>
                      <Row>
                        <h5>* {Language(language).PasswordMustBe}</h5>
                      </Row>
                      <Row style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 10,
                      }}>
                        <input
                          type='checkbox'
                          style={{ marginRight: 5}}
                          checked={accepted}
                          onChange={() => {
                            setAccepted(!accepted)
                            setAcceptedrror(false)
                          }}
                        />
                        <h5 style={acceptedError ? {color: 'red', margin: 0} : {margin: 0}}>
                          {`${Language(language).AcceptThe}`}
                        </h5>{" "}

                        <a 
                          style={{
                            fontSize: '1rem',
                            alignSelf: 'center',
                            color: '#CCAA50',
                            marginLeft: 5,
                          }} 
                          href='#' 
                          onClick={() => setShowTerms(true)}
                        >
                            {`${Language(language).TermsUse}`}
                        </a>
                      </Row>
                    </div>
                  </div>
                  <div>
                  { loading ? (
                    <>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
                      <h5 className="title">{Language(language).Wait}</h5>
                    </>
                  ) : (
                    <Button onClick={submitRegister} style={enterStyle}>
                      {Language(language).CompleteRegistration}
                    </Button>
                  )}
                  </div>
                </div>
              )
            }
            
          </div>
        </div>
      </div>

      {/* Modal termmos de uso */}
      <Dialog
        visible={showTerms}
        style={{ 
          width: "600px",
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={Language(language).TermsUse}
        modal
        className="p-fluid"
        onHide={() => {
          setShowTerms(false);
        }}
      >
        {config?.terms}
      </Dialog>
    </>
  );
}

const enterStyle: CSSProperties = {
  background: 'linear-gradient(#E9B157 100%, #CCAA50 100%)',
  color: '#F5F5F5',
  marginTop: 20,
  padding: 10,
  width: '50vmin',
  textTransform: 'uppercase',
  border: '1px solid #CCAA50',
}

const inputStyle: CSSProperties = {
  background: '#D9D9D9',
  borderRadius: '9px',
  padding: 10,
  paddingLeft: 40,
  margin: 20,
  marginBottom: 5,
  color: 'black',
  fontSize: '1rem',
  width: '50vmin',
}

const phoneInput: CSSProperties = {
  ...inputStyle,
  marginTop: 0,
}

const dateStyle: CSSProperties = {
  ...inputStyle,
  marginLeft: 5,
  marginTop: 2,
  marginRight: -10,
}

const leftIcon: CSSProperties = {
  position: 'absolute',
  height: '1.7rem',
  margin: 25,
  color: 'black',
}

const phoneIconStyle: CSSProperties = {
  ...leftIcon,
  margin: 5,
  marginLeft: 25,
}

const usernameIcon: CSSProperties = {
  ...leftIcon,
  margin: 25,
  marginTop: -40,
}

export default Register;
