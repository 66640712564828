import { Toast } from "primereact/toast";
import logo from '../../assets/logo/logo.png';

// reactstrap components
import {
  Button,
} from "reactstrap";
import LoadingSpin from "../../components/loading/loading";
import { useLanguage } from "../../contexts/language/LanguageWrapper";
import Language from "../../language/language";
import { useRef } from "react";

function MonthlyView(props: any) {
  const { language } = useLanguage();

  const toast = useRef<any>(null);

  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <header className="Login-header">
          <img src={logo} className="App-logo" alt="logo" />
        
          <>
            {props?.pix?.payload ? (
              <>
                <img 
                  style={{height: '40vmin', marginBottom: 20}}
                  src={`data:image/jpeg;base64,${props.pix.payload.point_of_interaction.transaction_data.qr_code_base64}`} 
                  alt="QR Code"
                />
                <label>{Language(language).PayContinueAccessNewNow}</label>
                <Button 
                  color="info" 
                  className="animation-on-hover" 
                  onClick={() => {
                    navigator.clipboard.writeText(
                      props.pix.payload.point_of_interaction.transaction_data.qr_code
                    );

                    toast.current.show({ 
                      severity: "success", 
                      summary: Language(language).Success, 
                      detail: Language(language).QRCodeCopied, 
                      life: 3000
                    });
                  }}
                >
                  {Language(language).CopyQRCode}
                </Button>
              </>
            ) : (
              <>
                <LoadingSpin />
              </>
            )}
          </>
        </header>
      </div>
    </>
  );
}

export default MonthlyView;
