import { CSSProperties, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import Language from "../../language/language";

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import { getConfig, updateConfig } from "../../api/config";
import LoadingSpin from "../../components/loading/loading";
import { useConfig } from "../../contexts/config/ConfigWrapper";
import SanitizedInput from "../../components/sanitizedInput";
import { FileUpload } from "primereact/fileupload";
import { createCertificate, deleteCertificate, getCertificates, updateCertificate } from "../../api/certificates";
import { Carousel } from "primereact/carousel";
import { Dialog } from "primereact/dialog";
import XIcon from "../../assets/icons/X.svg";
import userIcon from "../../assets/icons/user_min.svg";
import locationIcon from "../../assets/icons/location_on.svg";
import phoneIcon from "../../assets/icons/phone_min.svg";
import { getCEPAddress } from "../../services/cep";

function ConfigView() {
  const { language } = useLanguage();
  const { loadConfig } = useConfig();

  const toast = useRef<any>(null);

  const empty: iConfig = {
    name: 'config',
    gen1fee: 0,
    gen2fee: 0,
    gen3fee: 0,
    gen4fee: 0,
    gen5fee: 0,
    free_shipping: 0,
    lootMinimum: 0,
    lootWeekDay: 0,
    lootTerm: 0,
    actual_id: '',
    terms: '',
    home_video: '',
    general_video: '',
    telegram: '',
    telegram_title: '',
    whatsapp: '',
    whatsapp_title: '',
    shipDays: 0,
  }
  const [config, setConfig] = useState<iConfig>(empty);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCertificates, setLoadingCertificates] = useState(true);
  const [certificates, setCertificates] = useState<iCertificate[]>([]);
  const [deletingCertificate, setDeletingCertificate] = useState<iCertificate>();
  const [certificate, setCertificate] = useState<iCertificate>();
  const [showAuthME, setShowAuthME] = useState(false);
  const [authMEPass, setAuthMEPass] = useState('');
  const [showAddress, setShowAddress] = useState(false);
  const [inputError, setInputError] = useState('');
  const [loadingCEP, setloadingCEP] = useState(false);

  const emptyAddress: iAddress = {
    name: '',
    phone: '',
    address: '',
    number: '',
    city: '',
    postal_code: '',
    state_abbr: '',
  }

  const reloadConfig = async () => {
    await getConfig()
      .then((response) => {
        setConfig(response.data);

        setLoad(false);
      })
  }

  const loadCertificates = async () => {
    await getCertificates()
      .then((response) => {
        setCertificates(response.data);
      })
      .catch((e) => {
        console.log('getCertificates error', e);

        toast.current.show({ 
          severity: "danger", 
          summary: "Algo deu errado!", 
          detail: "Não foi possível carregar os certificados. Chama o Li!", 
          life: 6000
        });
      })
      .finally(() => setLoadingCertificates(false));
  }

  const loadCEP = () => {
    if (!config) return;

    const { address } = config;

    if (!address) return;

    setloadingCEP(true);

    getCEPAddress(address.postal_code)
      .then((response) => {
        const CEP: iCEP = response.data;

        setConfig({
          ...config,
          address: {
            ...address,
            district: CEP.bairro,
            city: CEP.localidade,
            address: CEP.logradouro,
            state_abbr: CEP.uf,
          }
        });
      })
      .catch((e) => console.log('getCEPAddress error', e))
      .finally(() => setloadingCEP(false));
  }

  const saveConfig = async () => {
    if (!config._id) return;

    setLoading(true);

    delete config.__v;

    await updateConfig(config._id, config)
      .then(() => {
        toast.current.show({ 
          severity: "success", 
          summary: "Sucesso", 
          detail: "Configuração salva! Go Winner!", 
          life: 3000
        });

        loadConfig();
      })
      .catch(e => {
        console.log('updateConfig error', e);
        if (e.response?.data) alert(e.response.data);
      })
      .finally(() => setLoading(false));
  }

  const removeCertificate = async () => {
    if (!deletingCertificate) return;

    setLoadingCertificates(true);

    await deleteCertificate(deletingCertificate._id)
      .then((response) => {
        toast.current.show({ 
          severity: "success", 
          summary: "Sucesso", 
          detail: "Modelo deletado", 
          life: 3000
        });

        loadCertificates();
      })
      .catch((e) => {
        console.log('deleteCertificate error', e);

        toast.current.show({ 
          severity: "danger", 
          summary: "Algo deu errado!", 
          detail: e.response?.data || 'Não foi possível deletar o certificado.', 
          life: 6000
        });
      })
      .finally(() => {
        setDeletingCertificate(undefined)
        setLoadingCertificates(false);
      });
  }

  const redirectAuthME = () => {
    if (authMEPass !== '2500') {
      toast.current.show({ 
        severity: "danger", 
        summary: "Algo deu errado!", 
        detail: 'Senha incorreta!', 
        life: 6000
      });

      return;
    }

    window.location.href = 'https://melhorenvio.com.br/oauth/authorize?client_id=15922&redirect_uri=https://painel.go.gowinner.com.br/&response_type=code&state=teste&scope=cart-read cart-write companies-read companies-write coupons-read coupons-write notifications-read orders-read products-read products-write purchases-read shipping-calculate shipping-cancel shipping-checkout shipping-companies shipping-generate shipping-preview shipping-print shipping-share shipping-tracking ecommerce-shipping transactions-read users-read users-write';
  }

  const _renderEditAddress = () => {
    if (!config) return;

    const address = config.address || emptyAddress;
    
    return (
      <div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <span className="modal-title">{Language(language).EditAddress}</span>

          <Button 
            className="modal-xicon"
            onClick={() => setShowAddress(false) }
          >
            <img src={XIcon} alt="" />
          </Button>
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          margin: '1rem',
        }}>
          <Row style={{
            marginLeft: -30,
            marginRight: -30,
          }}>
            <SanitizedInput
              style={inputError === 'name' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
              value={address?.name}
              onChange={(text: any) => {
                setConfig({
                  ...config,
                  address: {
                    ...address,
                    name: text.target.value,
                  }
                });

                if (inputError === 'name') setInputError('');
              }}
              placeholder={Language(language).RecipientName}
              type="text"
            />

            <img style={leftIcon} src={userIcon} alt='' />
          </Row>

          <Row>
            <Row>
              <SanitizedInput
                style={inputError === 'phone' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={address?.phone}
                onChange={(text: any) => {
                  setConfig({
                    ...config,
                    address: {
                      ...address,
                      phone: text.target.value,
                    }
                  });

                  if (inputError === 'phone') setInputError('');
                }}
                placeholder={Language(language).Phone}
                type="text"
              />

              <img style={leftIcon} src={phoneIcon} alt='' />
            </Row>

            <Row>
              <SanitizedInput
                style={inputError === 'email' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={address.email}
                onChange={(text: any) => {
                  setConfig({
                    ...config,
                    address: {
                      ...address,
                      email: text.target.value,
                    }
                  });

                  if (inputError === 'email') setInputError('');
                }}
                placeholder='E-mail'
                type="text"
              />

              <img style={leftIcon} src={userIcon} alt='' />
            </Row>
          </Row>

          <Row>
            <Row>
              <SanitizedInput
                style={inputError === 'postal_code' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={address.postal_code}
                onChange={(text: any) => {
                  const value = text.target.value.replace(/\D/,'')

                  setConfig({
                    ...config,
                    address: {
                      ...address,
                      postal_code: value,
                    }
                  });

                  if (inputError === 'postal_code') setInputError('');
                }}
                placeholder={Language(language).CEP}
                type="text"
                pattern="[0-9*]"
              />

              <img style={leftIcon} src={locationIcon} alt='' />
            </Row>

            <Row>
              <SanitizedInput
                style={inputError === 'state_abbr' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={address.state_abbr}
                onChange={(text: any) => {
                  setConfig({
                    ...config,
                    address: {
                      ...address,
                      state_abbr: text.target.value,
                    }
                  });

                  if (inputError === 'state_abbr') setInputError('');
                }}
                placeholder={loadingCEP ? `${Language(language).Wait}...` : Language(language).State}
                type="text"
                disabled={true}
              />

              <img style={leftIcon} src={locationIcon} alt='' />
            </Row>
          </Row>

          <Row style={{
            marginLeft: -30,
            marginRight: -30,
          }}>
            <SanitizedInput
              style={inputError === 'address' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
              value={address.address}
              onChange={(text: any) => {
                setConfig({
                  ...config,
                  address: {
                    ...address,
                    address: text.target.value,
                  }
                });

                if (inputError === 'address') setInputError('');
              }}
              placeholder={loadingCEP ? `${Language(language).Wait}...` : Language(language).Street}
              type="text"
              disabled={true}
            />

            <img style={leftIcon} src={locationIcon} alt='' />
          </Row>

          <Row>
            <Row>
              <SanitizedInput
                style={inputError === 'number' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={address.number}
                onChange={(text: any) => {
                  setConfig({
                    ...config,
                    address: {
                      ...address,
                      number: text.target.value,
                    }
                  });

                  if (inputError === 'number') setInputError('');
                }}
                placeholder={Language(language).Number}
                type="text"
              />

              <img style={leftIcon} src={locationIcon} alt='' />
            </Row>

            <Row>
              <SanitizedInput
                style={inputError === 'complement' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={address.complement}
                onChange={(text: any) => {
                  setConfig({
                    ...config,
                    address: {
                      ...address,
                      complement: text.target.value,
                    }
                  });

                  if (inputError === 'complement') setInputError('');
                }}
                placeholder={Language(language).Complement}
                type="text"
              />

              <img style={leftIcon} src={locationIcon} alt='' />
            </Row>
          </Row>

          <Row style={{
            marginLeft: -30,
            marginRight: -30,
          }}>
            <SanitizedInput
              style={inputError === 'district' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
              value={address.district}
              onChange={(text: any) => {
                setConfig({
                  ...config,
                  address: {
                    ...address,
                    district: text.target.value,
                  }
                });

                if (inputError === 'district') setInputError('');
              }}
              placeholder={loadingCEP ? `${Language(language).Wait}...` : Language(language).District}
              type="text"
              disabled={true}
            />

            <img style={leftIcon} src={locationIcon} alt='' />
          </Row>

          <Row style={{
            marginLeft: -30,
            marginRight: -30,
          }}>
            <SanitizedInput
              style={inputError === 'city' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
              value={address.city}
              onChange={(text: any) => {
                setConfig({
                  ...config,
                  address: {
                    ...address,
                    city: text.target.value,
                  }
                });

                if (inputError === 'city') setInputError('');
              }}
              placeholder={loadingCEP ? `${Language(language).Wait}...` : Language(language).City}
              type="text"
              disabled={true}
            />

            <img style={leftIcon} src={locationIcon} alt='' />
          </Row>

          <Row>
            <Row>
              <SanitizedInput
                style={inputError === 'company_document' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={address.company_document}
                onChange={(text: any) => {
                  setConfig({
                    ...config,
                    address: {
                      ...address,
                      company_document: text.target.value,
                    }
                  });

                  if (inputError === 'company_document') setInputError('');
                }}
                placeholder='CNPJ'
                type="text"
              />

              <img style={leftIcon} src={userIcon} alt='' />
            </Row>

            <Row>
              <SanitizedInput
                style={inputError === 'state_register' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={address.state_register}
                onChange={(text: any) => {
                  setConfig({
                    ...config,
                    address: {
                      ...address,
                      state_register: text.target.value,
                    }
                  });

                  if (inputError === 'state_register') setInputError('');
                }}
                placeholder={Language(language).StateRegister}
                type="text"
              />

              <img style={leftIcon} src={userIcon} alt='' />
            </Row>
          </Row>

          {loading ? (
            <LoadingSpin />
          ) : (
            <Row>
              <Button 
                className="address-cancel-button"
                onClick={() => setShowAddress(false)}
              >
                <span className="prod-name">{Language(language).Cancel}</span>
              </Button>
              <Button 
                className="shipping-button"
                onClick={() => {
                  saveConfig();
                  setShowAddress(false);
                }}
              >
                Salvar
              </Button>
            </Row>
          )}
        </div>
      </div>
    )
  }

  const _renderCertificates = (data: iCertificate) => {
    return (
      <view style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <img  src={data.path} style={{width: '30vmin'}} alt="logo" />
        <div>
          <Button 
            style={{padding: 7}}
            color="success" 
            className="animation-on-hover" 
            onClick={() => setCertificate(data)}
          >
            <i className="pi pi-pencil" />
          </Button>
          <Button 
            style={{padding: 7}}
            color="danger" 
            className="animation-on-hover" 
            onClick={() => setDeletingCertificate(data)}
          >
            <i className="pi pi-trash" />
          </Button>
        </div>
      </view>
    )
  }

  const certificateDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loadingCertificates} className="animation-on-hover" color="info" onClick={() => setCertificate(undefined)} >
        {Language(language).Cancel}
      </Button>
    </>
  );

  const deleteCertificateFooter = (
    <>
      <Button icon="pi pi-times" disabled={loadingCertificates} className="animation-on-hover" color="info" onClick={() => {
        setDeletingCertificate(undefined);
      }} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={loadingCertificates} className="animation-on-hover" color="info" onClick={removeCertificate} >
        {loadingCertificates ? Language(language).Processing : Language(language).YesDelete}
      </Button>
    </>
  );

  const authMEFooter = (
    <>
      <Button icon="pi pi-times" disabled={loadingCertificates} className="animation-on-hover" color="info" onClick={() => {
        setShowAuthME(false);
      }} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={loadingCertificates} className="animation-on-hover" color="info" onClick={redirectAuthME} >
        {loadingCertificates ? Language(language).Processing : 'Go'}
      </Button>
    </>
  );

  useEffect(() => {
    reloadConfig();

    loadCertificates();
  }, [])

  useEffect(() => {
    loadCEP();
  }, [config?.address?.postal_code]);
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <div>
          <Col>
            {load ? (
              <Card>
                <CardHeader>
                  <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
                  <h5 className="title">{Language(language).Loading}</h5>
                </CardHeader>
              </Card>
            ) : 
              <Card>
                <CardHeader>
                  <h5 className="title">Configuração</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>% da 1 Geração</label>
                      <SanitizedInput
                        value={config?.gen1fee}
                        onChange={(e: any) => {
                          const value = parseInt(`${e.target.value}`);

                          setConfig((prev) => {
                            return {
                              ...prev,
                              gen1fee: value,
                            }
                          });
                        }}
                        placeholder={'% 1 Geração'}
                        type="number"
                        size={50}
                      />
                    </Col>
                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>% da 2 Geração</label>
                      <SanitizedInput
                        value={config?.gen2fee}
                        onChange={(e: any) => {
                          const value = parseInt(`${e.target.value}`);

                          setConfig((prev) => {
                            return {
                              ...prev,
                              gen2fee: value,
                            }
                          });
                        }}
                        placeholder={'% 2 Geração'}
                        type="number"
                        size={50}
                      />
                    </Col>
                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>% da 3 Geração</label>
                      <SanitizedInput
                        value={config?.gen3fee}
                        onChange={(e: any) => {
                          const value = parseInt(`${e.target.value}`);

                          setConfig((prev) => {
                            return {
                              ...prev,
                              gen3fee: value,
                            }
                          });
                        }}
                        placeholder={'% 3 Geração'}
                        type="number"
                        size={50}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>% da 4 Geração</label>
                      <SanitizedInput
                        value={config?.gen4fee}
                        onChange={(e: any) => {
                          const value = parseInt(`${e.target.value}`);

                          setConfig((prev) => {
                            return {
                              ...prev,
                              gen4fee: value,
                            }
                          });
                        }}
                        placeholder={'% 4 Geração'}
                        type="number"
                        size={50}
                      />
                    </Col>
                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>% da 5 Geração</label>
                      <SanitizedInput
                        value={config?.gen5fee}
                        onChange={(e: any) => {
                          const value = parseInt(`${e.target.value}`);

                          setConfig((prev) => {
                            return {
                              ...prev,
                              gen5fee: value,
                            }
                          });
                        }}
                        placeholder={'% 5 Geração'}
                        type="number"
                        size={50}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>Dia de saque / 0-6 / domingo-sábado</label>
                      <SanitizedInput
                        value={config?.lootWeekDay}
                        onChange={(e: any) => {
                          const value = parseInt(`${e.target.value}`);

                          setConfig((prev) => {
                            return {
                              ...prev,
                              lootWeekDay: value,
                            }
                          });
                        }}
                        placeholder={'Dia de saque / 0-6 / domingo-sábado'}
                        type="number"
                        size={50}
                      />
                    </Col>

                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>Saque mínimo</label>
                      <SanitizedInput
                        value={config?.lootMinimum}
                        onChange={(e: any) => {
                          const value = parseInt(`${e.target.value}`);

                          setConfig((prev) => {
                            return {
                              ...prev,
                              lootMinimum: value,
                            }
                          });
                        }}
                        placeholder={'Valor mínimo de saque'}
                        type="number"
                        size={50}
                      />
                    </Col>

                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>Prazo (dias)</label>
                      <SanitizedInput
                        value={config?.lootTerm}
                        onChange={(e: any) => {
                          const value = parseInt(`${e.target.value}`);

                          setConfig((prev) => {
                            return {
                              ...prev,
                              lootTerm: value,
                            }
                          });
                        }}
                        placeholder={'Prazo (dias)'}
                        type="number"
                        size={50}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>ID da vez</label>
                      <SanitizedInput
                        value={config?.actual_id}
                        onChange={(e: any) => {
                          setConfig((prev) => {
                            return {
                              ...prev,
                              actual_id: e.target.value,
                            }
                          });
                        }}
                        placeholder={'ID da vez'}
                        type="text"
                        size={50}
                      />
                    </Col>

                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>Frete Grátis</label>

                      <SanitizedInput
                        value={config?.free_shipping}
                        onChange={(e: any) => {
                          const value = parseFloat(`${e.target.value}`);

                          setConfig((prev) => {
                            return {
                              ...prev,
                              free_shipping: value,
                            }
                          });
                        }}
                        placeholder={'Frete Grátis'}
                        type="number"
                        size={50}
                      />

                      <label style={{fontWeight: 'bold'}}>Valor mínimo para frete grátis</label>
                    </Col>

                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>Prazo de Entrega (dias)</label>

                      <SanitizedInput
                        value={config?.shipDays}
                        onChange={(e: any) => {
                          const value = parseInt(`${e.target.value}`);

                          setConfig((prev) => {
                            return {
                              ...prev,
                              shipDays: value,
                            }
                          });
                        }}
                        placeholder={'Prazo de Entrega (dias)'}
                        type="number"
                        size={50}
                      />

                      <label style={{fontWeight: 'bold'}}>Será somado ao prazo da transportadora</label>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>Telegram</label>
                      <SanitizedInput
                        value={config?.telegram}
                        onChange={(e: any) => {
                          setConfig((prev) => {
                            return {
                              ...prev,
                              telegram: e.target.value,
                            }
                          });
                        }}
                        placeholder={'Telegram'}
                        type="text"
                        size={50}
                      />
                    </Col>

                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>Título do Telegram</label>
                      <SanitizedInput
                        value={config?.telegram_title}
                        onChange={(e: any) => {
                          setConfig((prev) => {
                            return {
                              ...prev,
                              telegram_title: e.target.value,
                            }
                          });
                        }}
                        placeholder={'Título do Telegram'}
                        type="text"
                        size={50}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>WhatsApp</label>
                      <SanitizedInput
                        value={config?.whatsapp}
                        onChange={(e: any) => {
                          setConfig((prev) => {
                            return {
                              ...prev,
                              whatsapp: e.target.value,
                            }
                          });
                        }}
                        placeholder={'WhatsApp'}
                        type="text"
                        size={50}
                      />
                    </Col>

                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>Título do WhatsApp</label>
                      <SanitizedInput
                        value={config?.whatsapp_title}
                        onChange={(e: any) => {
                          setConfig((prev) => {
                            return {
                              ...prev,
                              whatsapp_title: e.target.value,
                            }
                          });
                        }}
                        placeholder={'Título do WhatsApp'}
                        type="text"
                        size={50}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <label style={{fontWeight: 'bold'}}>Se o link do vídeo é 'https://www.youtube.com/watch?v=fV3u-UotCJI' o ID do vídeo seria: 'fV3u-UotCJI'</label>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>Vídeo da tela inicial</label>
                      <SanitizedInput
                        value={config?.home_video}
                        onChange={(e: any) => {
                          setConfig((prev) => {
                            return {
                              ...prev,
                              home_video: e.target.value,
                            }
                          });
                        }}
                        placeholder={'Vídeo da tela inicial'}
                        type="text"
                        size={50}
                      />
                    </Col>

                    <Col md="4">
                      <label style={{fontWeight: 'bold'}}>Vídeo da visão geral</label>
                      <SanitizedInput
                        value={config?.general_video}
                        onChange={(e: any) => {
                          setConfig((prev) => {
                            return {
                              ...prev,
                              general_video: e.target.value,
                            }
                          });
                        }}
                        placeholder={'Vídeo da visão geral'}
                        type="text"
                        size={50}
                      />
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col>
                      <label style={{fontWeight: 'bold'}}>Modelos de certificado</label>

                      {loadingCertificates ? (<LoadingSpin spin />) : (
                        <Carousel
                          style={{padding: 5}}
                          value={certificates}
                          numVisible={4}
                          numScroll={1}
                          responsiveOptions={carouselResponsiveOptions}
                          itemTemplate={_renderCertificates}
                        />
                      )}

                      <FileUpload
                        chooseLabel="Procurar"
                        auto
                        name="file"
                        customUpload={true}
                        uploadHandler={e => {
                          setLoadingCertificates(true);

                          createCertificate(e.files[0])
                            .then(() => {
                              toast.current.show({ 
                                severity: "success", 
                                summary: "Sucesso", 
                                detail: "Registro salvo", 
                                life: 3000
                              });

                              loadCertificates();
                            })
                            .catch((e) => {
                              console.log('createCertificate error', e);

                              toast.current.show({ 
                                severity: "danger", 
                                summary: "Falha!", 
                                detail: "Falha no upload. Chama o Li!", 
                                life: 3000
                              });
                            });
                        }}
                        accept="image/*"
                        maxFileSize={10000000}
                      />
                    </Col>
                  </Row> */}

                  <Row>
                    <Col>
                      <label style={{fontWeight: 'bold'}}>Termos de uso</label>
                      <SanitizedInput
                        value={config?.terms}
                        onChange={(e: any) => {
                          setConfig((prev) => {
                            return {
                              ...prev,
                              terms: e.target.value,
                            }
                          });
                        }}
                        placeholder={'Termos de uso'}
                        type="textarea"
                      />
                    </Col>
                  </Row>

                  <Row>
                    {loading ? (
                      <LoadingSpin />
                    ) : (
                      <Button 
                        style={{padding: 7, margin: 15}}
                        color="info" 
                        className="animation-on-hover" 
                        onClick={saveConfig}
                      >
                        Salvar
                      </Button>
                    )}

                    {loading ? (
                      <LoadingSpin />
                    ) : (
                      <Button 
                        style={{padding: 7, margin: 15}}
                        color="info" 
                        className="animation-on-hover" 
                        onClick={() => setShowAddress(true)}
                      >
                        Endereço de Envio
                      </Button>
                    )}

                    {loading ? (
                      <LoadingSpin />
                    ) : (
                      <Button 
                        style={{padding: 7, margin: 15}}
                        color="info" 
                        className="animation-on-hover" 
                        onClick={() => setShowAuthME(true)}
                      >
                        TOKEN Melhor Envio
                      </Button>
                    )}
                  </Row>
                </CardBody>
              </Card>
            }
            
          </Col>
        </div>
      </div>

      {/* Modal Endereço */}
      <Dialog
        visible={showAddress}
        modal
        className="shipping-modal"
        onHide={() => setShowAddress(false) }
        content={_renderEditAddress}
      />

      {/* Modal pra editar modelo de certificado */}
      <Dialog
        visible={!(typeof certificate === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={'Editar'}
        modal
        className="p-fluid"
        footer={certificateDialogFooter}
        onHide={() => {
          setCertificate(undefined);
        }}
      >
        {certificate ? (
          <view style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <img  src={certificate.path} style={{width: '30vmin'}} alt="logo" />

            <FileUpload
              chooseLabel="Procurar"
              auto
              name="file"
              customUpload={true}
              uploadHandler={e => {
                setLoadingCertificates(true);

                updateCertificate(certificate._id, e.files[0])
                  .then(() => {
                    toast.current.show({ 
                      severity: "success", 
                      summary: "Sucesso", 
                      detail: "Registro salvo", 
                      life: 3000
                    });

                    loadCertificates();

                    setCertificate(undefined);
                  })
                  .catch((e) => {
                    console.log('createCertificate error', e);

                    toast.current.show({ 
                      severity: "danger", 
                      summary: "Falha!", 
                      detail: "Falha no upload. Chama o Li!", 
                      life: 3000
                    });
                  });
              }}
              accept="image/*"
              maxFileSize={10000000}
            />
          </view>
        ) : null}
      </Dialog>

      {/* Modal pra excluir modelo de certificado */}
      <Dialog
        visible={!(typeof deletingCertificate === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={Language(language).Delete}
        modal
        className="p-fluid"
        footer={deleteCertificateFooter}
        onHide={() => {
          setDeletingCertificate(undefined);
        }}
      >
        <view style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          {deletingCertificate ? (
            <img  src={deletingCertificate.path} style={{width: '30vmin'}} alt="logo" />
          ) : null}
          <span>
            Deletar esse modle de certificado? Este processo é irreversível.
          </span>
        </view>
      </Dialog>

      {/* Modal pra autorizar API Melhor Envio */}
      <Dialog
        visible={showAuthME}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={'Autorizar Melhor Envio'}
        modal
        className="p-fluid"
        footer={authMEFooter}
        onHide={() => {
          setShowAuthME(false);
        }}
      >
        <view style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <SanitizedInput
            value={authMEPass}
            onChange={(e: any) => {
              setAuthMEPass(e.target.value);
            }}
            placeholder={'Senha'}
            type="text"
            size={50}
          />
        </view>
      </Dialog>
    </>
  );
}

export default ConfigView;

const inputStyle: CSSProperties = {
  background: '#D9D9D9',
  borderRadius: '9px',
  padding: 10,
  paddingLeft: 40,
  margin: 20,
  marginBottom: 0,
  color: 'black',
  fontSize: '1rem',
  width: '100%',
}

const leftIcon: CSSProperties = {
  position: 'absolute',
  height: '1.7rem',
  margin: 25,
  color: 'black',
}
