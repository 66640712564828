// reactstrap components
import { useRef } from "react";
import html2canvas from 'html2canvas';
// import './index.css';

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import Language from "../../language/language";
import { Button } from "reactstrap";

function CertificatePreview(props: iProps) {
  const {language} = useLanguage();

  const componentRef = useRef<any>();

  const handlePrint = () => {
    const input = document.getElementById('divToPrint');

    if (!input) return;

    html2canvas(input)
      .then((canvas: any) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement("a");
        link.download = `certificado-${props.course?.replaceAll(' ', '-')}.png`;
        link.href = imgData;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
      })
    ;
  };

  const color = 'black';

  return (
    <>
      {props.print ? (
        <Button 
          icon="pi pi-times" className="animation-on-hover" color="info" onClick={handlePrint} >

          Download
        </Button>
      ) : null}

      <div id="divToPrint" ref={componentRef} style={{
        display: 'flex',
        position: 'relative',
        width: '2000px',
        height: '1414px',
      }}>
        <img src={props.data.path} alt="Certificate" />

        <label style={{
          position: 'absolute',
          right: '40px',
          top: '600px',
          color: color,
          textAlign: 'center',
          width: 1600,
          fontSize: 128,
          fontFamily: 'Dancing Script',
          fontWeight: 'bold',
        }}>{props.student || 'Fulano de Tal'}</label>

        <label style={{
          alignSelf: 'center',
          position: 'absolute',
          right: '40px',
          top: '800px',
          textAlign: 'center',
          color: color,
          fontSize: 48,
          width: 1400
        }}>{Language(language).CertificateText(props.course || 'Curso Exemplo', props.hour || 9)}</label>

        <label style={{
          position: 'absolute',
          right: '930px',
          bottom: '220px',
          color: color,
          textAlign: 'center',
          width: 500,
          lineHeight: 1,
          fontSize: 64,
          fontFamily: 'Dancing Script',
          fontWeight: 'bold',
        }}>{props.student || 'Fulano de Tal'}</label>

        <label style={{
          alignSelf: 'center',
          position: 'absolute',
          right: '1000px',
          bottom: '145px',
          color: color,
          textAlign: 'center',
          width: 300,
          lineHeight: 1,
          fontSize: 32,
          fontWeight: 'bold',
        }}>{props.student || 'Fulano de Tal'}</label>

        <label style={{
          alignSelf: 'center',
          position: 'absolute',
          right: '480px',
          bottom: '220px',
          color: color,
          textAlign: 'center',
          width: 500,
          lineHeight: 1,
          fontSize: 64,
          fontFamily: 'Dancing Script',
          fontWeight: 'bold',
        }}>{props.expert || 'Beltrano Expert'}</label>

        <label style={{
          alignSelf: 'center',
          position: 'absolute',
          right: '580px',
          bottom: '145px',
          color: color,
          textAlign: 'center',
          width: 300,
          lineHeight: 1,
          fontSize: 32,
          fontWeight: 'bold',
        }}>{props.expert || 'Beltrano Expert'}</label>

        <label style={{
          alignSelf: 'center',
          position: 'absolute',
          right: '660px',
          bottom: '105px',
          color: color,
          fontSize: 32,
        }}>{props.title || 'Instrutor'}</label>
      </div>
      
      {props.print ? (
        <Button 
          icon="pi pi-times" className="animation-on-hover" color="info" onClick={handlePrint} >

          Download
        </Button>
      ) : null}
    </>
  );
}

export default CertificatePreview;

interface iProps {
  data: any,
  student?: string,
  course?: string,
  hour?: string | number,
  title?: string,
  expert?: string,
  print?: boolean,
}
