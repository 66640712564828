import axios from "axios"
import config from "../../config"

export const createCertificate = async (file: any) => {
  const formData = new FormData();

  formData.append('file', file);
  
  const response = await axios.post(`${config.API_URL}certificates/post`, formData);

  return response;
}

export const getCertificates = async () => {
  const response = await axios.get(`${config.API_URL}certificates/getAll`);

  return response;
}

export const getCertificate = async (id: string) => {
  const response = await axios.get(`${config.API_URL}certificates/getOne/${id}`);

  return response;
}

export const updateCertificate = async (id: string, file: any) => {
  const formData = new FormData();

  formData.append('file', file);
  
  const response = await axios.patch(`${config.API_URL}certificates/update/${id}`, formData);

  return response;
}

export const deleteCertificate = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}certificates/delete/${id}`);

  return response;
}
