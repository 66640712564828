import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Row,
  CardTitle,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Language from "../../language/language";

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import LoadingSpin from "../../components/loading/loading";
import { getAprovedCourses } from "../../api/courses";
import { useUser } from "../../contexts/user/UserWrapper";
import { useNavigate } from "react-router-dom";
import blockIcon from "../../assets/icons/block.svg";
import "./styles.css";
import SanitizedInput from "../../components/sanitizedInput";
import { getCategorys } from "../../api/categorys";

function CoursesView() {
  const { language } = useLanguage();
  const { user, plan } = useUser();
  const navigate = useNavigate();

  const [records, setRecords] = useState<iCourse[]>([]);
  const [load, setLoad] = useState<boolean>(true);
  const [search, setSearch] = useState<string>();

  const [categorys, setCategorys] = useState<iCategory[]>([]);
  const [category, setCategory] = useState<string>();

  const renderLimit = 20;
  const [showMore, setShowMore] = useState(0);

  const toast = useRef<any>(null);

  const loadRecords = async () => {
    if (!user?._id || !plan) return;

    setLoad(true);

    await getAprovedCourses()
      .then((response) => {
        setRecords(response.data);

        setLoad(false);
      })
      .catch((e) => console.log('getCoursesByUser error', e));
  }

  const loadCategorys = async () => {
    await getCategorys()
      .then((response) => {
        setCategorys(response.data);
      })
      .catch((e) => console.log('getCategorys error', e));
  }

  const renderCourses = () => {
    if (records.length === 0) return(
      <label style={{
        fontWeight: 'bold',
        fontSize: 16,
      }}>{Language(language).NotCreatedCourses}</label>
    )

    let result = [...records];

    if (search && search !== '') result = records.filter((data) => 
      data.name.toLowerCase().includes(search.toLowerCase()));

    if (category) result = result.filter((data) => data.category_id === category);

    if (result.length === 0) return(
      <label style={{
        fontWeight: 'bold',
        fontSize: 16,
      }}>{Language(language).NotCreatedCourses}</label>
    )

    return result.map((data) => {
      // const limit = renderLimit + (showMore * renderLimit);

      // if (index >= limit) return null;

      return (
        <Button 
          className="course-button"
          onClick={
            () => {
              navigate(`/go/courses/${data._id}`);
            }
          }
        >
          <view style={{display: 'flex', flexDirection: 'column'}}>
            {data.image ? (
              <img  src={data.image} className="course-image" alt="logo" />
            ) : null}

            {plan?.level && data.level && data.level > plan.level ? (
              <img src={blockIcon} alt="" className="block-icon" />
            ) : null}
  
            {data.show_name ? (
              <label style={{
                alignSelf: 'center',
                fontWeight: 'bold',
                fontSize: '1rem',
                marginTop: 5,
                width: '20vmin',
              }}>{data.name}</label>
            ) : null}
          </view>
        </Button>
      )
    })
  }

  const renderCategoryItem = () => categorys.map((category) => (
    <DropdownItem onClick={() => setCategory(category._id)}>
      {category.name}
    </DropdownItem>
  ))

  useEffect(() => {
    loadRecords();

    loadCategorys();
  }, [user?._id, plan])
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <>
          <>
            <CardTitle tag="h3">
              {Language(language).Courses}
            </CardTitle>
          </>
          <>
            {load ? (<LoadingSpin />) : (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <Row>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <SanitizedInput
                      className="search"
                      style={{
                        marginRight: 10
                      }}
                      value={search}
                      onChange={(e: any) => {
                        setSearch(e.target.value);
                      }}
                      type="text"
                      size={50}
                    />
                    {!search || search === '' ? (
                      <i className="pi pi-search" style={{
                        display: 'block',
                        position: 'absolute',
                        marginLeft: 10,
                      }} />
                    ) : null}

                    {categorys.length ? (
                      <UncontrolledDropdown>
                        <DropdownToggle>
                          {category ? categorys.find((item) => item._id === category)?.name : Language(language).Category}
                        </DropdownToggle>

                        <DropdownMenu>
                          <DropdownItem onClick={() => setCategory(undefined)}>
                            {Language(language).All}
                          </DropdownItem>
                          {renderCategoryItem()}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  {renderCourses()}
                </Row>
                {/* {records.length > renderLimit + (showMore * renderLimit) ? (
                  <Row>
                    <Button 
                      color="info"
                      onClick={
                        () => {setShowMore(showMore + 1)}
                      }
                    >
                      <span>{Language(language).ShowMore}</span>
                    </Button>
                  </Row>
                ) : null} */}
              </div>
            )}
          </>
        </>
      </div>
    </>
  );
}

export default CoursesView;
