import { Toast } from "primereact/toast";
import logo from '../../assets/logo/logo.png';
import './index.css'

// reactstrap components
import {
  Row,
  Button,
} from "reactstrap";
import LoadingSpin from "../../components/loading/loading";
import { getInitialPlans, purchasePlan } from "../../api/plans";
import { useUser } from "../../contexts/user/UserWrapper";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../contexts/language/LanguageWrapper";
import Language from "../../language/language";
import { useEffect, useRef, useState } from "react";
import { getKits, purchaseKit } from "../../api/kits";

function SelectPlan(props: any) {
  const { user, handleUser } = useUser();
  const navigate = useNavigate();
  const { language } = useLanguage();

  const [loading, setLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<iPlan[]>([]);
  const [kits, setKits] = useState<iKit[]>([]);
  const [selected, setSelected] = useState('');
  const [pix, setPix] = useState<any>();

  const toast = useRef<any>(null);

  const loadRecords = async () => {
    getInitialPlans()
      .then((response) => {
        setRecords(response.data);
      });

    getKits()
      .then((response) => setKits(response.data))
  }

  const purchase = async () => {
    if (!user?._id) {
      alert(`${Language(language).SomethingWrong} ${Language(language).RefreshPage}`);
      return;
    }

    if (selected === '') {
      alert(Language(language).SelectAKit);
      return;
    }

    setLoading(true);

    const kit = kits.find((i) => `${i._id}` === `${selected}`);

    // Kit
    if (kit) {
      await purchaseKit(user._id, selected)
        .then((response) => {
          handleUser(response.data);

          navigate('/go/requests');
        })
        .catch((e) => {
          console.log('purchase error', e);
        })
        .finally(() => setLoading(false));

      return;
    }

    // Plano
    await purchasePlan(user._id, selected)
      .then((response) => {
        setPix(response.data);
      })
      .catch((e) => {
        console.log('purchase error', e);
      })
      .finally(() => setLoading(false));
  }

  const _renderPlans = () => {
    const sorted = records.sort((a: iPlan, b: iPlan) => {
      return b.fee - a.fee;
    });

    return sorted.map((data: iPlan) => (
      <Button 
        className={selected === data._id ? "plan-container-selected" : "plan-container"}
        onClick={() => setSelected(data._id || '')}
      >
        {data.image ? (
          <img  src={data.image} className="plan-image" alt="logo" />
        ) : null}

        <label className="plan-name">{data.name}</label>

        <span className="plan-name">{`R$ ${data.fee.toFixed(2)}`}</span>

        <span className="plan-info">{`(${data.price.toFixed(2)}/${Language(language).month})`}</span>
      </Button>
    ))
  }

  const _renderKits = () => {
    const sorted = kits.sort((a: iKit, b: iKit) => {
      return b.price - a.price;
    });

    return sorted.map((data: iKit) => (
      <Button 
        className={selected === data._id ? "plan-container-selected" : "plan-container"}
        onClick={() => setSelected(data._id || '')}
      >
        {data.image ? (
          <img  src={data.image} className="plan-image" alt="logo" />
        ) : null}

        <label className="plan-name">{data.name}</label>

        <span className="plan-name">{`R$ ${data.price.toFixed(2)}`}</span>

        <span className="plan-info">{`${data.percent} %`}</span>
      </Button>
    ))
  }

  useEffect(() => {
    loadRecords();
  }, []);

  useEffect(() => {
    if (user?.plan_id) {
      navigate('/go/*');
    }
  }, [user?.plan_id])

  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <header className="Login-header">
          <img src={logo} className="App-logo" alt="logo" />
          <br />
        
          {records.length === 0 ? (
            <LoadingSpin />
          ) : (
            <>
              {pix ? (
                <>
                  <img 
                    style={{height: '40vmin'}}
                    src={`data:image/jpeg;base64,${pix.payload.point_of_interaction.transaction_data.qr_code_base64}`} 
                    alt="QR Code"
                  />
                  <label>{Language(language).PayAccessNow}</label>
                  <Button 
                    color="info" 
                    className="animation-on-hover" 
                    onClick={() => {
                      navigator.clipboard.writeText(
                        pix.payload.point_of_interaction.transaction_data.qr_code
                      );

                      toast.current.show({ 
                        severity: "success", 
                        summary: Language(language).Success, 
                        detail: Language(language).QRCodeCopied, 
                        life: 3000
                      });
                    }}
                  >
                    {Language(language).CopyQRCode}
                  </Button>
                </>
              ) : (
                <>
                  <Row>
                    <label>{Language(language).SelectAKit}</label>
                  </Row>

                  <Row>
                    {_renderKits()}
                  </Row>

                  <Row>
                    { loading ? (
                      <>
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
                        {/* <h5 className="title">{Language(language).Wait}</h5> */}
                      </>
                    ) : (
                      <Button className="plan-purchase-button" onClick={purchase}>
                        <span className="plan-purchase-button-text">
                          {Language(language).Purchase}
                        </span>
                      </Button>
                    )}
                  </Row>
                </>
              )}
            </>
          )}
        </header>
      </div>
    </>
  );
}

export default SelectPlan;
