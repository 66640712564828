import axios from "axios"
import config from "../../config"

export const getConfig = async () => {
  const response = await axios.get(`${config.API_URL}config/getAll`)

  return response;
}

export const updateConfig = async (id: string, data: iConfig) => {
  const response = await axios.patch(`${config.API_URL}config/update/${id}`, data);

  return response;
}
