import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import ThemeContextWrapper from "./contexts/theme/ThemeWrapper";
import LanguageContextWrapper from "./contexts/language/LanguageWrapper";
import UserContextWrapper from './contexts/user/UserWrapper';
import ConfigContextWrapper from './contexts/config/ConfigWrapper';
import Router from './routes';

import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "primeicons/primeicons.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigContextWrapper>
      <UserContextWrapper>
        <ThemeContextWrapper>
          <LanguageContextWrapper>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </LanguageContextWrapper>
        </ThemeContextWrapper>
      </UserContextWrapper>
    </ConfigContextWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
