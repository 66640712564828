import axios from "axios"
import config from "../../config"

export const meAuthApp = async (id: string, code: string) => {
  const data = {
    id,
    code,
  }

  const response = await axios.post(`${config.API_URL}melhorEnvio/authApp`, data);

  return response;
}

export const quoteShipping = async (data: iMeQuoteRequest) => {
  const response = await axios.post(`${config.API_URL}melhorEnvio/quoteShipping`, data);

  return response;
}
