import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Card,
  Row,
  Col,
  CardTitle,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Language from "../../language/language";

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import LoadingSpin from "../../components/loading/loading";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { createCourse, getCoursesByUser } from "../../api/courses";
import { useUser } from "../../contexts/user/UserWrapper";
import { useNavigate } from "react-router-dom";
import SanitizedInput from "../../components/sanitizedInput";
import { Checkbox } from "primereact/checkbox";
import "./styles.css";
import { getCategorys } from "../../api/categorys";

function ExpertView() {
  const { language } = useLanguage();
  const { user } = useUser();
  const navigate = useNavigate();

  const [recordDialog, setRecordDialog] = useState<boolean>(false);
  const [records, setRecords] = useState<iCourse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(true);
  const [fieldError, setFieldError] = useState('');
  const [image, setImage] = useState<any>();

  const emptyRecord: iCourse = {
    name: '',
    show_name: true,
    description: '',
  }
  const [record, setRecord] = useState<iCourse>(emptyRecord);

  const [categorys, setCategorys] = useState<iCategory[]>([]);

  const renderLimit = 20;
  const [showMore, setShowMore] = useState(0);

  const toast = useRef<any>(null);

  const saveRecord = async () => {
    if (!user?._id) return;

    if (record.name.length < 3) {
      setFieldError('name');
      alert(Language(language).EnterValidName);
      return;
    };

    if (!record.description || record.description.length < 10) {
      setFieldError('description');
      alert(Language(language).EnterValidDescription);
      return;
    };

    setLoading(true);

    delete record.__v;

    if (!record._id) {
      // Criando novo curso
      await createCourse(user._id, record, image)
        .then(() => {
          setRecordDialog(false);

          toast.current.show({ 
            severity: "success", 
            summary: Language(language).Success, 
            detail: Language(language).RecordSaved, 
            life: 3000
          });

          loadRecords();

          setRecord(emptyRecord);
        })
        .catch(e => {
          console.log('createCourse error', e);
        })
        .finally(() => setLoading(false));
    }
  }

  const loadRecords = async () => {
    if (!user?._id) return;

    setLoad(true);

    await getCoursesByUser(user._id)
      .then((response) => {
        setRecords(response.data);

        setLoad(false);
      })
      .catch((e) => console.log('getCoursesByUser error', e));
  }

  const loadCategorys = async () => {
    await getCategorys()
      .then((response) => {
        setCategorys(response.data);
      })
      .catch((e) => console.log('getCategorys error', e));
  }

  const recordDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => setRecordDialog(false)} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={saveRecord} >
        {loading ? Language(language).Processing : Language(language).Create}
      </Button>
    </>
  );

  const renderCourses = () => {
    if (records.length === 0) return(
      <label style={{
        fontWeight: 'bold',
        fontSize: 16,
      }}>{Language(language).NotCreatedCourses}</label>
    )

    return records.map((data, index) => {
      // const limit = renderLimit + (showMore * renderLimit);

      // if (index >= limit) return null;

      return (
        <Button 
          className="course-button"
          onClick={
            () => {
              navigate(`/go/expert/${data._id}`);
            }
          }
        >
          <view style={{display: 'flex', flexDirection: 'column'}}>
            {data.image ? (
              <img  src={data.image} className="course-image" alt="logo" />
            ) : null}
  
            <label style={{
              alignSelf: 'center',
              fontWeight: 'bold',
              fontSize: '1rem',
              marginTop: 5,
              width: '20vmin',
            }}>{data.name}</label>
          </view>
        </Button>
      )
    })
  }
  
  const renderCategoryItem = () => categorys.map((category) => (
    <DropdownItem onClick={() => setRecord((prev) => {
      return {
        ...prev,
        category_id: category._id,
      }
    })}>
      {category.name}
    </DropdownItem>
  ))

  useEffect(() => {
    loadRecords();

    loadCategorys()
  }, [user?._id]);
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <>
          <>
            <CardTitle tag="h3">
              {Language(language).CreateCourse}
              <Button 
                style={{padding: 7, marginLeft: 10}}
                color="info" 
                className="animation-on-hover" 
                onClick={() => setRecordDialog(true)}
              >
                <i className="pi pi-plus" />
              </Button>
            </CardTitle>
          </>
          <>
            {load ? (<LoadingSpin />) : (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <Row>
                  {renderCourses()}
                </Row>
                {/* {records.length > renderLimit + (showMore * renderLimit) ? (
                  <Row>
                    <Button 
                      color="info"
                      onClick={
                        () => {setShowMore(showMore + 1)}
                      }
                    >
                      <span>{Language(language).ShowMore}</span>
                    </Button>
                  </Row>
                ) : null} */}
              </div>
            )}
          </>
        </>
      </div>

      {/* Modal criar */}
      <Dialog
        visible={recordDialog}
        style={{ 
          width: "600px",
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={record?._id ? Language(language).EditCourse : Language(language).CreateCourse}
        modal
        className="p-fluid"
        footer={recordDialogFooter}
        onHide={() => setRecordDialog(false)}
      >
        <Card style={{padding: 10}}>
          <Row style={{
            marginBottom: 10,
          }}>
            <Col md="6">
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).CourseName} *</label>
              <SanitizedInput
                style={fieldError === 'name' ? {borderColor: 'red'} : {}}
                value={record.name}
                onChange={(e: any) => {
                  if (fieldError === 'name') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).CourseName}
                type="text"
                size={50}
              />
            </Col>

            <Col md="6">
              <Row>
                <Checkbox
                  checked={record.show_name === true}
                  onChange={e => {
                    setRecord((prev) => {
                      return {
                        ...prev,
                        show_name: e.checked === true,
                      }
                    });
                  }}
                />

                <label style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  marginLeft: 10,
                }}>{Language(language).ShowName}</label>
              </Row>
            </Col>
          </Row>

          <Row style={{
            marginBottom: 10,
          }}>
            <Col>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Description} *</label>
              <SanitizedInput
                style={fieldError === 'description' ? {borderColor: 'red'} : {}}
                value={record.description}
                onChange={(e: any) => {
                  if (fieldError === 'description') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).Description}
                type="textarea"
                size={300}
              />
            </Col>
          </Row>

          {user?.is_root ? (
            <>
              <Row style={{
                marginBottom: 10,
              }}>
                <Col md="6">
                  <label style={{
                    fontWeight: 'bold',
                    fontSize: 16,
                  }}>Escola Avançada</label>
                  <SanitizedInput
                    style={{}}
                    value={record.school_id}
                    onChange={(e: any) => {
                      setRecord((prev) => {
                        return {
                          ...prev,
                          school_id: e.target.value,
                        }
                      });
                    }}
                    placeholder={'ID do curso'}
                    type="text"
                    size={50}
                  />
                </Col>
              </Row>
              {categorys.length ? (
                <>
                  <UncontrolledDropdown>
                    <DropdownToggle>
                      {record.category_id ? categorys.find((item) => item._id === record.category_id)?.name : 'Categia'}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setRecord((prev) => {
                        return {
                          ...prev,
                          category_id: null,
                        }
                      })}/>
                      {renderCategoryItem()}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              ) : null}
            </>
          ) : null}

          <Row>
            <Col style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 10,
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).DiscoverCourse} ({Language(language).optional})</label>
              <span>{Language(language).InsertLinkDescribesCourse}</span>
              <SanitizedInput
                style={fieldError === 'url' ? {borderColor: 'red'} : {}}
                value={record.url}
                onChange={(e: any) => {
                  if (fieldError === 'url') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      url: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).PageLink}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Support}</label>
              <span>{Language(language).SetContactSupport}</span>
            </Col>
          </Row>

          <Row>
            <Col style={{
              marginBottom: 10,
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).SupportEmail} ({Language(language).optional})</label>
              <SanitizedInput
                style={fieldError === 'email' ? {borderColor: 'red'} : {}}
                value={record.email}
                onChange={(e: any) => {
                  if (fieldError === 'email') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      email: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).SupportEmail}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col style={{
              marginBottom: 10,
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).SupportPhone} ({Language(language).optional})</label>
              <SanitizedInput
                style={fieldError === 'phone' ? {borderColor: 'red'} : {}}
                value={record.phone}
                onChange={(e: any) => {
                  if (fieldError === 'phone') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      phone: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).SupportPhone}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Image} ({Language(language).optional})</label>
            </Col>
          </Row>

          {record.image ? (
            <Row>
              <Col>
                <img src={record.image} className="App-logo" alt="logo" />
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col>
              <FileUpload
                chooseLabel="Procurar"
                auto
                name="file"
                customUpload={true}
                uploadHandler={e => setImage(e.files[0])}
                accept="image/*"
                maxFileSize={10000000}
              />
            </Col>
          </Row>
        </Card>
      </Dialog>
    </>
  );
}

export default ExpertView;
