import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Row,
  Col,
  Card,
} from "reactstrap";
import Language from "../../language/language";

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import LoadingSpin from "../../components/loading/loading";
import { getChildrens, getUserById, updateUser, userAdminFindParent, usersAdminFind, usersCounter } from "../../api/users";
import { useUser } from "../../contexts/user/UserWrapper";
import "./styles.css";
import SanitizedInput from "../../components/sanitizedInput";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";

function UsersView() {
  const { language } = useLanguage();
  const { user } = useUser();

  const toast = useRef<any>(null);

  const [name, setName] = useState<string>();
  const [lastname, setLastName] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [parent, setParent] = useState<string>(); 
  const [cpf, setCpf] = useState<string>(); 
  const [pix, setPix] = useState<string>(); 

  const [childrens, setChildrens] = useState<iChildren[]>([]);
  const [children, setChildren] = useState<iChildren>();
  const [editUser, setEditUser] = useState<iUser>();
  const [counter, setCounter] = useState<any>();
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const finder = async (is_active?: boolean) => {
    const filter: any = {}

    if (name && name !== '') filter.name = name;
    else if (lastname && lastname !== '') filter.lastname = lastname;
    else if (username && username !== '') filter.username = username;
    else if (email && email !== '') filter.email = email;
    else if (parent && parent !== '') filter.parent = parent;
    else if (cpf && cpf !== '') filter.cpf = cpf;
    else if (pix && pix !== '') filter.pix = pix;

    else if (typeof is_active === 'boolean') filter.is_active = is_active;

    else return;

    setLoading(true);

    await usersAdminFind(filter)
      .then((response) => {
        setChildren(undefined);
        setChildrens(response.data);
      })
      .catch((error) => {
        console.log('usersAdminFind error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const findParent = async (id?: string) => {
    if (!id) return;

    await userAdminFindParent(id)
      .then((response) => {
        setChildren(response.data);
      })
      .catch((error) => {
        console.log('userAdminFindParent error', error);
      });
  }

  const loadUser = async (id: string) => {
    setLoadingUser(true);

    await getUserById(id)
      .then((response) => {
        setLoadingUser(false);

        setEditUser(response.data);
      })
      .catch((error) => {
        console.log('getUserById error', error);
      });
  }

  const saveUser = async () => {
    if (!editUser?._id) return;

    setSaving(true);

    await updateUser(editUser._id, editUser)
      .then((response) => {
        toast.current.show({ 
          severity: "success", 
          summary: "Sucesso", 
          detail: "Registro salvo", 
          life: 3000
        });

        setEditUser(undefined);
      })
      .catch((error) => {
        console.log('updateUser error', error);
      })
      .finally(() => setSaving(false));
  }

  const getCounter = async () => {
    await usersCounter()
      .then((response) => {
        setCounter(response.data);
      })
      .catch((error) => {
        console.log('usersCounter error', error);
      })
  }

  const renderChildren = (child: iChildren, back: boolean = false) => {
    if (!child) return;
    
    return (
      <view style={{display: 'flex', flexDirection: 'column'}}>
        <Button 
          className="affiliate-container"
          onClick={
            () => {
              if (back) return findParent(child.parent);

              const newback: iChildren = child;

              if (children && !newback.backChild) {
                newback.backChild = children;
              }

              setChildren(newback);
            }
          }
        >
          {child && child.pin ? (
            <img  src={child.pin} style={{height: '15vmin', width: '15vmin'}} alt="logo" />
          ) : null}
          <div className="affiliate-div">
            <label style={{
              fontWeight: 'bold',
              fontSize: '1rem',
            }}>{child.name}</label>

            <span className="info-text">{`${Language(language).TotalAffiliates}: ${child.affiliates}`}</span>

            <span className="info-text">{`${Language(language).Gains}: ${child.gain}`}</span>

            <span className="info-text">{`${child.score} ${Language(language).Points}`}</span>

            {child.is_active? (
              <div className="tag-active">
                <span className="tag-text">{Language(language).ACTIVE}</span>
              </div>
            ) : (
              <div className="tag-inactive">
                <span className="tag-text">{Language(language).INACTIVE}</span>
              </div>
            )}
          </div>
        </Button>

        <Button 
          style={{
            margin: '1rem',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            borderRadius: '5px',
            background: 'linear-gradient(#511010 100%, #902A2B 100%)',
            border: '1px solid #CCAA50',
          }}
          onClick={() => loadUser(child.id)}
        >
          <i className="pi pi-pencil" />
        </Button>
      </view>
    )
  }

  const recordDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={saving} className="animation-on-hover" color="info" onClick={() => setEditUser(undefined)} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={saving} className="animation-on-hover" color="info" onClick={saveUser} >
        {saving ? "Salvando..." : "Salvar"}
      </Button>
    </>
  );

  const renderChildrens = () => {
    if (childrens.length === 0) return (
      <span style={{alignSelf: 'center'}}>{Language(language).NoAffiliates}</span>
    );

    return childrens.map((item) => renderChildren(item));
  }

  useEffect(() => {
    if (!user?._id) return;

    if (children) {
      setLoading(true);
      getChildrens(children.id)
        .then((response) => {
          setChildrens(response.data);
        })
        .catch(e => console.log('getChildrens error', e))
        .finally(() => setLoading(false));
    }
  }, [children, user]);

  useEffect(() => {
    getCounter();
  }, [])
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <div>
          <>
            {counter ? (
              <Row>
                <Button 
                  color="info"
                >
                  <span>Ativos</span>
                  {counter.actives}
                </Button>
                <Button 
                  color="danger"
                  onClick={() => finder(false)}
                >
                  <span>Inativos</span>
                  {counter.total - counter.actives}
                </Button>
                <Button>
                  <span>Total</span>
                  {counter.total}
                </Button>
              </Row>
            ) : null}
            <Row>
              <h4 className="title">Filtros</h4>
            </Row>
            <Row>
              <Col md='3'>
                <span>Nome</span>
                <SanitizedInput
                  value={name}
                  onChange={(e: any) => {
                    setName(e.target.value);
                  }}
                  placeholder={'Nome'}
                  type="text"
                  size={50}
                />
              </Col>

              <Col md='3'>
                <span>Sobrenome</span>
                <SanitizedInput
                  value={lastname}
                  onChange={(e: any) => {
                    setLastName(e.target.value);
                  }}
                  placeholder={'Sobrenome'}
                  type="text"
                  size={50}
                />
              </Col>

              <Col md='3'>
                <span>Nome de usuário</span>
                <SanitizedInput
                  value={username}
                  onChange={(e: any) => {
                    setUsername(e.target.value);
                  }}
                  placeholder={'Nome de usuário'}
                  type="text"
                  size={50}
                />
              </Col>

              <Col md='3'>
                <span>Email</span>
                <SanitizedInput
                  value={email}
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                  }}
                  placeholder={'Email'}
                  type="text"
                  size={50}
                />
              </Col>

              <Col md='3'>
                <span>ID do pai</span>
                <SanitizedInput
                  value={parent}
                  onChange={(e: any) => {
                    setParent(e.target.value);
                  }}
                  placeholder={'ID do pai'}
                  type="text"
                  size={50}
                />
              </Col>

              <Col md='3'>
                <span>CPF</span>
                <SanitizedInput
                  value={cpf}
                  onChange={(e: any) => {
                    setCpf(e.target.value);
                  }}
                  placeholder={'CPF'}
                  type="text"
                  size={50}
                />
              </Col>

              <Col md='3'>
                <span>PIX</span>
                <SanitizedInput
                  value={pix}
                  onChange={(e: any) => {
                    setPix(e.target.value);
                  }}
                  placeholder={'PIX'}
                  type="text"
                  size={50}
                />
              </Col>
            </Row>

            <Row>
              <Button 
                style={{
                  margin: '1rem',
                  paddingLeft: '2rem',
                  paddingRight: '2rem',
                  borderRadius: '5px',
                  background: 'linear-gradient(#511010 100%, #902A2B 100%)',
                  border: '1px solid #CCAA50',
                }}
                onClick={() => finder()}
              >
                Procurar
              </Button>
            </Row>
            <hr />

            {children ? (
              <Row style={{marginBottom: 20}}>
                <Col>
                  {renderChildren(children, true)}
                </Col>
              </Row>
            ) : null}

            <h4 className="title">{Language(language).Affiliates}</h4>

            <Row>
              {loading ? (<LoadingSpin />) : renderChildrens()}
            </Row>
            
          </>
        </div>
      </div>

      {/* Modal editar usuário */}
      <Dialog
        visible={loadingUser || (typeof editUser !== 'undefined')}
        style={{ 
          width: "600px",
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={"Editar Usuário"}
        modal
        className="p-fluid"
        footer={recordDialogFooter}
        onHide={() => setEditUser(undefined)}
      >
        {editUser ? (
          <Card style={{padding: 10}}>
            <Row>
              <Col>
                <label>{editUser._id}</label>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <label>Nome</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.name}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        name: e.target.value,
                      }
                    });
                  }}
                  placeholder={'Nome'}
                  type="text"
                  size={50}
                />
              </Col>

              <Col>
                <label>Sobrenome</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.lastname}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        lastname: e.target.value,
                      }
                    });
                  }}
                  placeholder={'Sobrenome'}
                  type="text"
                  size={50}
                />
              </Col>
            </Row>

            <Row>
              <Col md='4'>
                <label>Data de Nascimento</label>

                <SanitizedInput
                  style={{}}
                  value={editUser.birthdate ? new Date(`${editUser.birthdate}`).toISOString().slice(0,10) : null}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        birthdate: e.target.value,
                      }
                    });
                  }}
                  placeholder={'Data de Nascimento'}
                  type="date"
                  size={50}
                />
              </Col>
            </Row>
            
            <Row>
              <Col md="4">
                <label>Nome de usuário</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.username}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        username: e.target.value.trim(),
                      }
                    });
                  }}
                  placeholder={'Nome de usuário'}
                  type="text"
                  size={50}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Email</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.email}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        email: e.target.value.trim(),
                      }
                    });
                  }}
                  placeholder={'Nome de usuário'}
                  type="text"
                  size={50}
                />
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <label>Pai</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.parent}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        parent: e.target.value.trim(),
                      }
                    });
                  }}
                  placeholder={'Pai'}
                  type="text"
                  size={50}
                />
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <label>CPF</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.cpf}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        cpf: e.target.value.trim(),
                      }
                    });
                  }}
                  placeholder={'CPF'}
                  type="text"
                  size={50}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label>PIX</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.pix}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        pix: e.target.value,
                      }
                    });
                  }}
                  placeholder={'PIX'}
                  type="text"
                  size={50}
                />
              </Col>
            </Row>
            
            <Row style={{
              marginLeft: 10,
              marginTop: 10,
            }}>
              <Checkbox
                checked={editUser.is_root === true}
                onChange={e => {
                  //@ts-ignore
                  setEditUser((prev) => {
                    return {
                      ...prev,
                      is_root: e.checked === true,
                    }
                  });
                }}
              />

              <label>. Root</label>

              <Checkbox
                checked={editUser.is_special === true}
                style={{
                  marginLeft: 10,
                }}
                onChange={e => {
                  //@ts-ignore
                  setEditUser((prev) => {
                    return {
                      ...prev,
                      is_special: e.checked === true,
                    }
                  });
                }}
              />

              <label>. Especial</label>

              <Checkbox
                checked={editUser.is_active === true}
                style={{
                  marginLeft: 10,
                }}
                onChange={e => {
                  if (e.checked === true) {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        last_payment: new Date(),
                      }
                    });
                  }
                  //@ts-ignore
                  setEditUser((prev) => {
                    return {
                      ...prev,
                      is_active: e.checked === true,
                    }
                  });
                }}
              />

              <label>. Ativo</label>
            </Row>

            <Row>
              <Col>
                <label>ID Plano</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.plan_id}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        plan_id: e.target.value,
                      }
                    });
                  }}
                  placeholder={'ID Plano'}
                  type="text"
                  size={50}
                />
              </Col>
            </Row>

            <Row>
              <Col md='4'>
                <label>Afiliados</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.affiliates}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        affiliates: e.target.value,
                      }
                    });
                  }}
                  placeholder={'Afiliados'}
                  type="number"
                  size={50}
                />
              </Col>
            </Row>

            <Row>
              <Col md='4'>
                <label>Saldo</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.balance}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        balance: e.target.value,
                      }
                    });
                  }}
                  placeholder={'Saldo'}
                  type="number"
                  size={50}
                />
              </Col>

              <Col md='4'>
                <label>Ganhos</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.gain}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        gain: e.target.value,
                      }
                    });
                  }}
                  placeholder={'Ganhos'}
                  type="number"
                  size={50}
                />
              </Col>

              <Col md='4'>
                <label>Perdas</label>
                <SanitizedInput
                  style={{}}
                  value={editUser.losses}
                  onChange={(e: any) => {
                    //@ts-ignore
                    setEditUser((prev) => {
                      return {
                        ...prev,
                        losses: e.target.value,
                      }
                    });
                  }}
                  placeholder={'Perdas'}
                  type="number"
                  size={50}
                />
              </Col>
            </Row>
          </Card>
        ) : (<LoadingSpin />)}
      </Dialog>
    </>
  );
}

export default UsersView;
