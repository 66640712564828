import { createContext } from "react";

export const UserContext = createContext<iUserContext>({
  loading: true,
  user: undefined,
  plan: undefined,
  kit: undefined,
  accessedCourses: [],
  handleUser: () => {},
  reloadUser: () => {},
  accessCourse: () => {},
});
