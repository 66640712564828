import { useEffect, useRef, useState } from 'react';
import logo from './assets/logo/logo.png';
import logoBemVindo from './assets/logo/GoWinnerBemVindo.png';
import './App.css';
import { Button } from 'reactstrap';
import Language from './language/language';
import { useLanguage } from './contexts/language/LanguageWrapper';
import YouTubeVideo from './components/YouTubePlayer';
import { useConfig } from './contexts/config/ConfigWrapper';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import SanitizedInput from './components/sanitizedInput';
import { meAuthApp } from './api/melhorEnvio';
import { Toast } from 'primereact/toast';

function App() {
  const {language} = useLanguage();
  const {config} = useConfig();
  const navigate = useNavigate();

  const [meCode, setMeCode] = useState<string>();
  const [showAuthME, setShowAuthME] = useState(false);
  const [authMEPass, setAuthMEPass] = useState<string>();

  const toast = useRef<any>(null);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');

    if (code) {
      setMeCode(code);

      setShowAuthME(true);
    }
  }, [window.location.search]);

  const sendAuthME = () => {
    if (!authMEPass || authMEPass.length < 4 || !meCode) return;

    setShowAuthME(false);

    meAuthApp(authMEPass, meCode)
      .then(() => {
        toast.current.show({ 
          severity: "success", 
          summary: Language(language).Success, 
          detail: 'App autorizado!', 
          life: 3000
        });
      })
      .catch((e) => {
        toast.current.show({ 
          severity: "danger", 
          summary: "Algo deu errado!", 
          detail: "Falha na autorização", 
          life: 3000
        });

        console.log('meAuthApp error', e);
      })
      .finally(() => {
        setAuthMEPass(undefined);

        setMeCode(undefined);
      });
  }

  const authMEFooter = (
    <>
      <Button icon="pi pi-times" className="animation-on-hover" color="info" onClick={() => {
        setShowAuthME(false);
      }} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" className="animation-on-hover" color="info" onClick={sendAuthME} >
        {'Go'}
      </Button>
    </>
  );

  return (
    <div className="App">
      <Toast ref={toast} />

      {/* <TopBar showMembersArea /> */}
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <img src={logoBemVindo} className="App-logo-wellcome" alt="Wellcome Future" />
      </header>
      <div className="App">
        <br />
        {config ? (
          <YouTubeVideo height='195' width='320' videoId={config.home_video} />
        ) : null}

        <Button 
          style={{
            margin: '1rem',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            borderRadius: '5px',
            background: 'linear-gradient(#511010 100%, #902A2B 100%)',
            border: '1px solid #CCAA50',
          }}
          onClick={() => navigate("/login")}
        >
          <span 
            // style={{
            //   color: '#CCAA50',
            // }}
            className="members-button-text">{Language(language).MembersArea}</span>
        </Button>

        {/* <Row>
          {_renderPlans()}
        </Row> */}
      </div>

      {/* Modal pra autorizar API Melhor Envio */}
      <Dialog
        visible={showAuthME}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={'Autorizar Melhor Envio'}
        modal
        className="p-fluid"
        footer={authMEFooter}
        onHide={() => {
          setShowAuthME(false);
        }}
      >
        <view style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <SanitizedInput
            value={authMEPass}
            onChange={(e: any) => {
              setAuthMEPass(e.target.value);
            }}
            placeholder={'Senha'}
            type="text"
            size={50}
          />
        </view>
      </Dialog>
    </div>
  );
}

export default App;
