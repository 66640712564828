// reactstrap components
import { useRef } from "react";
import YouTube from "react-youtube";
import { Button, Row } from "reactstrap";

import './index.css';
import playIcon from '../../assets/icons/play_arrow_filled.svg';
import forwardIcon from '../../assets/icons/fast_forward_filled.svg';

function YouTubeVideo(props: iProps) {
  const player = useRef<any>(null);
  
  const opts = {
    height: window.innerWidth < 993 ? '100%' : props.height,
    width: window.innerWidth < 993 ? '100%' : props.width,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      rel: 0,
      showinfo: 0,
      modestbranding: 1,
      controls: 0,
    },
  };

  const play = () => {
    if (!player?.current?.internalPlayer) return;

    player.current.internalPlayer.playVideo();
  }

  const pause = () => {
    if (!player?.current?.internalPlayer) return;

    player.current.internalPlayer.pauseVideo();
  }

  const backward = async () => {
    if (!player?.current?.internalPlayer) return;

    const time = await player.current.internalPlayer.getCurrentTime();

    player.current.internalPlayer.seekTo(time - 10, true);
  }

  const forward = async () => {
    if (!player?.current?.internalPlayer) return;

    const time = await player.current.internalPlayer.getCurrentTime();

    player.current.internalPlayer.seekTo(time + 10, true);
  }

  const touch = async () => {
    if (!player?.current?.internalPlayer) return;

    const state = await player.current.internalPlayer.getPlayerState();

    if (state !== 1) play();
    else pause();
  }

  if (!props.videoId || props.videoId === '')
    return null;

  return (
    <view style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div onClick={touch}>
        <div style={{
          pointerEvents: 'none',
        }}>
          <YouTube ref={player} videoId={props.videoId} opts={opts} />
        </div>
      </div>
      <Row>
        <Button 
          className="backward-button" 
          onClick={backward}
        >
          <img src={forwardIcon} alt="" className="" />
        </Button>

        <Button 
          className="player-button" 
          onClick={touch}
        >
          <img src={playIcon} alt="" className="" />
        </Button>

        <Button 
          className="forward-button" 
          onClick={forward}
        >
          <img src={forwardIcon} alt="" className="mirror-img" />
        </Button>
      </Row>
    </view>
  );
}

export default YouTubeVideo;

interface iProps {
  height: string,
  width: string,
  videoId: string,
}
