import { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Row } from 'reactstrap';

import logo from '../../assets/logo/logo.png';
import { useLanguage } from '../../contexts/language/LanguageWrapper';
import Language from '../../language/language';
import { userCheckReset, userResetPassword } from '../../api/users';
import { useNavigate, useParams } from 'react-router-dom';
import SanitizedInput from '../../components/sanitizedInput';
import bcrypt from "bcryptjs";

function ResetPassword() {
  const { language } = useLanguage();
  const params = useParams();
  const navigate = useNavigate();

  const [repassword, setRepassword] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  const [passwordError, setPasswordError] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);

  const checkReset = async () => {
    if (!params.id || !params.token) {
      setLoad(false);
      setMessage(Language(language).InvalidParameter);
      return;
    }

    const filter = {
      id: params.id,
      token: params.token,
    }

    await userCheckReset(filter)
      .catch(e => {
        console.log('userForgotPassword', e);
        setMessage(e.response?.data);
      })
      .finally(() => setLoad(false));
  }

  const resetPassword = async () => {
    if (!password || password.length < 8 || password !== repassword) {
      setPasswordError(true);
      alert(Language(language).InvalidData);
      setLoading(false);
      return;
    }

    setLoading(true);

    const salt = bcrypt.genSaltSync(10);
    const hash = bcrypt.hashSync(password, salt);

    const filter = {
      id: params.id,
      token: params.token,
      newpassword: hash,
    }

    await userResetPassword(filter)
      .then(() => {
        alert(Language(language).PasswordResetSuccess);

        navigate('/');
      })
      .catch(e => {
        console.log('userResetPassword', e);
        setMessage(e.response?.data);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    checkReset();
  }, []);

  return (
    <>
      {load ? (
        <Card>
          <CardHeader>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
            <h5 className="title">{Language(language).Loading}</h5>
          </CardHeader>
        </Card>
      ) : (
        <header className="Login-header">
          <img src={logo} className="App-logo" alt="logo" />

          {message ? (
            <Card>
              <CardHeader>
                <i className="pi pi-ban" style={{ fontSize: '2rem' }} />
                <h5 className="title">{message}</h5>
              </CardHeader>
            </Card>
          ) : (
            <>
              <CardBody>
                <Row>
                  <label>{Language(language).NewPassword}</label>
                  <SanitizedInput
                    style={passwordError ? {borderColor: 'red'} : {}}
                    value={password}
                    onChange={(e: any) => {
                      setPassword(e.target.value)
                      setPasswordError(false)
                    }}
                    placeholder={Language(language).TypePassword}
                    type="password"
                    size={50}
                  />
                </Row>

                <Row>
                  <label>{Language(language).RetypePassword}</label>
                  <SanitizedInput
                    style={passwordError ? {borderColor: 'red'} : {}}
                    value={repassword}
                    onChange={(e: any) => {
                      setRepassword(e.target.value)
                      setPasswordError(false)
                    }}
                    placeholder={Language(language).RetypePassword}
                    type="password"
                    size={50}
                  />
                </Row>
              </CardBody>

              { loading ? (
                <>
                  <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
                  <h5 className="title">{Language(language).Wait}</h5>
                </>
              ) : (
                <>
                  <Button color="info" className="animation-on-hover" onClick={resetPassword}>
                    {Language(language).ResetPassword}
                  </Button>
                </>
              )}
            </>
          )}
        </header>
      )}
    </>
  );
}

export default ResetPassword;
