import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Row,
  Col,
  FormGroup,
  NavLink,
} from "reactstrap";
import Language from "../../language/language";

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import { useUser } from "../../contexts/user/UserWrapper";
import { getNextPlans, getPlanById, getPlans, purchasePlan } from "../../api/plans";
import { getGraduation, getRank, requestCashout, updateUser, userGraduate } from "../../api/users";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import YouTubeVideo from "../../components/YouTubePlayer";
import { useConfig } from "../../contexts/config/ConfigWrapper";
import SanitizedInput from "../../components/sanitizedInput";
import './styles.css';
import currencyIcon from "../../assets/icons/moneyS.svg";
import moneyIcon from "../../assets/icons/moneyCash.svg";
import groupIcon from "../../assets/icons/people3.svg";
import blockIcon from "../../assets/icons/blockX.svg";
import addGroupIcon from "../../assets/icons/add-group.svg";
import copyIcon from "../../assets/icons/Copy.svg";
import LoadingSpin from "../../components/loading/loading";
import { getGraduationById } from "../../api/graduations";
import { Checkbox } from "primereact/checkbox";
import { getKits, getNextKits, purchaseKit } from "../../api/kits";
import { useNavigate } from "react-router-dom";

function GeneralView(props: any) {
  const { language } = useLanguage();
  const { user, kit, reloadUser, handleUser } = useUser();
  const { config } = useConfig();
  const navigate = useNavigate();

  const toast = useRef<any>(null);

  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState<iPlan>();
  const [currentGraduation, setCurrentGraduation] = useState<iGraduation>();
  const [plans, setPlans] = useState<iPlan[]>([]);
  const [kits, setKits] = useState<iKit[]>([]);
  const [graduation, setGraduation] = useState<number | undefined>();
  const [graduating, setGraduating] = useState(false);
  const [pix, setPix] = useState<any>(undefined);
  const [purchasing, setPurchasing] = useState<boolean>(false);
  const [monthly, setMonthly] = useState<boolean>(true);
  const [cashing, setCashing] = useState(false);
  const [showCashout, setShowCashout] = useState(false);
  const [cashoutAmount, setCashoutAmount] = useState(0);
  const [cashoutError, setCashoutError] = useState(false);
  const [pixKeyType, setPixKeyType] = useState<'CPF' | 'PHONE' | 'EMAIL' | 'RANDOM' | undefined>();
  const [birthdate, setBirthdate] = useState<Date> ();
  const [birthdateError, setBirthdateError] = useState(false);
  const [rank, setRank] = useState<iRank>();

  const loadPlans = async () => {
    if (user?._id) await getNextPlans(user._id)
      .then((response) => {
        setPlans(response.data);
      })
      .catch(e => console.log('getNextPlans error', e));

    if (user?._id) await getNextKits(user._id)
      .then((response) => {
        setKits(response.data);
      })
      .catch(e => console.log('getNextKits error', e));
  }

  const loadRank = async () => {
    await getRank()
      .then((response) => {
        setRank(response.data);
      })
      .catch(e => console.log('getRank error', e));
  }

  const purchase = async (id: string) => {
    if (!user?._id) {
      alert(`${Language(language).SomethingWrong} ${Language(language).RefreshPage}`);
      return;
    }

    setPurchasing(true);

    const kit = kits.find((i) => `${i._id}` === `${id}`);

    // Kit
    if (kit) {
      await purchaseKit(user._id, id)
        .then((response) => {
          handleUser(response.data);

          navigate('/go/requests');
        })
        .catch((e) => {
          console.log('purchaseKit error', e);
        })
        .finally(() => setPurchasing(false));

      return;
    }

    await purchasePlan(user._id, id)
      .then((response) => {
        setPix(response.data);
      })
      .catch((e) => {
        console.log('purchasePlan error', e);
      })
      .finally(() => setPurchasing(false));
  }

  const Cashout = async () => {
    if (!user?.balance || !user?._id || (!user.pix_type && !pixKeyType)) return;

    if (!user.pix_type && pixKeyType) await updateUser(user._id, {
      ...user,
      pix_type: pixKeyType,
    });

    if (user.balance >= cashoutAmount) {
      setCashing(true);

      await requestCashout(user._id, cashoutAmount)
        .then((response) => {
          console.log('response', response);
          if (!config?.lootTerm) return;

          reloadUser();

          alert(Language(language).RequestSentWitinXDays(config.lootTerm));

          setShowCashout(false);
        })
        .catch(e => console.log('requestCashout error', e))
        .finally(() => setCashing(false));
    }
  }

  const graduate = async () => {
    if (!user?._id || !graduation) return;

    setGraduating(true);

    await userGraduate(user._id)
      .then((response) => {
        reloadUser(true);
      })
      .catch((e) => {
        console.log('graduate error', e);
        if (e.response?.data) alert(e.response.data);
      })
      .finally(() => setGraduating(false));
  }

  const submitRegister = async () => {
    if (!user?._id) return;

    setLoading(true);

    const data: iUser = {
      ...user,
    }

    if (!user.birthdate) {
      const today = moment();

      if (!birthdate || Math.abs(today.diff(birthdate, 'years')) > 150) {
        setBirthdateError(true);
        alert(Language(language).InvalidData);
        setLoading(false);
        return;
      }

      data.birthdate = birthdate;
    }

    await updateUser(user._id, data)
      .then(response => {
        reloadUser();
      })
      .catch(e => {
        console.log('e', e);
        if (e.response?.data) alert(e.response.data);
      });
  }

  const copyReferral = () => {
    navigator.clipboard.writeText(
      `https://painel.go.gowinner.com.br/register/${user?._id}`
    );

    toast.current.show({ 
      severity: "success", 
      summary: Language(language).Success, 
      detail: Language(language).ReferralLinkCopied, 
      life: 3000
    });
  }

  const _renderPlans = () => {
    const sorted = plans.sort((a: iPlan, b: iPlan) => {
      return b.fee - a.fee;
    });

    return sorted.map((data: iPlan) => (
      <div className="plan-container">
        {data.image ? (
          <img  src={data.image} className="plan-image" alt="logo" />
        ) : null}

        <label className="plan-name">{data.name}</label>

        {plan?._id === data._id ? null : (
          <span className="plan-name">{`R$ ${data.fee.toFixed(2)}`}</span>
        )}

        {plan?._id === data._id ? null : (
          <span className="plan-info">{`(${data.price.toFixed(2)}/${Language(language).month})`}</span>
        )}

        {plan?._id === data._id ? (
          <div className="plan-current">
            <span className="plan-current-text">{Language(language).CurrentPlan}</span>
          </div>
        ) : purchasing ? (<LoadingSpin spin />) : (
          <Button
            color="info"
            className="plan-purchase-button"
            onClick={() => purchase(data._id || '')}
          >
            <span className="plan-purchase-button-text">{Language(language).SelectPlan}</span>
          </Button>
        )}
      </div>
    ))
  }

  const _renderKits = () => {
    const sorted = kits.sort((a: iKit, b: iKit) => {
      return b.price + a.price;
    });

    return sorted.map((data: iKit) => (
      <div className="plan-container">
        {data.image ? (
          <img  src={data.image} className="plan-image" alt="logo" />
        ) : null}

        <label className="plan-name">{data.name}</label>

        {kit?._id === data._id ? null : (
          <span className="plan-name">{`R$ ${data.price.toFixed(2)}`}</span>
        )}

        <span className="plan-info">{`${data.percent} %`}</span>

        {kit?._id === data._id ? (
          <div className="plan-current">
            <span className="plan-current-text">{Language(language).CurrentKit}</span>
          </div>
        ) : purchasing ? (<LoadingSpin spin />) : (
          <Button
            color="info"
            className="plan-purchase-button"
            onClick={() => purchase(data._id || '')}
          >
            <span className="plan-purchase-button-text">{Language(language).SelectKit}</span>
          </Button>
        )}
      </div>
    ))
  }

  const recordDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={cashing} className="animation-on-hover" color="info" onClick={() => setShowCashout(false)} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={cashing} className="animation-on-hover" color="info" onClick={Cashout} >
        {cashing ? Language(language).Processing : Language(language).Request}
      </Button>
    </>
  );

  const completeRegisterFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={submitRegister} >
        {loading ? Language(language).Processing : Language(language).Save}
      </Button>
    </>
  );

  useEffect(() => {
    loadPlans();

    loadRank();
  }, [])

  useEffect(() => {
    if (user) setLoad(false);

    if (user?._id && !graduation) {
      getGraduation(user._id)
        .then((response) => {
          setGraduation(response.data);
        })
        .catch((e) => console.log('getGraduation error', e));
    }
  }, [user]);

  useEffect(() => {
    if (user?.balance) setCashoutAmount(user.balance)
  }, [user?.balance])

  useEffect(() => {
    if (user?.plan_id) {
      getPlanById(user.plan_id)
        .then((response) => {
          setPlan(response.data);
        })
        .catch((e) => console.log('getPlanById error', e));
    }
  }, [user?.plan_id]);

  useEffect(() => {
    reloadUser(true);

    if (user?.graduation_id) {
      getGraduationById(user.graduation_id)
        .then((response) => {
          setCurrentGraduation(response.data);
        })
        .catch((e) => console.log('getGraduationById error', e));
    }
  }, [graduation, user?.graduation_id]);
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <div>
          <div>
            {load ? (
              <div>
                <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
                <h5 className="title">{Language(language).Loading}</h5>
              </div>
            ) : 
              <div>
                <>
                  <Row style={{
                    display: 'flex',
                    margin: 0,
                    padding: 0,
                    alignItems: 'center',
                    justifyItems: 'center',
                  }}>
                    {/* {rank && rank.weekDirects.length ? (
                      <div className="rank-container">
                        <label className="graduation-text">{Language(language).WeeklyRank}</label>

                        {rank.weekDirects.map((item, index) => {
                          if (index < 3) return (
                            <label className="graduation-text">{`${index + 1}º - ${item.name} ${item.lastname || ''} - ${item.affiliates} ${Language(language).Indications}`}</label>
                          )

                          return null;
                        })}
                      </div>
                    ) : null} */}
                    <Col>
                      <div className="pin-container">
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyItems: 'center',
                        }}>
                          {currentGraduation?.pin ? (
                            <img
                              src={currentGraduation.pin} 
                              alt="pin" 
                              className="pin-img"
                            />
                          ) : (
                            <label>{Language(language).Loading}</label>
                          )}

                          {!(typeof graduation === 'undefined') ? (
                            <div style={{
                              margin: 10,
                            }}>
                              <div className="graduation-text">
                                {Language(language).Graduation}{"\n"}
                              </div>

                              <div className="graduation-value">
                                {`${parseInt(`${graduation || 0}`)}`}
                              </div>

                              <div className="graduation-value">
                                {Language(language).Points}
                              </div>

                            </div>
                          ) : null}
                        </div>

                        <div>
                          {config &&
                          user &&
                          user.balance &&
                          user.loot === 0 &&
                          (user.is_root || user.balance >= config.lootMinimum) &&
                          (user.is_root || `${config.lootWeekDay}`.includes(`${moment().weekday()}`)) ? (
                            <Button
                              className="shipping-button"
                              onClick={() => setShowCashout(true)}
                            >
                              <span className="prod-name">
                                {Language(language).RequestCashout}
                              </span>

                            </Button>
                          ) : null}
                        </div>

                        <div>
                          {user?.affiliates && currentGraduation && graduation &&
                          currentGraduation.score > 0 &&
                          graduation >= currentGraduation.score &&
                          user.affiliates >= currentGraduation.indications ?
                          graduating ? (<LoadingSpin spin />) : (
                            <Button
                              className="members-button"
                              onClick={graduate}
                            >
                              <span className="members-button-text">
                                {Language(language).Graduate}
                              </span>

                            </Button>
                          )
                          : null}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="total-container">
                        <img src={currencyIcon} alt="" className="total-icons" />
                        <div className="total-info-div">
                          <span className="total-text">{Language(language).Balance}{"\n"}</span>
                          <span className="total-text-value">{`R$ ${user?.balance?.toFixed(2)}`}{"\n"}</span>
                        </div>
                      </div>
                      <div className="total-container">
                        <img src={moneyIcon} alt="" className="total-icons" />
                        <div className="total-info-div">
                          <span className="total-text">{Language(language).Gains}{"\n"}</span>
                          <span className="total-text-value">{`R$ ${user?.gain?.toFixed(2)}`}{"\n"}</span>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="total-container">
                        <img src={groupIcon} alt="" className="total-icons" />
                        <div className="total-info-div">
                          <span className="total-text">{Language(language).TotalAffiliates}{"\n"}</span>
                          <span className="total-text-value">{`${user?.affiliates}`}{"\n"}</span>
                        </div>
                      </div>

                      <div className="total-container">
                        <img src={blockIcon} alt="" className="total-icons" />
                        <div className="total-info-div">
                          <span className="total-text">{Language(language).Losses}{"\n"}</span>
                          <span className="total-text-value">{`R$ ${user?.losses?.toFixed(2)}`}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{
                    display: 'flex',
                    margin: 0,
                    padding: 0,
                    alignItems: 'center',
                    justifyItems: 'center',
                  }}>
                    <div className="referral-container">
                      <img src={addGroupIcon} alt="" className="total-icons" />

                      <div className="total-info-div">
                        <span className="total-text">{Language(language).ReferralLink}</span>

                        <a 
                          className="referral-box"
                          href='#' 
                          onClick={copyReferral}
                        >
                          <span className="referral-text">{`https://painel.go.gowinner.com.br/register/${user?._id}`}</span>

                          <img style={{marginLeft: 15}} src={copyIcon} alt='' />
                        </a>
                      </div>
                    </div>
                  </Row>
                  
                  {config ? (
                    <view style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}>
                      <YouTubeVideo height="195" width="320" videoId={config.general_video} />
                    </view>
                  ) : null}

                  <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                    <span className="our-plans">{Language(language).OurKits}</span>
                  </Row>

                  <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                    {_renderKits()}
                  </Row>

                  {/* {!user?.kit_id ? (
                    <>
                      <Row style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                        <span className="our-plans">{Language(language).OurPlans}</span>
                      </Row>

                      <Row style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                        {_renderPlans()}
                      </Row>
                    </>
                  ) : null} */}
                </>
              </div>
            }
            
          </div>
        </div>
      </div>

      {/* Modal PIX */}
      <Dialog
        visible={!(typeof pix === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={"PIX"}
        modal
        className="p-fluid"
        onHide={() => {
          setPix(undefined);
        }}
      >
        <view style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          {pix?.payload ? (
            <img 
              style={{height: '40vmin', width: '40vmin', marginBottom: 30}}
              src={`data:image/jpeg;base64,${pix.payload.point_of_interaction.transaction_data.qr_code_base64}`} 
              alt="QR Code"
            />
          ) : null}

          <label>{Language(language).PayAccessNewNow}</label>
          <Button 
            color="info" 
            className="animation-on-hover" 
            onClick={() => {
              navigator.clipboard.writeText(
                pix.payload.point_of_interaction.transaction_data.qr_code
              );

              toast.current.show({ 
                severity: "success", 
                summary: Language(language).Success, 
                detail: Language(language).QRCodeCopied, 
                life: 3000
              });
            }}
          >
            {Language(language).CopyQRCode}
          </Button>
        </view>
      </Dialog>

      {/* Modal PIX mensalidade */}
      <Dialog
        visible={!(typeof props.pix === 'undefined') && monthly}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={"PIX"}
        modal
        className="p-fluid"
        onHide={() => {
          setMonthly(false);
        }}
      >
        <view style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          {props.pix?.payload ? (
            <img 
              style={{height: '40vmin', width: '40vmin', marginBottom: 30}}
              src={`data:image/jpeg;base64,${props.pix.payload.point_of_interaction.transaction_data.qr_code_base64}`} 
              alt="QR Code"
            />
          ) : null}

          <label>{Language(language).PayContinueAccessNewNow}</label>
          <Button 
            color="info" 
            className="animation-on-hover" 
            onClick={() => {
              navigator.clipboard.writeText(
                props.pix.payload.point_of_interaction.transaction_data.qr_code
              );

              toast.current.show({ 
                severity: "success", 
                summary: Language(language).Success, 
                detail: Language(language).QRCodeCopied, 
                life: 3000
              });
            }}
          >
            {Language(language).CopyQRCode}
          </Button>
        </view>
      </Dialog>

      {/* Modal solicitar saque */}
      <Dialog
        visible={showCashout}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={Language(language).RequestCashout}
        modal
        className="p-fluid"
        onHide={() => {
          setShowCashout(false);
        }}
        footer={recordDialogFooter}
      >
        <>
          {!user?.pix_type ? (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <label>{Language(language).PIXKeyType}</label>
              <label>{`PIX: ${user?.pix}`}</label>
              <div style={{
                display: 'flex',
              }}>
                <Checkbox
                  checked={pixKeyType === 'CPF'}
                  onChange={e => {
                    if (e.checked) setPixKeyType('CPF');
                  }}
                />
                <label>
                  {`. CPF`}
                </label>

                <Checkbox 
                  style={{
                    marginLeft: 10,
                  }}
                  checked={pixKeyType === 'EMAIL'}
                  onChange={e => {
                    if (e.checked) setPixKeyType('EMAIL');
                    
                  }}
                />
                <label>
                  {`. EMAIL`}
                </label>

                <Checkbox 
                  style={{
                    marginLeft: 10,
                  }}
                  checked={pixKeyType === 'PHONE'}
                  onChange={e => {
                    if (e.checked) setPixKeyType('PHONE');
                    
                  }}
                />
                <label>
                  {`. ${Language(language).Phone}`}
                </label>

                <Checkbox 
                  style={{
                    marginLeft: 10,
                  }}
                  checked={pixKeyType === 'RANDOM'}
                  onChange={e => {
                    if (e.checked) setPixKeyType('RANDOM');
                    
                  }}
                />
                <label>
                  {`. ${Language(language).RandomKey}`}
                </label>
              </div>
            </div>
          ) : null}
          <label>{Language(language).WithdrawalAmount}</label>

          <SanitizedInput
            style={cashoutError ? {borderColor: 'red'} : {}}
            value={cashoutAmount}
            onChange={(e: any) => {
              setCashoutError(false);
                  
              const value = parseFloat(`${e.target.value}`);

              setCashoutAmount(value);
            }}
            placeholder={Language(language).RequestCashout}
            type="number"
            size={50}
          />
        </>
      </Dialog>

      {/* Modal dados ausentes */}
      <Dialog
        visible={
          user?.birthdate === undefined || user?.birthdate === null
        }
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={Language(language).CompleteRegistration}
        modal
        className="p-fluid"
        onHide={() => {}}
        footer={completeRegisterFooter}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          {!user?.birthdate ? (
            <Col>
              <FormGroup>
                <label>{Language(language).Birthdate}</label>

                <SanitizedInput
                  style={birthdateError ? {borderColor: 'red'} : {}}
                  value={birthdate}
                  onChange={(text: any) => {
                    setBirthdate(text.target.value)
                    setBirthdateError(false)
                  }}
                  placeholder={Language(language).Birthdate}
                  type="date"
                />
              </FormGroup>
            </Col>
          ) : null}
        </div>
      </Dialog>
    </>
  );
}

export default GeneralView;
