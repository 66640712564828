import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardText,
} from "reactstrap";

import LoadingSpin from "../../components/loading/loading";
import { Dialog } from "primereact/dialog";
import { getRequests, separateRequest } from "../../api/requests";
import { getProdutsTotal } from "../store";
import SanitizedInput from "../../components/sanitizedInput";

function RequestsView() {
  const toast = useRef<any>(null);

  const [list, setList] = useState<iRequest[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [viewRequest, setViewRequest] = useState<iRequest>();
  const [separeRequest, setSepareRequest] = useState<iRequest>();
  const [nfkey, setNfkey] = useState<string>();
  const [filter, setFilter] = useState<iRequestState>('PAID');

  const loadList = async () => {
    setLoadingList(true);

    await getRequests()
      .then((response) => {
        setList(response.data);
      })
      .catch((e) => console.log('loadList error', e))
      .finally(() => setLoadingList(false));
  };

  const separate = (request: iRequest) => {
    if (request.shipping && !nfkey && !window.confirm("Vai enviar a etiqueta para a transportadora sem nota fiscal? O envio será marcado como não comercial. Continuar?"))
      return; 

    setLoading(true);

    separateRequest(request._id, nfkey)
      .then(() => {
        loadList();

        setNfkey(undefined);

        setSepareRequest(undefined);

        setViewRequest(undefined);

        toast.current.show({ 
          severity: "success", 
          summary: 'AEEEE!', 
          detail: 'Pedido separado!', 
          life: 3000
        });
      })
      .catch((error) => {
        if (error.message) alert(error.message);

        console.log('separateRequest error', error);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const renderList = () => {
    if (list.length === 0) return(
      <span>Não há pedidos pendentes.</span>
    )

    return list.map((item) => {
      if (item.state !== filter) return null;

      const total = getProdutsTotal(item.produts, item.percent);

      return (
        <>
          <Row style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <span className="plan-info">{`${item.shipping ? `${item.address.city} - ${item.address.state_abbr}` : 'BUSCA NA LOJA'} - R$: ${(total.discount + total.other_total).toFixed(2)} - ${item.produts.length} itens`}</span>

            <div>
            <Button
              className="shipping-button" 
              onClick={() => {setViewRequest(item)}}
            >
              <i className="pi pi-search" style={{ fontSize: '1rem', marginLeft: 10 }} />
            </Button>
            <Button
              className="address-cancel-button" 
              onClick={() => {
                if (item.shipping) setSepareRequest(item)
                else separate(item);
              }}
            >
              Separado
            </Button>
            </div>
          </Row>
          <hr />
        </>
      )
    })
  }

  const _renderProduts = () => viewRequest?.produts?.map((prod, index) => (
    <div className="prod-container">
      <div style={{
          display: 'flex',
          marginBottom: '1rem',
          borderBottom: '5px white solid',
          width: '100%'
      }}>
        <img  
          src={prod.colors.find((color) => color.name === prod.color)?.images[0] || prod.images[0]}
          className="prod-image"
          alt="logo" 
        />

        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <span className="prod-name">{`${prod.buy_quant} x ${prod.name}`}</span>

          <span className="prod-name">{`${prod.color || prod.colors[0]?.name || ' '} ${prod.size ? ` Tam: ${prod.size}`: prod.sizes.length ? ` Tam: ${prod.sizes[0]}` : ''}`}</span>

          { prod.reference ? (
            <span className="prod-name">{`Ref: ${prod.reference}`}</span>
          ) : null}
        </div>
      </div>
    </div>
  ))

  const recordDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => setViewRequest(undefined)} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => {
        if (!viewRequest) return;

        if (viewRequest.shipping) setSepareRequest(viewRequest)
        else separate(viewRequest)
      }} >
        {loading ? 'Processando...' : 'Separado'}
      </Button>
    </>
  );

  const nfkeyFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => setViewRequest(undefined)} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => {
        if (!separeRequest) return;

        separate(separeRequest);
      }} >
        {loading ? 'Processando...' : 'Continuar'}
      </Button>
    </>
  );

  useEffect(() => {
    loadList();
  }, []);
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <div>
          <Card style={{padding: 20}}>
            <CardHeader>
              <h5 className="title">Logística</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col style={{marginBottom: 20}}>
                  {loadingList ? (<LoadingSpin />) : (
                    renderList()
                  )}
                </Col>
                <Col md='4'>
                  {loadingList ? (<LoadingSpin />) : (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Button 
                        color="info" 
                        style={{marginBottom: 20}}
                        onClick={() => setFilter('PAID')}
                      >
                        <CardText style={{
                          fontWeight: 'bold', 
                          fontSize: '1rem', 
                          marginBottom: 8,
                        }}>
                          Pendentes
                        </CardText>
                      </Button>

                      <Button 
                        color="danger" 
                        style={{marginBottom: 20}}
                        onClick={() => setFilter('SEPARATION')}
                      >
                        <CardText style={{
                          fontWeight: 'bold', 
                          fontSize: '1rem', 
                          marginBottom: 8,
                        }}>
                          Separados
                        </CardText>
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>

      {/* Modal visualizar pedido */}
      <Dialog
        visible={typeof viewRequest !== 'undefined'}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={'Itens do pedido'}
        modal
        className="p-fluid"
        onHide={() => {
          setViewRequest(undefined);
        }}
        footer={recordDialogFooter}
      >
        <div>
          {_renderProduts()}
        </div>
      </Dialog>

      {/* Modal CHAVE DANFE */}
      <Dialog
        visible={typeof separeRequest !== 'undefined'}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={'Chave da NFe'}
        modal
        className="p-fluid"
        onHide={() => {
          setSepareRequest(undefined);
        }}
        footer={nfkeyFooter}
      >
        <div>
          <SanitizedInput
            value={nfkey}
            onChange={(e: any) => {
              setNfkey(e.target.value)
            }}
            placeholder={'Chave da NFe'}
            type="text"
            size={50}
          />
        </div>
      </Dialog>
    </>
  );
}

export default RequestsView;
