import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Card,
  Row,
  Col,
} from "reactstrap";
import Language from "../../language/language";

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import LoadingSpin from "../../components/loading/loading";
import { getChildrens } from "../../api/users";
import { useUser } from "../../contexts/user/UserWrapper";
import "./styles.css";

function AffiliatesView() {
  const { language } = useLanguage();
  const { user } = useUser();

  const toast = useRef<any>(null);

  const [childrens, setChildrens] = useState<iChildren[]>([]);
  const [children, setChildren] = useState<iChildren>();
  const [generation, setGeneration] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const backGeneration = () => {
    if (generation > 1) {
      setGeneration(generation - 1);

      if (children?.backChild) setChildren(children.backChild);
    } else {
      setGeneration(0);

      setChildren(undefined);
    }
  }

  const renderChildren = (child: iChildren, back: boolean = false) => {
    if (!child) return;
    
    return (
      <Button 
        className="affiliate-container"
        onClick={
          () => {
            if (back) return backGeneration();

            if (generation < 5 && child.affiliates > 0) {
              setGeneration(generation + 1);

              const back: iChildren = child;

              if (children && !back.backChild) {
                back.backChild = children;
              }

              setChildren(back);
            }
          }
        }
      >
        {child && child.pin ? (
          <img  src={child.pin} style={{height: '15vmin', width: '15vmin'}} alt="logo" />
        ) : null}

        <div className="affiliate-div">
          <span style={{
            fontWeight: 'bold',
            fontSize: '1rem',
          }}>{child.name}</span>

          <span className="info-text">{`${Language(language).TotalAffiliates}: ${child.affiliates}`}</span>

          <span className="info-text">{`${child.score} ${Language(language).Points}`}</span>
          
          {child.is_active? (
            <div className="tag-active">
              <span className="tag-text">{Language(language).ACTIVE}</span>
            </div>
          ) : (
            <div className="tag-inactive">
              <span className="tag-text">{Language(language).INACTIVE}</span>
            </div>
          )}

        {/* <Row>
          <i className="tim-icons icon-money-coins info-icon" />
          <span className="info-text">{`${Language(language).Gains}: ${child.gain}`}</span>
        </Row> */}
        </div>
      </Button>
    )
  }

  const renderChildrens = () => {
    if (childrens.length === 0) return (
      <span style={{alignSelf: 'center'}}>{Language(language).NoAffiliates}</span>
    );

    return childrens.map((item) => renderChildren(item));
  }

  useEffect(() => {
    if (!user?._id) return;

    if (typeof children === 'undefined') {
      setLoading(true);
      getChildrens(user._id)
        .then((response) => {
          setChildrens(response.data);
        })
        .catch(e => console.log('getChildrens error', e))
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      getChildrens(children.id)
        .then((response) => {
          setChildrens(response.data);
        })
        .catch(e => console.log('getChildrens error', e))
        .finally(() => setLoading(false));
    }
  }, [children, user])
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <div>
          <>
            <h4 className="title">{Language(language).YourAffiliates}</h4>

            {children ? (
              <Row style={{marginBottom: 20}}>
                <Col>
                  {renderChildren(children, true)}
                </Col>
                <Col md='3'>
                  <Button 
                    style={{
                      margin: '1rem',
                      paddingLeft: '2rem',
                      paddingRight: '2rem',
                      borderRadius: '5px',
                      background: 'linear-gradient(#511010 100%, #902A2B 100%)',
                      border: '1px solid #CCAA50',
                    }}
                    className="members-button"
                    onClick={backGeneration}
                  >
                    <i className="pi pi-undo" />
                  </Button>
                </Col>
              </Row>
            ) : null}

            {children ? (
              <h4 className="title">{Language(language).XXXAffiliates(children.name)}</h4>
            ) : null}

            <Row>
              {loading ? (<LoadingSpin />) : renderChildrens()}
            </Row>
            
          </>
        </div>
      </div>
    </>
  );
}

export default AffiliatesView;
