import axios from "axios"
import config from "../../config"

export const getKitById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}kits/getOne/${id}`)

  return response;
}

export const createKit = async (data: iKit) => {
  const response = await axios.post(`${config.API_URL}kits/post`, data);

  return response;
}

export const getKits = async () => {
  const response = await axios.get(`${config.API_URL}kits/getAll`)

  return response;
}

export const purchaseKit = async (user_id: string, kit_id: string) => {
  const response = await axios.get(`${config.API_URL}kits/purchase/${user_id}/${kit_id}`)

  return response;
}

export const getNextKits = async (id: string) => {
  const response = await axios.get(`${config.API_URL}kits/getNext/${id}`)

  return response;
}

export const updateKit = async (id: string, data: iKit) => {
  const response = await axios.patch(`${config.API_URL}kits/update/${id}`, data);

  return response;
}

export const deleteKit = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}kits/delete/${id}`);

  return response;
}
