import axios from "axios"
import config from "../../config"

export const getBookById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}books/getOne/${id}`)

  return response;
}

export const createBook = async (couser_id: string, data: iBook, image?: any, file?: any) => {
  const formData = new FormData();

  formData.append('body', JSON.stringify(data));

  if (file) formData.append('file', file);

  if (image) formData.append('image', image);

  const response = await axios.post(`${config.API_URL}books/post/${couser_id}`, formData);

  return response;
}

export const getBooks = async (course_id: string) => {
  const response = await axios.get(`${config.API_URL}books/getAll/${course_id}`)

  return response;
}

export const updateBook = async (id: string, data: iBook, image?: any, file?: any) => {
  const formData = new FormData();

  formData.append('body', JSON.stringify(data));

  if (file) formData.append('file', file);

  if (image) formData.append('image', image);

  const response = await axios.patch(`${config.API_URL}books/update/${id}`, formData);

  return response;
}

export const deleteBook = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}books/delete/${id}`);

  return response;
}
