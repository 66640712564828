import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardText,
} from "reactstrap";

import LoadingSpin from "../../components/loading/loading";
import { cashoutPaid, cashoutRequests, usersFinance } from "../../api/users";
import { Dialog } from "primereact/dialog";

function FinanceView() {
  const toast = useRef<any>(null);

  const [total, setTotal] = useState<iUsersFinance>();
  const [list, setList] = useState<iCashoutRequest[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loadingList, setLoadingList] = useState(true);
  const [paid, setPaid] = useState<iCashoutRequest>();

  const loadTotal = async () => {
    await usersFinance()
      .then((response) => {
        setTotal(response.data[0]);
      })
      .catch((e) => console.log('loadTotal error', e))
      .finally(() => setLoadingTotal(false));
  }

  const loadList = async () => {
    await cashoutRequests()
      .then((response) => {
        setList(response.data);
      })
      .catch((e) => console.log('loadList error', e))
      .finally(() => setLoadingList(false));
  };

  const pay = async () => {
    if (!paid) return;

    setLoading(true);

    await cashoutPaid(paid.id)
      .then(() => {
        setPaid(undefined);

        loadList();

        loadTotal();
      })
      .catch((e) => console.log('cashoutPaid error', e));
  }

  const renderList = () => {
    if (list.length === 0) return(
      <span>Não há solicitações de saque.</span>
    )

    return list.map((item) => (
      <>
        <Row style={{alignItems: 'center'}}>
          <span>{`${item.name} - valor: ${item.loot} - PIX: ${item.pix} ${item.type}`}</span>
          <Button
            color="info" 
            className="animation-on-hover" 
            style={{
              padding: 5,
              marginRight: 10,
              marginLeft: 5,
            }}
            onClick={() => {
              navigator.clipboard.writeText(
                item.pix
              );

              toast.current.show({ 
                severity: "success", 
                summary: 'Copiado!', 
                detail: 'PIX copiado', 
                life: 3000
              });
            }}
          >
            <i className="pi pi-copy" style={{ fontSize: '1rem', marginLeft: 10 }} />
          </Button>
          <Button
            color="danger"
            className="animation-on-hover" 
            onClick={() => setPaid(item)}
          >
            Pago?
          </Button>
        </Row>
        <hr />
      </>
    ))
  }

  const recordDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => setPaid(undefined)} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={pay} >
        {loading ? 'Processando...' : 'Sim, winner pago!'}
      </Button>
    </>
  );

  useEffect(() => {
    loadTotal();

    loadList();
  }, []);
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <div>
          <Card style={{padding: 20}}>
            <CardHeader>
              <h5 className="title">Financeiro</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col style={{marginBottom: 20}}>
                  {loadingList ? (<LoadingSpin />) : (
                    renderList()
                  )}
                </Col>
                <Col md='4'>
                  {loadingTotal ? (<LoadingSpin />) : (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Button color="info" style={{marginBottom: 20}}>
                        <CardText style={{
                          fontWeight: 'bold', 
                          fontSize: '1rem', 
                          marginBottom: 8,
                        }}>
                          Saldo total dos usuários
                        </CardText>
                        <CardText style={{fontWeight: 'bold', fontSize: '2rem'}}>
                          {`R$ ${total?.total_balance?.toFixed(2)}`}
                        </CardText>
                      </Button>

                      <Button color="danger" style={{marginBottom: 20}}>
                        <CardText style={{
                          fontWeight: 'bold', 
                          fontSize: '1rem', 
                          marginBottom: 8,
                        }}>
                          Total de saques
                        </CardText>
                        <CardText style={{fontWeight: 'bold', fontSize: '2rem'}}>
                          {`R$ ${total?.total_loot?.toFixed(2)}`}
                        </CardText>
                      </Button>

                      <Button style={{marginBottom: 20}}>
                        <CardText style={{
                          fontWeight: 'bold', 
                          fontSize: '1rem', 
                          marginBottom: 8,
                        }}>
                          Total (Saldo + Saques)
                        </CardText>
                        <CardText style={{fontWeight: 'bold', fontSize: '2rem'}}>
                          {`R$ ${((total?.total_loot || 0) + (total?.total_balance || 0)).toFixed(2)}`}
                        </CardText>
                      </Button>

                      <Button color="primary" style={{marginBottom: 20}}>
                        <CardText style={{
                          fontWeight: 'bold', 
                          fontSize: '1rem', 
                          marginBottom: 8,
                        }}>
                          Total Entrada
                        </CardText>
                        <CardText style={{fontWeight: 'bold', fontSize: '2rem'}}>
                          {`R$ ${total?.total?.toFixed(2)}`}
                        </CardText>
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>

      {/* Modal saque pago */}
      <Dialog
        visible={typeof paid !== 'undefined'}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={'Saque Pago'}
        modal
        className="p-fluid"
        onHide={() => {
          setPaid(undefined);
        }}
        footer={recordDialogFooter}
      >
        <>
          <label>{`${paid?.name} - ${paid?.loot} PAGO?`}</label>
        </>
      </Dialog>
    </>
  );
}

export default FinanceView;
