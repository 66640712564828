/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Nav, NavItem, NavLink } from "reactstrap";
import { useLanguage } from "../../contexts/language/LanguageWrapper";
import Language from "../../language/language";

function Footer() {
  const { language } = useLanguage();

  return (
    <footer className="footer">
      <Container>
        <Nav>
          <NavItem>
            <NavLink href="">
              Go Winner
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="">
              {Language(language).AboutUs}
            </NavLink>
          </NavItem>
        </Nav>
        <div className="copyright">
          © {new Date().getFullYear()} made with{" "}
          <i className="tim-icons icon-heart-2" /> by{" "}
          Go Winner.
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
