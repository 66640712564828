import axios from "axios"
import config from "../../config"

export const getPlanById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}plans/getOne/${id}`)

  return response;
}

export const createPlan = async (data: iPlan) => {
  const response = await axios.post(`${config.API_URL}plans/post`, data);

  return response;
}

export const getPlans = async () => {
  const response = await axios.get(`${config.API_URL}plans/getAll`)

  return response;
}

export const updatePlan = async (id: string, data: iPlan) => {
  const response = await axios.patch(`${config.API_URL}plans/update/${id}`, data);

  return response;
}

export const deletePlan = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}plans/delete/${id}`);

  return response;
}

export const getInitialPlans = async () => {
  const response = await axios.get(`${config.API_URL}plans/getInitial`)

  return response;
}

export const getNextPlans = async (id: string) => {
  const response = await axios.get(`${config.API_URL}plans/getNext/${id}`)

  return response;
}

export const getPlansMinLevel = async (level: number | string) => {
  const response = await axios.get(`${config.API_URL}plans/getPlansMinLevel/${level}`)

  return response;
}

export const purchasePlan = async (user_id: string, plan_id: string) => {
  const response = await axios.get(`${config.API_URL}plans/purchase/${user_id}/${plan_id}`)

  return response;
}

export const monthlyPlan = async (user_id: string, plan_id: string) => {
  const response = await axios.get(`${config.API_URL}plans/monthly/${user_id}/${plan_id}`)

  return response;
}
