export const es: iLanguage = {
  CompleteRegistration: 'Completa tu registro',
  Loading: 'Cargando...',
  Name: 'Nombre',
  Lastname: 'Apellido',
  Username: 'Nombre de usuario',
  Password: 'Contraseña',
  RetypePassword: 'Vuelva a escribir la contraseña',
  Send: 'Enviar',
  PIXkey: 'Tecla PIX',
  EnterPIXagain: 'Ingrese la clave PIX nuevamente',
  InvalidData: 'Datos inválidos',
  AcceptTerms: 'Acepto los términos de uso.',
  AcceptTermsOfUse: 'Aceptar las condiciones de uso',
  AcceptThe: 'Acepto los ',
  InvalidParameter: '¡Parametro invalido!',
  RegisteredSuccess: 'Usuario registrado exitosamente! Ahora puedes iniciar sesión.',
  MembersArea: 'Área de miembros',
  EmailOrUsername: 'Correo electrónico o nombre de usuario',
  TypePassword: 'Escribe tu contraseña',
  Enter: 'Entrar',
  EnterEmailOrUsername: 'Ingrese su correo electrónico o nombre de usuario para iniciar sesión',
  EnterPasswordToLogin: 'Ingrese su contraseña para iniciar sesión',
  SomethingWrongTryAgain: '¡Algo salió mal! Inténtalo de nuevo',
  StayConnected: 'Mantente conectado',
  Wait: 'Esperar',
  AboutUs: 'Sobre nosotros',
  SelectAPlan: 'Selecciona un plan',
  month: 'mes',
  Purchase: 'Compra',
  SomethingWrong: '¡Algo salió mal!',
  RefreshPage: 'Recarga la página',
  PayAccessNow: '¡Paga con PIX y obtén acceso a nuestro contenido ahora mismo!',
  PayAccessNewNow: '¡Paga con PIX y obtén acceso a contenido nuevo ahora mismo!',
  PayContinueAccessNewNow: '¡Paga con PIX y continúa accediendo a nuestro contenido ahora mismo!',
  CopyQRCode: 'Copiar código QR',
  QRCodeCopied: '¡Código QR copiado!',
  Success: 'Éxito',
  Gains: 'Ganancias Totales',
  Balance: 'Balance',
  TotalAffiliates: 'Total de Afiliados',
  Losses: 'Bono perdido',
  Graduation: 'Graduación',
  CurrentPlan: 'Plan actual',
  Affiliates: 'Afiliados',
  INACTIVE: 'INACTIVO',
  ACTIVE: 'ACTIVO',
  NoAffiliates: 'Sin afiliados',
  ReferralLink: 'Enlace de referencia',
  Copied: '¡Copiado!',
  ReferralLinkCopied: '¡Enlace de referencia copiado!',
  RequestCashout: 'Solicitud de retirada',
  Cancel: 'Cancelar',
  Request: 'Pedido',
  Processing: 'Processando...',
  WithdrawalAmount: 'Cantidad de retiro',
  RequestSentWitinXDays: (t: any) => `Solicitação enviada. Enviaremos um PIX em até ${t} dias.`,
  Hi: 'Hola',
  welcome: 'sea bienvenido',
  DirectReferrals: 'Referencias directas',
  CreateCourse: 'Crear curso',
  EditCourse: 'Editar Curso',
  Create: 'Crear',
  CourseName: 'Nombre del curso',
  Description: 'Descripción',
  optional: 'opcional',
  DiscoverCourse: 'Descubre el curso',
  InsertLinkDescribesCourse: 'Inserte aquí un enlace a una página que describa su curso.',
  PageLink: 'Enlace de página',
  Support: 'Soporte',
  SetContactSupport: 'Establecer la información de contacto que aparecerá para los estudiantes que necesitan ayuda',
  SupportEmail: 'Correo electrónico de soporte',
  SupportPhone: 'Teléfono de soporte',
  Image: 'Imagen',
  EnterValidName: 'Por favor ingrese un nombre valido',
  EnterValidDescription: 'Introduzca una descripción válida',
  NotCreatedCourses: 'Aún no has creado ningún curso. ¡Crea un curso y conviértete en un experto ahora mismo!',
  RecordSaved: 'Registro guardado',
  Save: 'Ahorrar',
  Modules: 'Módulos',
  ThisCourseNotHaveModules: 'Este curso aún no tiene módulos. Crea módulos y agrega contenido a tu curso.',
  CreateModule: 'Crear módulo',
  EditModule: 'Editar módulo',
  ModuleName: 'Nombre del módulo',
  Number: 'Número',
  RepresentsOrderingModule: 'Representa el orden del módulo. Déjelo en blanco y el módulo se numerará automáticamente.',
  Content: 'Contenido',
  EditContent: 'Contenido editado',
  CreateContent: 'Crear contenido',
  ContentName: 'Nombre del contenido',
  RepresentsOrderingContent: 'Representa el orden del contenido. Déjelo en blanco y el contenido se numerará automáticamente.',
  YouTubeID: 'ID de vídeo de YouTube',
  IfVideoLinkIs: "Si el enlace del video es 'https://www.youtube.com/watch?v=fV3u-UotCJI', el ID del video sería: 'fV3u-UotCJI'",
  Text: 'Texto',
  ExternalLinks: 'Enlaces externos con contenido complementario al de tu clase.',
  AddLink: 'Añadir enlace',
  Files: 'Archivos',
  AddUpToFiles: (qt: any, size: any) => `Agregue hasta ${qt} archivos de máximo ${size} MB cada uno.`,
  NoContentInThisModule: 'No hay contenido en este módulo. Agregue contenido para sus estudiantes.',
  Delete: '¿Borrar?',
  DeleteContentProcessIrreversible: '¿Estás seguro de que deseas eliminar el contenido? Este proceso es irreversible.',
  YesDelete: '¡Sí, eliminar!',
  DeletedRecord: 'Registro eliminado',
  DeleteModuleProcessIrreversible: '¿Está seguro de que desea eliminar el módulo y todo su contenido? Este proceso es irreversible.',
  Publish: '¿Publicar?',
  Courses: 'Cursos',
  LearnMoreClikHere: '¿Saber más sobre el curso? Haga clic aquí',
  NeedHelpContactCourseSupport: '¿Necesita ayuda? Contactar con el soporte del curso',
  CourseUnderConstruction: 'Este curso aún está en construcción. Pronto podrás acceder a tu contenido.',
  ModuleUnderConstruction: 'Este módulo aún está en construcción. Pronto podrás acceder a tu contenido.',
  Start: '¡Empezar!',
  Continue: 'Continuar',
  Certificate: 'Certificado',
  YouCompletedCourse: '¡Has completado este curso!',
  Concluded: '¡Concluido!',
  AccessContent: 'Acceder al contenido',
  ExtraContent: 'Contenido extra',
  YouCompletedModule: '¡Has completado este módulo!',
  Progress: 'Progreso',
  TermsUse: 'Terminos de Uso',
  PreviousClassRequired: '¿Requiere clases previas?',
  CheckIfMandatoryPreviousClass: 'Seleccione esta opción si es obligatorio completar la clase anterior para publicar este contenido',
  Days: 'Días',
  YouCanAlsoDaysSince: 'También puede establecer la cantidad de días calendario desde la finalización de la clase anterior para publicar este contenido.',
  Unavailable: 'Indisponible',
  ContentNotAvailable: 'Este contenido no está disponible',
  ReleaseCertificate: '¿Liberar Certificado?',
  NeedChooseCertificateTemplate: 'Debes elegir una plantilla de certificado',
  CertificateText: (course: string, hour: number | string) => `Quienes completaron magistralmente el curso ${course} con una carga horaria de ${hour} horas, a través de los conocimientos transmitidos por la plataforma online GO WINNER`,
  Title: 'Título',
  TitleCertificateDesc: 'Este es el título que aparecerá debajo de su nombre en el certificado. (Maestro, Instructor, etc.)',
  Workload: 'Carga horaria',
  Author: 'Autor',
  IssueCertificate: 'Emitir Certificado',
  Print: 'Imprimir',
  Library: 'Biblioteca',
  CreateBook: 'Crear libro',
  EditBook: 'Editar libro',
  BookName: 'Nombre del libro',
  SendFile: '¿Enviar archivo?',
  EnterValidLink: 'Por favor proporcione un enlace válido',
  SelectFile: 'Seleccione un archivo',
  LogOut: 'Salir',
  ForgotPassword: '¿Olvidaste tu contraseña?',
  EnterMailORUsername: 'Ingrese su correo electrónico O nombre de usuario',
  EmailORUsername: 'Correo electrónico o nombre de usuario',
  EmailWithResetLinkSent: (mail: string) => `Se envió un correo electrónico con un enlace para restablecer la contraseña a ${mail}`,
  ResetPassword: 'Redefinir contraseña',
  PasswordResetSuccess: '¡Restablecimiento de contraseña exitoso! Ahora puedes iniciar sesión',
  NewPassword: 'Nueva contraseña',
  ShowName: 'Mostrar nombre',
  Access: '¡Acceso!',
  OurPlans: 'Nuestros planes',
  SelectPlan: 'Seleccionar Plan',
  ShowMore: 'Mostrar más',
  Remove: 'Eliminar',
  AreYouSure: '¿Está seguro?',
  RemoveCouseFromList: '¿Quitar este curso de tu lista?',
  YesRemove: '¡Sí, elimina!',
  Removed: 'Eliminado',
  YouHaventAccessedCourses: 'Aún no has accedido a ningún curso.',
  MyCourses: 'Mis cursos',
  PasswordMustBe: 'Su contraseña debe tener al menos 8 dígitos',
  Graduate: '¡Graduar!',
  YouNeedLargerPlanToCourse: 'Necesitas un plan más grande para acceder a este curso.',
  Search: 'Buscar',
  PIXKeyType: 'Tipo de clave PIX',
  Phone: 'Teléfono',
  RandomKey: 'Clave aleatoria',
  Category: 'Categoría',
  All: 'Todo',
  WeeklyRank: 'Clasificación Semanal',
  Indications: 'Indicaciones',
  Birthdate: 'Fecha de nacimiento',
  Profile: 'Perfil',
  Heirs: 'Herederos',
  HeirsDesc: 'Nombre y documento de un máximo de 3 personas que pueden heredar tu cuenta Go Winner en caso de tu fallecimiento',
  NameAndDocument: 'Nombre y documento',
  RememberMe: 'Acuérdate de mí',
  Register: 'Registro',
  WellcomeVirtualOffice: '¡Bienvenido a tu Oficina Virtual Go Winner!',
  Points: 'Agujas',
  YourAffiliates: 'Tus afiliados',
  XXXAffiliates: (t: string) => `${t} afiliados`,
  Choose: 'Elegir',
  Buy: 'Comprar',
  CartItems: (t: number | string) => `Carrito - ${t} artículos`,
  AddedToCart: '¡Añadido al carrito!',
  Size: 'Tamaño',
  Color: 'Color',
  SelectAKit: 'Seleccione un kit',
  Or: 'O',
  ValueOfProducts: 'Valor de los productos',
  Discount: 'Descuento',
  KitValue: 'Valor del kit',
  Products: 'Productos',
  Accessories: 'Accesorios',
  ChooseShipping: 'Elige Envío',
  NewAddress: 'Nueva dirección',
  Address: 'DIRECCIÓN',
  EditAddress: 'Editar dirección',
  RecipientName: 'Nombre del destinatario',
  Street: 'Camino',
  Complement: 'Complemento',
  District: 'Barrio',
  City: 'Ciudad',
  CEP: 'Código Postal',
  State: 'Estado',
  AddressSaved: '¡Dirección guardada!',
  NaturalPerson: 'Individual',
  LegalPerson: 'Persona Jurídica',
  StateRegister: 'Registro Estatal',
  Shipping: 'Transporte',
  PickupInStore: 'Recoger en Tienda',
  Confirm: 'Confirmar',
  Checkout: 'Finalizar compra',
  FinalValue: 'Valor Final',
  StillXKit: (t: string | number) => `Aún quedan ${t} en descuento para cerrar el kit`,
  OurKits: 'Nuestros kits',
  CurrentKit: 'Equipo actual',
  SelectKit: 'Seleccionar kit',
  Free: 'Gratis',
}

export default es;
