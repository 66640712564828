import axios from "axios"
import config from "../../config"

export const getGraduationById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}graduations/getOne/${id}`)

  return response;
}

export const createGraduation = async (data: iGraduation) => {
  const response = await axios.post(`${config.API_URL}graduations/post`, data);

  return response;
}

export const getGraduations = async () => {
  const response = await axios.get(`${config.API_URL}graduations/getAll`)

  return response;
}

export const updateGraduation = async (id: string, data: iGraduation) => {
  const response = await axios.patch(`${config.API_URL}graduations/update/${id}`, data);

  return response;
}

export const deleteGraduation = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}graduations/delete/${id}`);

  return response;
}

export const getInitialGraduations = async () => {
  const response = await axios.get(`${config.API_URL}graduations/getInitial`)

  return response;
}

export const getNextGraduations = async (id: string) => {
  const response = await axios.get(`${config.API_URL}graduations/getNext/${id}`)

  return response;
}

export const purchaseGraduation = async (user_id: string, plan_id: string) => {
  const response = await axios.get(`${config.API_URL}graduations/purchase/${user_id}/${plan_id}`)

  return response;
}

export const monthlyGraduation = async (user_id: string, plan_id: string) => {
  const response = await axios.get(`${config.API_URL}graduations/monthly/${user_id}/${plan_id}`)

  return response;
}
