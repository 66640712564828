import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import './index.css';

// reactstrap components
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { getProduts } from "../../api/produts";
import { getProdCategorys } from "../../api/produts/category";
import ImagCarousel from "../../components/imgCarousel";
import Language from "../../language/language";
import Cart from "./components/cart";
import { useUser } from "../../contexts/user/UserWrapper";
import { useLanguage } from "../../contexts/language/LanguageWrapper";
import { Icon as DropDownIcon } from "../../assets/icons/dropdownIcon";
import XIcon from "../../assets/icons/X.svg";
import LoadingSpin from "../../components/loading/loading";

export const getProdutsTotal = (produts: iProdut[], percent: number) => {
  let result: iRequestTotal = {
    total: 0,
    discount: 0,
    other_total: 0,
  }

  produts.forEach((produt) => {
    if (produt.buy_quant) {
      const total = produt.price * produt.buy_quant;

      if (!produt.store) {
        result.total = result.total + total;

        const discount = total * percent / 100;

        result.discount = result.discount + discount;
      }
      else result.other_total = result.other_total + total;
    }
  });

  return result;
}

function StoreView(props: iProps) {
  const { user, kit, handleUser } = useUser();
  const { language } = useLanguage();

  const [produts, setProduts] = useState<iProdut[]>([]);
  const [choose, setChoose] = useState<iProdut>();
  const [loading, setLoading] = useState(true);

  const [categorys, setCategorys] = useState<iProdutCategory[]>([]);

  const toast = useRef<any>(null);

  const loadRecords = async () => {
    setLoading(true);

    await getProduts()
      .then((response) => {
        const items: iProdut[] = response.data;
        
        setProduts(items.filter((i) => ((props.store && i.store) || (!props.store && !i.store))));
      })
      .finally(() => setLoading(false));
  }

  const loadCategorys = async () => {
    await getProdCategorys()
      .then((response) => {
        setCategorys(response.data);
      })
      .catch((e) => console.log('getProdCategorys error', e));
  }

  const addToCart = (produt: iProdut) => {
    if (!user) return;

    let cart: iProdut[] = [];

    if (user.cart) cart = [...user.cart];

    const find = cart.find((prod) => prod._id === produt._id && prod.size === produt.size && prod.color === produt.color);

    if (!find) cart.push(produt);
    else cart = cart.map((prod) => prod._id !== produt._id ? prod : { 
      ...prod, 
      buy_quant: (prod?.buy_quant || 1) + (produt?.buy_quant || 1),
    });

    handleUser({ ...user, cart });

    toast.current.show({ 
      severity: "success", 
      summary: Language(language).Success, 
      detail: Language(language).AddedToCart, 
      life: 3000
    });
  }

  const _renderProdut = () => produts.map((produt, index) => {
    if (props.store && !produt.store) return null;

    if (!props.store && produt.store) return null;

    let haveStock = false;

    const { stock, multistock } = produt;

    if (multistock) {
      let key = '';
      let color = produt.color;
      let size = produt.size;

      if (!size && produt.sizes.length) size = produt.sizes[0];
      if (!color && produt.colors.length) color = produt.colors[0].name;

      if (color && color !== '' && size && size !== '') key = `${color} ${size}`;

      else if (color && color !== '') key = color;

      else if (size && size !== '') key = size;

      if (key !== '' && multistock[key]) haveStock = true;
    } 
    else if (typeof stock === 'number' && stock > 0) haveStock = true;
    else if (stock === undefined || stock ===  null) haveStock = true;

    return (
      <div className="produt-container">
        <ImagCarousel className="produt-img" images={produt.images}/>
  
        <span className="produt-name">{produt.name}</span>
  
        <span className="produt-desc">{produt.description}</span>
  
        <span className="produt-price">R$ {produt.price.toFixed(2)}</span>
  
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <span className="produt-desc">{produt.reference}</span>
  
          <span className="produt-score">{`${produt.score} ${Language().Points.toLowerCase()}`}</span>
        </div>
  
        {haveStock && !produt.sizes?.length && !produt.colors?.length ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <Button 
              className="buy-button-minum"
              onClick={() => {
                if (produt?.buy_quant && produt.buy_quant > 1) {
                  const newProds: iProdut[] = [...produts];
  
                  newProds[index].buy_quant = produt.buy_quant - 1;
    
                  setProduts(newProds);
                }
              }}
            >
              <span className="choose-button-text">-</span>
            </Button>
  
            <div className="buy-quant">{produt.buy_quant || 1}</div>
  
            <Button 
              className="buy-button-plus"
              onClick={() => {
                const newProds: iProdut[] = [...produts];
                let buy_quant = produt.buy_quant || 1;

                if (typeof produt.stock !== 'undefined' && buy_quant >= produt.stock)
                  buy_quant = produt.stock - 1;
    
                newProds[index].buy_quant = buy_quant + 1;
    
                setProduts(newProds);
              }}
            >
              <span className="choose-button-text">+</span>
            </Button>
  
            <Button 
              className="buy-button"
              onClick={() => addToCart({...produt, buy_quant: produt.buy_quant || 1})}
            >
              <span className="choose-button-text">{Language().Buy}</span>
            </Button>
          </div>
        ) : haveStock ? (
          <Button 
            className="choose-button"
            onClick={() => {
              setChoose(produt);
            }}
          >
            <span className="choose-button-text">{Language().Choose}</span>
          </Button>
        ) : null}
      </div>
    )
  });

  const renderChoose = () => {
    if (!choose) return null;

    let haveStock = false;
    let stock: number;

    const { multistock } = choose;

    if (multistock) {
      let key = '';
      let color = choose.color;
      let size = choose.size;

      if (!size && choose.sizes.length) size = choose.sizes[0];
      if (!color && choose.colors.length) color = choose.colors[0].name;

      if (color && color !== '' && size && size !== '') key = `${color} ${size}`;

      else if (color && color !== '') key = color;

      else if (size && size !== '') key = size;

      if (key !== '' && multistock[key]) {
        haveStock = true;
        stock = multistock[key];
      }
    } 
    else if (multistock === undefined || multistock ===  null) haveStock = true;

    return (
      <>
        <Button 
          className="modal-xicon"
          onClick={() => setChoose(undefined) }
        >
          <img src={XIcon} alt="" />
        </Button>

        <div style={{
          display: 'flex',
          margin: '1rem',
        }}>
          <ImagCarousel className="choose-image" images={choose.colors.find((color) => color.name === choose.color)?.images || choose.images} />

          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <span className="choose-name">{choose.name}</span>

            {choose.sizes.length ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
                <span className="choose-label">{Language(language).Size}:</span>
  
                <UncontrolledDropdown group>
                  <DropdownToggle className="choose-drop">
                    <span className="choose-drop-text">
                      {choose.size && choose.size !== '' ? choose.size : choose.sizes[0]}
                    </span>
  
                    <DropDownIcon fill='black' />
                  </DropdownToggle>
                  <DropdownMenu>
                    {renderSizes()}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            ) : null}

            {choose.colors.length ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
                <span className="choose-label">{Language(language).Color}:</span>
  
                <UncontrolledDropdown group>
                  <DropdownToggle className="choose-drop">
                    <span className="choose-drop-text">
                      {choose.color && choose.color !== '' ? choose.color : choose.colors[0]?.name}
                    </span>
  
                    <DropDownIcon fill='black' />
                  </DropdownToggle>
                  <DropdownMenu>
                    {renderColors()}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            ) : null}

            {haveStock ? (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <Button 
                  className="buy-button-minum"
                  onClick={() => {
                    if (choose.buy_quant) {
                      setChoose({
                        ...choose,
                        buy_quant: choose.buy_quant - 1
                      })
                    }
                  }}
                >
                  <span className="choose-button-text">-</span>
                </Button>
  
                <div className="buy-quant">{choose.buy_quant || 1}</div>
  
                <Button 
                  className="buy-button-plus"
                  onClick={() => {
                    let buy_quant = choose.buy_quant || 1;

                    if (typeof stock !== 'undefined' && buy_quant >= stock) buy_quant = stock - 1;
        
                    setChoose({
                      ...choose,
                      buy_quant: buy_quant + 1
                    })
                  }}
                >
                  <span className="choose-button-text">+</span>
                </Button>
  
                <Button 
                  className="buy-button"
                  onClick={() => {
                    addToCart({...choose, buy_quant: choose.buy_quant || 1});
                    setChoose(undefined);
                  }}
                >
                  <span className="choose-button-text">{Language().Buy}</span>
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </>
    )
  }

  const renderSizes = () => choose?.sizes.map((size) => (
    <DropdownItem onClick={() => setChoose(() => {
      return {
        ...choose,
        size,
        buy_quant: 1,
      }
    })}>
      <span className="choose-drop-text">{size}</span>
    </DropdownItem>
  ))

  const renderColors = () => choose?.colors.map((color) => (
    <DropdownItem onClick={() => setChoose(() => {
      return {
        ...choose,
        color: color.name,
        buy_quant: 1,
      }
    })}>
      <span className="choose-drop-text">{color.name}</span>
    </DropdownItem>
  ));

  const renderTotal = () => {
    if (!kit && !user?.plan_id) return null;

    if (!user?.cart?.length) return null;

    const total = getProdutsTotal(user.cart, kit?.percent || 0);

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <span className="total-left-text">{Language(language).Products}:</span>
          
          <span className="total-left-text">{!user.new_kit ? Language(language).Discount : `${Language(language).KitValue} (${Language(language).Discount})`}:</span>

          {total.other_total > 0 ? (
            <span className="total-left-text">{Language(language).Accessories}:</span>
          ) : null}
          
          <span className="total-left-text">Total:</span>
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <span className="total-right-text">R$ {total.total.toFixed(2)}</span>
          
          <div>
            <span className="total-right-text-green">R$ {total.discount.toFixed(2)}</span>
            {' '}
            {kit ? (<span className="total-right-text">({kit.percent}%)</span>) : null}
          </div>

          {total.other_total > 0 ? (
            <span className="total-right-text">R$ {total.other_total.toFixed(2)}</span>
          ) : null}

          <span className="total-right-text-green">R$ {(total.total + total.other_total - total.discount).toFixed(2)}</span>
        </div>
      </div>
    )
  }

  useEffect(() => {
    loadRecords();
  }, []);

  return (
    <>
      <Toast ref={toast} />

      {loading ? (<LoadingSpin />) : (
        <div className="content">

          {renderTotal()}

          <div style={{
            display: 'flex',
          }}>
            <Cart produts={user?.cart}/>
          </div>

          <Row className="center-mobile-row">
            {_renderProdut()}
          </Row>
        </div>
      )}

      {/* Modal Escolher Produto */}
      <Dialog
        visible={choose !== undefined}
        modal
        className="choose-modal"
        onHide={() => setChoose(undefined) }
        content={renderChoose}
      />
    </>
  );
}

export default StoreView;

interface iProps {
  store?: boolean,
}
