import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "./ConfigContext"
import { getConfig } from "../../api/config";

export default function ConfigContextWrapper(props: any) {
  const [config, setConfig] = useState<iConfig | undefined>();

  const loadConfig = async () => {
    await getConfig()
      .then((response) => {
        setConfig(response.data);
      })
  }

  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ 
      config: config, 
      loadConfig: loadConfig,
    }}>
      {props.children}
    </ConfigContext.Provider>
  );
}

export const useConfig = () => useContext(ConfigContext);
