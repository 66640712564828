import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
} from "reactstrap";
import Language from "../../language/language";

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import LoadingSpin from "../../components/loading/loading";
import { useUser } from "../../contexts/user/UserWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { getContent } from "../../api/contents";
import YouTubeVideo from "../../components/YouTubePlayer";

function ContentView() {
  const { language } = useLanguage();
  const { user, accessedCourses, accessCourse } = useUser();
  const { id, course_id } = useParams();
  const navigate = useNavigate();

  const [content, setContent] = useState<iContent>();
  const [unavailable, setUnavailable] = useState(false);
  const [load, setLoad] = useState<boolean>(true);

  const toast = useRef<any>(null);

  const loadContent = async () => {
    if (!user?._id || !id) return;

    // id: content_id_atual-content_id_anterior
    const id_split = id.split('-');

    let count_access = false;
    if (!accessedCourses.includes(id_split[0])) count_access = true;

    accessCourse(id_split[0]);

    let completed = 'no';
    if (id_split.length > 1) completed = id_split[1];

    await getContent(id_split[0], user._id, count_access, completed)
      .then((response) => {
        setContent(response.data);

        if (id_split.length > 1 && response.data._id === id_split[1])
          navigate(`/go/courses/${course_id}`);

        setLoad(false);
      })
      .catch((e) => {
        if (e.response?.data === 'unavailable') {
          setUnavailable(true);

          setTimeout(() => {
            navigate(`/go/courses/${course_id}`);
          }, 3000)
        }
        
        console.log('getCourseByUser error', e);
      });
  }

  const nextContent = async () => {
    if (!user?._id || !id || !content || !course_id) return;

    setLoad(true);

    navigate(`/go/learn/${content.next_content || content._id}-${id}/${course_id}`);
  }

  useEffect(() => {
    loadContent();
  }, [user?._id, id]);
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <Card>
          {unavailable ? (
            <label style={{
              fontWeight: 'bold',
              fontSize: 20,
            }}>{Language(language).ContentNotAvailable}</label>
          ) : (
            <CardBody>
              {load || !content ? (<LoadingSpin />) : (
                <>
                  <Row>
                    <Col style={{marginLeft: 10}}>
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Row style={{
                          marginLeft: 5,
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                          <label style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                          }}>{content.name}</label>
                        </Row>
                        <hr />

                        <label style={{
                          fontSize: 16,
                        }}>{content.description}</label>
                        <hr />

                        {content.video ? (
                          <>
                            <YouTubeVideo height="390" width="640" videoId={content.video} />
                            <hr />
                          </>
                        ) : null}

                        {content.text ? (
                          <>
                            <Row style={{
                              marginLeft: 5,
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                              <label style={{
                                fontWeight: 'bold',
                                fontSize: 14,
                              }}>{content.text}</label>
                            </Row>
                            <hr />
                          </>
                        ) : null}

                        {content.files.length ? (
                          <>
                            <hr />
                            <Row style={{
                              marginLeft: 5,
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                              <label style={{
                                fontWeight: 'bold',
                                fontSize: 14,
                              }}>{Language(language).Files}</label>
                            </Row>
                          </>
                        ) : null}

                        {content.files.length ? content.files.map((item) => {
                          return (
                            <CardTitle tag="h4">
                              <a href={item}>{item?.split('/')[10]?.replaceAll('%20', ' ')}</a>
                            </CardTitle>
                          )
                        }) : null}

                        {content.links.length ? (
                          <>
                            <hr />
                            <Row style={{
                              marginLeft: 5,
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                              <label style={{
                                fontWeight: 'bold',
                                fontSize: 14,
                              }}>{Language(language).ExtraContent}</label>
                            </Row>
                          </>
                        ) : null}

                        {content.links.length ? content.links.map((item) => {
                          return (
                            <CardTitle tag="h4">
                              <a href={item}>{item}</a>
                            </CardTitle>
                          )
                        }) : null}

                        <Col md='6'>
                          <hr />
                          <Button 
                            style={{padding: 7, marginLeft: 10}}
                            color="success" 
                            className="animation-on-hover" 
                            onClick={nextContent}
                          >
                            {Language(language).Concluded}
                          </Button>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <hr />
            </CardBody>
          )}
        </Card>
      </div>
    </>
  );
}

export default ContentView;
