import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Language from "../../language/language";

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import LoadingSpin from "../../components/loading/loading";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { getCourseByUser, updateCourse } from "../../api/courses";
import { useUser } from "../../contexts/user/UserWrapper";
import { useParams } from "react-router-dom";
import { createModule, deleteModule, getModules, updateModule } from "../../api/modules";
import { createContent, deleteContent, deleteContentFile, getContents, updateContent } from "../../api/contents";
import { Checkbox } from "primereact/checkbox";
import SanitizedInput from "../../components/sanitizedInput";
import { getCertificates } from "../../api/certificates";
import { Carousel } from "primereact/carousel";
import CertificatePreview from "../../components/certificate";

// Certificate models
import { certificateModels } from "../../utils/Functions";
import { createBook, deleteBook, getBooks, updateBook } from "../../api/books";
import { getCategorys } from "../../api/categorys";

function ModulesView() {
  const { language } = useLanguage();
  const { user } = useUser();
  const { id } = useParams();

  const [recordDialog, setRecordDialog] = useState<boolean>(false);
  const [contentDialog, setContentDialog] = useState<boolean>(false);
  const [records, setRecords] = useState<iModule[]>([]);
  const [course, setCourse] = useState<iCourse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(true);
  const [loadModules, setLoadModules] = useState<boolean>(true);
  const [fieldCourseError, setFieldCourseError] = useState('');
  const [fieldError, setFieldError] = useState('');
  const [contentError, setContentError] = useState('');
  const [image, setImage] = useState<any>();
  const [files, setFiles] = useState<any>();
  const [contentDelete, setContentDelete] = useState<string>();
  const [moduleDelete, setModuleDelete] = useState<string>();
  const [loadingCertificates, setLoadingCertificates] = useState(false);
  const [bookDialog, setBookDialog] = useState<boolean>(false);
  const [bookError, setBookError] = useState('');
  const [bookImage, setBookImage] = useState<any>();
  const [bookLink, setBookLink] = useState(false);
  const [bookFile, setBookFile] = useState<any>();
  const [loadingBooks, setLoadingBooks] = useState<boolean>(true);
  const [books, setBooks] = useState<iBook[]>([]);
  const [bookDelete, setBookDelete] = useState<string>();

  const [categorys, setCategorys] = useState<iCategory[]>([]);

  const certificates = certificateModels();
  const [certificate, setCertificate] = useState<any>();

  const emptyRecord: iModule = {
    name: '',
    description: '',
  }
  const [record, setRecord] = useState<iModule>(emptyRecord);

  const emptyContent: iContent = {
    name: '',
    files: [],
    links: [],
    require_days: 0,
  }
  const [content, setContent] = useState<iContent>(emptyContent);

  const emptyBook: iBook = {
    name: '',
    link: '',
  }
  const [book, setBook] = useState<iBook>(emptyBook);

  const carouselResponsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const toast = useRef<any>(null);

  const saveRecord = async (data?: iModule) => {
    if (!user?._id || !course?._id) return;

    const module = data || record;

    if (module.name.length < 3) {
      setFieldError('name');
      alert(Language(language).EnterValidName);
      return;
    };

    setLoading(true);

    delete module.__v;
    delete module.edited;
    delete module.contents;
    delete module.loading;

    module.course_id = course._id;

    if (!module._id) {
      // Criando novo módulo
      await createModule(module)
        .then(() => {
          hideRecordDialog();

          toast.current.show({ 
            severity: "success", 
            summary: Language(language).Success, 
            detail: Language(language).RecordSaved, 
            life: 3000
          });

          loadRecords();

          setRecord(emptyRecord);
        })
        .catch(e => {
          console.log('createModule error', e);
        })
        .finally(() => setLoading(false));
    } else {
      // Editando módulo
      await updateModule(module._id, module)
      .then(() => {
        hideRecordDialog();

        toast.current.show({ 
          severity: "success", 
          summary: Language(language).Success, 
          detail: Language(language).RecordSaved, 
          life: 3000
        });

        loadRecords();

        setRecord(emptyRecord);
      })
      .catch(e => {
        console.log('updateModule error', e);
      })
      .finally(() => setLoading(false));
    }
  }

  const saveCourse = async () => {
    if (!user?._id || !course?._id) return;

    if (course.name.length < 3) {
      setFieldCourseError('name');
      alert(Language(language).EnterValidName);
      return;
    };

    if (course.name.length < 3) {
      setFieldCourseError('author');
      alert(Language(language).EnterValidName);
      return;
    };

    if (!course.description || course.description.length < 10) {
      setFieldCourseError('description');
      alert(Language(language).EnterValidDescription);
      return;
    };

    delete course.__v;

    setLoading(true);

    await updateCourse(course._id, user._id, course, image)
      .then(() => {
        toast.current.show({ 
          severity: "success", 
          summary: Language(language).Success, 
          detail: Language(language).RecordSaved, 
          life: 3000
        });

        setLoading(false);

        loadCourse();
      })
      .catch(e => {
        console.log('updateCourse error', e);
      })
      .finally(() => setLoading(false));
  }

  const saveContent = async () => {
    if (!user?._id || !content.module_id) return;

    if (content.name.length < 3) {
      setContentError('name');
      alert(Language(language).EnterValidName);
      return;
    };

    delete content.__v;
    delete content.deletingFile;
    delete content.next_content;

    setLoading(true);

    if (!content._id) {
      // Novo conteúdo
      await createContent(content.module_id, content, files)
        .then((response) => {
          toast.current.show({ 
            severity: "success", 
            summary: Language(language).Success, 
            detail: Language(language).RecordSaved, 
            life: 3000
          });

          loadContents(response.data.module_id);
          
          hideContentDialog();
        })
        .catch(e => {
          console.log('createContent error', e);
        })
        .finally(() => setLoading(false));
    } else {
      // Editando conteúdo
      await updateContent(content._id, content, files)
        .then((response) => {
          toast.current.show({ 
            severity: "success", 
            summary: Language(language).Success, 
            detail: Language(language).RecordSaved, 
            life: 3000
          });

          const updated = response.data;
          if (updated._id === content._id && content.module_id) 
              loadContents(content.module_id);
          
          hideContentDialog();
        })
        .catch(e => {
          console.log('updateContent error', e);
        })
        .finally(() => setLoading(false));
    }
  }

  const saveBook = async () => {
    if (!course?._id) return;

    if (book._id && book.course_id !== course._id) return;

    if (book.name.length < 3) {
      setBookError('name');
      alert(Language(language).EnterValidName);
      return;
    };

    if (!bookLink && book.link.length < 3) {
      setBookError('link');
      alert(Language(language).EnterValidLink);
      return;
    }

    if (bookLink && !bookFile) {
      alert(Language(language).SelectFile);
      return;
    }

    setLoadingBooks(true);

    if (!book._id) {
      // Criando livro
      await createBook(course._id, book, bookImage, bookFile)
        .then(() => {
          toast.current.show({ 
            severity: "success", 
            summary: Language(language).Success, 
            detail: Language(language).RecordSaved, 
            life: 3000
          });

          setBook(emptyBook);

          setBookDialog(false);

          loadBooks();
        })
        .catch(e => {
          console.log('createBook error', e);
        })
        .finally(() => setBookLink(false));
    } else {
      // Editando livro
      await updateBook(book._id, book, bookImage, bookFile)
        .then(() => {
          toast.current.show({ 
            severity: "success", 
            summary: Language(language).Success, 
            detail: Language(language).RecordSaved, 
            life: 3000
          });

          setBook(emptyBook);

          setBookDialog(false);

          loadBooks();
        })
        .catch(e => {
          console.log('updateBook error', e);
        })
        .finally(() => setBookLink(false));
    }
  }

  const loadRecords = async () => {
    if (!course?._id) return;

    await getModules(course._id)
      .then((response) => {
        setRecords(response.data);

        setLoadModules(false);
      })
      .catch((e) => console.log('getModules error', e));
  }

  const loadCourse = async () => {
    if (!id || !user?._id) return

    await getCourseByUser(id, user._id)
      .then((response) => {
        setCourse(response.data);

        setLoad(false);
      })
      .catch((e) => console.log('getCourseByUser error', e));
  }

  const loadContents = async (module_id: string) => {
    await getContents(module_id)
      .then((response) => {
        setRecords((prev) => {
          return prev.map((item) => {
            if (item._id === module_id) return {
              ...item,
              contents: response.data,
              loading: false,
            }

            return item;
          })
        })
      })
      .catch((e) => console.log('getCourseByUser error', e));
  }

  const loadBooks = async () => {
    if (!course?._id) return;

    await getBooks(course._id)
      .then((response) => {
        setBooks(response.data);

        setLoadingBooks(false);
      })
      .catch((e) => console.log('getBooks error', e));
  }

  const loadCategorys = async () => {
    await getCategorys()
      .then((response) => {
        setCategorys(response.data);
      })
      .catch((e) => console.log('getCategorys error', e));
  }

  // const loadCertificates = async () => {
  //   await getCertificates()
  //     .then((response) => {
  //       setCertificates(response.data);

  //       setLoadingCertificates(false);
  //     })
  //     .catch((e) => {
  //       console.log('getCertificates error', e);
  //     });
  // }

  const removeModule = async () => {
    if (!moduleDelete) return;

    setLoadModules(true);

    setModuleDelete(undefined);

    await deleteModule(moduleDelete)
      .then((response) => {
        toast.current.show({ 
          severity: "success", 
          summary: Language(language).Success, 
          detail: Language(language).DeletedRecord, 
          life: 3000
        });

        loadRecords();
      })
      .catch((e) => console.log('deleteModule error', e));
  }

  const removeContent = async () => {
    if (!contentDelete) return;

    setLoading(true);

    await deleteContent(contentDelete)
      .then((response) => {
        toast.current.show({ 
          severity: "success", 
          summary: Language(language).Success, 
          detail: Language(language).DeletedRecord, 
          life: 3000
        });

        loadContents(response.data);

        setContentDelete(undefined);
      })
      .catch((e) => console.log('deleteContent error', e))
      .finally(() => setLoading(false));
  }

  const removeBook = async () => {
    if (!bookDelete) return;

    setLoadingBooks(true);

    await deleteBook(bookDelete)
      .then((response) => {
        toast.current.show({ 
          severity: "success", 
          summary: Language(language).Success, 
          detail: Language(language).DeletedRecord, 
          life: 3000
        });

        loadBooks();

        setBookDelete(undefined);
      })
      .catch((e) => console.log('deleteBook error', e));
  }

  const deleteFile = async (content_id: string, file: number) => {
    if (content._id !== content_id) return;

    await deleteContentFile(content_id, file)
      .then((response) => {
        const newContent = response.data;

        if (content._id === newContent._id) setContent(newContent);
      })
      .catch((e) => console.log('deleteContentFile error', e));
  }

  const hideRecordDialog = () => {
    setRecordDialog(false);

    setRecord(emptyRecord);
  }

  const hideContentDialog = () => {
    setContentDialog(false);

    setFiles(undefined);

    setContent(emptyContent);
  }

  const recordDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={hideRecordDialog} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => saveRecord()} >
        {loading ? Language(language).Processing : Language(language).Create}
      </Button>
    </>
  );

  const contentDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => setContentDialog(false)} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={saveContent} >
        {loading ? Language(language).Processing : Language(language).Save}
      </Button>
    </>
  );

  const bookDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loadingBooks} className="animation-on-hover" color="info" onClick={() => setBookDialog(false)} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={loadingBooks} className="animation-on-hover" color="info" onClick={saveBook} >
        {loadingBooks ? Language(language).Processing : Language(language).Save}
      </Button>
    </>
  );

  const deleteBookFooter = (
    <>
      <Button icon="pi pi-times" disabled={loadingBooks} className="animation-on-hover" color="info" onClick={() => {
        setBookDelete(undefined);
      }} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={loadingBooks} className="animation-on-hover" color="info" onClick={removeBook} >
        {loadingBooks ? Language(language).Processing : Language(language).YesDelete}
      </Button>
    </>
  );

  const deleteContentFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => {
        setContentDelete(undefined);
      }} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={removeContent} >
        {loading ? Language(language).Processing : Language(language).YesDelete}
      </Button>
    </>
  );

  const deleteModuleFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => {
        setModuleDelete(undefined);
      }} >
        {Language(language).Cancel}
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={removeModule} >
        {loading ? Language(language).Processing : Language(language).YesDelete}
      </Button>
    </>
  );

  const certificateFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => {
        setCertificate(undefined);
      }} >
        Ok
      </Button>
    </>
  );

  const renderModules = () => {
    if (records.length === 0) return(
      <label style={{
        fontWeight: 'bold',
        fontSize: 16,
      }}>{Language(language).ThisCourseNotHaveModules}</label>
    )

    return records.map((data) => (
      <Card style={{
        backgroundColor: '#1f1a2a',
        padding: 5,
      }}>
        <Row>
          <Col md='6'>
            <SanitizedInput
              value={data.name}
              onChange={(e: any) => {
                setRecords((prev) => {
                  return prev.map((item) => {
                    if (item._id === data._id) return {
                      ...item,
                      name: e.target.value,
                      edited: true,
                    }

                    return item;
                  })
                })
              }}
              onBlur={() => {if (data.edited) saveRecord(data)}}
              placeholder={Language(language).ModuleName}
              type="text"
              size={50}
            />
          </Col>
          <Col md='2'>
            <SanitizedInput
              value={data.number}
              onChange={(e: any) => {
                setRecords((prev) => {
                  return prev.map((item) => {
                    if (item._id === data._id) return {
                      ...item,
                      number: parseInt(e.target.value),
                      edited: true,
                    }

                    return item;
                  })
                })
              }}
              onBlur={() => {if (data.edited) saveRecord(data)}}
              placeholder={Language(language).Number}
              type="number"
              size={50}
            />
          </Col>
          <Col>
            <CardTitle tag="h3">
              {Language(language).Content}
              <Button 
                style={{padding: 7, marginLeft: 10}}
                color="info" 
                className="animation-on-hover" 
                onClick={() => {
                  setContentDialog(true);

                  setContent({
                    ...emptyContent,
                    module_id: data._id,
                  })
                }}
              >
                <i className="pi pi-plus" />
              </Button>
              <Button 
                style={{padding: 7, marginLeft: 30}}
                color="danger" 
                className="animation-on-hover" 
                onClick={() => setModuleDelete(data._id)}
              >
                <i className="pi pi-trash" />
              </Button>
            </CardTitle>
          </Col>
        </Row>
        <Row>
          <Col style={{marginTop: 5}}>
            <SanitizedInput
              value={data.description}
              onChange={(e: any) => {
                setRecords((prev) => {
                  return prev.map((item) => {
                    if (item._id === data._id) return {
                      ...item,
                      description: e.target.value,
                      edited: true,
                    }

                    return item;
                  })
                })
              }}
              onBlur={() => {if (data.edited) saveRecord(data)}}
              placeholder={Language(language).Description}
              type="textarea"
              size={300}
            />
          </Col>
        </Row>
        
        {!data.contents ? (
          <Row>
            <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Button 
                disabled={data.loading}
                onClick={() => {
                  loadContents(data._id);

                  setRecords((prev) => {
                    return prev.map((item) => {
                      if (item._id === data._id) return {
                        ...item,
                        loading: true,
                      }

                      return item;
                    })
                  })
                }}
              >
                {data.loading ? (
                  <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem' }} />
                ) : (
                  <i className="pi pi-chevron-down" />
                )}
              </Button>
            </Col>
          </Row>
        ) : renderContents(data.contents)}
      </Card>
    ))
  }

  const _renderCertificates = (data: any) => {
    return (
      <Button
        color={course?.certificate_id === data.id ? 'success' : 'info'}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        onClick={() => {
          setCertificate(data);

          setCourse((prev) => {
            return {
              ...prev,
              certificate_id: data.id,
            }
          });
        }}
      >
        <img  src={data.path} style={{width: '30vmin'}} alt="logo" />
      </Button>
    )
  }

  const renderContents = (contents: iContent[]) => {
    if (contents.length === 0) return(
      <label style={{
        fontWeight: 'bold',
        fontSize: 16,
      }}>{Language(language).NoContentInThisModule}</label>
    )

    return contents.map((item) => {
      return (
        <Row>
          <Col>
            <CardTitle tag="h4">
              {`${item.number} - ${item.name}`}
              <Button 
                style={{padding: 7, marginLeft: 10}}
                color="info" 
                className="animation-on-hover" 
                onClick={() => {
                  setContentDialog(true);

                  setContent(item);
                }}
              >
                <i className="pi pi-pencil" />
              </Button>
              <Button 
                style={{padding: 7, marginLeft: 15}}
                color="danger" 
                className="animation-on-hover" 
                onClick={() => setContentDelete(item._id)}
              >
                <i className="pi pi-trash" />
              </Button>
            </CardTitle>
          </Col>
        </Row>
      )
    })
  }

  const renderBooks = () => {
    return (
      <Row style={{marginLeft: 10}}>
        {books.map((data) => (
          <view style={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <Button 
              style={{
                margin: 5,
                width: '35vmin',
              }}
              color={"info"}
              onClick={
                () => {
                  setBook(data);

                  setBookDialog(true);
                }
              }
            >
              <view style={{display: 'flex', flexDirection: 'column'}}>
                {data.image ? (
                  <img  src={data.image} style={{height: '25vmin', aspectRatio: 2/3, flex: 1}} alt="logo" />
                ) : null}

                <label style={{
                  alignSelf: 'center',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  marginTop: 5,
                }}>{data.name}</label>
              </view>
            </Button>

            <view>
              <Button 
                style={{padding: 7, marginLeft: 10}}
                color="info" 
                className="animation-on-hover" 
                onClick={() => {
                  setBook(data);

                  setBookDialog(true);
                }}
              >
                <i className="pi pi-pencil" />
              </Button>
              <Button 
                style={{padding: 7, marginLeft: 15}}
                color="danger" 
                className="animation-on-hover" 
                onClick={() => setBookDelete(data._id)}
              >
                <i className="pi pi-trash" />
              </Button>
            </view>
          </view>
        ))}
      </Row>
    )
  }

  const renderCategoryItem = () => categorys.map((category) => (
    <DropdownItem onClick={() => setCourse((prev) => {
      return {
        ...prev,
        category_id: category._id,
      }
    })}>
      {category.name}
    </DropdownItem>
  ))

  useEffect(() => {
    loadCourse();

    loadCategorys();
  }, [user?._id]);

  useEffect(() => {
    if (!course?._id) return;

    loadRecords();

    loadBooks();

    // loadCertificates();
  }, [course?._id]);
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <Card>
          <CardBody>
            {load || !course ? (<LoadingSpin />) : (
              <>
                <Row>
                  <Col md='6' style={{marginLeft: 10}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <label style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}>{Language(language).CourseName}</label>
                      <SanitizedInput
                        style={fieldCourseError === 'name' ? {borderColor: 'red'} : {}}
                        value={course.name}
                        onChange={(e: any) => {
                          if (fieldCourseError === 'name') setFieldCourseError('');

                          setCourse((prev) => {
                            return {
                              ...prev,
                              name: e.target.value,
                            }
                          });
                        }}
                        placeholder={Language(language).CourseName}
                        type="text"
                        size={50}
                      />
                      <hr />
                      
                      <div style={{
                        display: 'flex'
                      }}>
                        <Checkbox
                          checked={course.show_name === true}
                          onChange={e => {
                            setCourse((prev) => {
                              return {
                                ...prev,
                                show_name: e.checked === true,
                              }
                            });
                          }}
                        />
                        <label style={{
                          fontWeight: 'bold',
                          fontSize: 16,
                          marginLeft: 10,
                        }}>{Language(language).ShowName}</label>
                      </div>
                      <hr />

                      <label style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}>{Language(language).Author}</label>
                      <SanitizedInput
                        style={fieldCourseError === 'author' ? {borderColor: 'red'} : {}}
                        value={course.author || `${user?.name} ${user?.lastname}`}
                        onChange={(e: any) => {
                          if (fieldCourseError === 'author') setFieldCourseError('');

                          setCourse((prev) => {
                            return {
                              ...prev,
                              author: e.target.value,
                            }
                          });
                        }}
                        placeholder={Language(language).Author}
                        type="text"
                        size={50}
                      />
                      <hr />

                      <label style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}>{Language(language).Description}</label>
                      <SanitizedInput
                        style={fieldCourseError === 'description' ? {borderColor: 'red'} : {}}
                        value={course.description}
                        onChange={(e: any) => {
                          if (fieldCourseError === 'description') setFieldCourseError('');

                          setCourse((prev) => {
                            return {
                              ...prev,
                              description: e.target.value,
                            }
                          });
                        }}
                        placeholder={Language(language).Description}
                        type="textarea"
                        size={300}
                      />
                      <hr />

                      {user?.is_root ? (
                        <>
                          <label style={{
                            fontWeight: 'bold',
                            fontSize: 16,
                          }}>Escola Avançada</label>
                          <SanitizedInput
                            style={{}}
                            value={course.school_id}
                            onChange={(e: any) => {
                              setCourse((prev) => {
                                return {
                                  ...prev,
                                  school_id: e.target.value,
                                }
                              });
                            }}
                            placeholder={'ID do curso'}
                            type="text"
                            size={50}
                          />
                          <hr />
                          {categorys.length ? (
                            <>
                              <UncontrolledDropdown>
                                <DropdownToggle>
                                  {course.category_id ? categorys.find((item) => item._id === course.category_id)?.name : 'Categoria'}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={() => setCourse((prev) => {
                                    return {
                                      ...prev,
                                      category_id: null,
                                    }
                                  })}/>
                                  {renderCategoryItem()}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              <hr />
                            </>
                          ) : null}
                        </>
                      ) : null}

                      <label style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}>{Language(language).DiscoverCourse}</label>
                      <span>{Language(language).InsertLinkDescribesCourse}</span>
                      <SanitizedInput
                        style={fieldCourseError === 'url' ? {borderColor: 'red'} : {}}
                        value={course.url}
                        onChange={(e: any) => {
                          if (fieldCourseError === 'url') setFieldCourseError('');

                          setCourse((prev) => {
                            return {
                              ...prev,
                              url: e.target.value,
                            }
                          });
                        }}
                        placeholder={Language(language).PageLink}
                        type="text"
                        size={50}
                      />
                      <hr />
                    </div>
                  </Col>
                  <Col>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <label style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}>{Language(language).Image}</label>

                      {course.image ? (
                        <img src={course.image} style={{
                          width: '35vmin', 
                          aspectRatio: 2/3, 
                          flex: 1,
                        }} alt="logo" />
                      ) : null}

                      <FileUpload
                        chooseLabel="Procurar"
                        auto
                        name="file"
                        customUpload={true}
                        uploadHandler={e => setImage(e.files[0])}
                        accept="image/*"
                        maxFileSize={10000000}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col style={{marginLeft: 10}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <label style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}>{Language(language).Support}</label>
                      <span>{Language(language).SetContactSupport}</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md='6' style={{marginLeft: 10}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <label style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}>{Language(language).SupportEmail}</label>
                      <SanitizedInput
                        style={fieldCourseError === 'email' ? {borderColor: 'red'} : {}}
                        value={course.email}
                        onChange={(e: any) => {
                          if (fieldCourseError === 'email') setFieldCourseError('');

                          setCourse((prev) => {
                            return {
                              ...prev,
                              email: e.target.value,
                            }
                          });
                        }}
                        placeholder={Language(language).SupportEmail}
                        type="text"
                        size={50}
                      />
                    </div>
                  </Col>

                  <Col>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <label style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}>{Language(language).SupportPhone}</label>
                      <SanitizedInput
                        style={fieldCourseError === 'phone' ? {borderColor: 'red'} : {}}
                        value={course.phone}
                        onChange={(e: any) => {
                          if (fieldCourseError === 'phone') setFieldCourseError('');

                          setCourse((prev) => {
                            return {
                              ...prev,
                              phone: e.target.value,
                            }
                          });
                        }}
                        placeholder={Language(language).SupportPhone}
                        type="text"
                        size={50}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{
                  marginTop: 10,
                }}>
                  <Col md='6' style={{marginLeft: 10}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <label style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                        marginRight: 10,
                      }}>{Language(language).ReleaseCertificate}</label>

                      <Checkbox
                        checked={course.release_certificate === true}
                        onChange={e => {
                          setCourse((prev) => {
                            return {
                              ...prev,
                              release_certificate: e.checked,
                            }
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                {course.release_certificate ? (
                  loadingCertificates ? (<LoadingSpin spin />) : (
                    <>
                      <Row>
                        <Col style={{marginLeft: 10}}>
                          {!course.certificate_id ? (
                            <label style={{
                              fontSize: 16,
                              marginRight: 10,
                            }}>{Language(language).NeedChooseCertificateTemplate}</label>
                          ) : null}

                          <Carousel
                            style={{padding: 5}}
                            value={certificates}
                            numVisible={4}
                            numScroll={1}
                            responsiveOptions={carouselResponsiveOptions}
                            itemTemplate={_renderCertificates}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4' style={{marginLeft: 10}}>
                          <label style={{
                            fontWeight: 'bold',
                            fontSize: 16,
                          }}>{Language(language).Title}</label>
                          <SanitizedInput
                            style={fieldCourseError === 'expert_title' ? {borderColor: 'red'} : {}}
                            value={course.expert_title}
                            onChange={(e: any) => {
                              if (fieldCourseError === 'expert_title') setFieldCourseError('');

                              setCourse((prev) => {
                                return {
                                  ...prev,
                                  expert_title: e.target.value,
                                }
                              });
                            }}
                            placeholder={Language(language).Title}
                            type="text"
                            size={50}
                          />
                        </Col>

                        <Col md='4' style={{marginLeft: 10}}>
                          <label style={{
                            fontWeight: 'bold',
                            fontSize: 16,
                          }}>{Language(language).Workload}</label>
                          <SanitizedInput
                            style={fieldCourseError === 'workload' ? {borderColor: 'red'} : {}}
                            value={course.workload}
                            onChange={(e: any) => {
                              if (fieldCourseError === 'workload') setFieldCourseError('');
                              
                              const value = parseInt(`${e.target.value}`);
    
                              setCourse((prev) => {
                                return {
                                  ...prev,
                                  workload: value,
                                }
                              });
                            }}
                            placeholder={Language(language).Workload}
                            type="number"
                            size={50}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{marginLeft: 10}}>
                          <label style={{
                            fontSize: 16,
                          }}>{Language(language).TitleCertificateDesc}</label>
                        </Col>
                      </Row>
                    </>
                  )
                ) : null}

                <Row style={{
                  marginLeft: 10,
                  marginTop: 10,
                }}>
                  {user?.is_root ? (
                    <Col style={{display: 'flex', flexDirection: 'row'}}>
                      <Checkbox
                        checked={course.approved === true}
                        onChange={e => {
                          setCourse((prev) => {
                            return {
                              ...prev,
                              approved: e.checked,
                            }
                          });
                        }}
                      />
                      <label style={{fontWeight: 'bold', fontSize: 14}}>
                        {`. ${Language(language).Publish}`}
                      </label>
                    </Col>
                  ) : null}
                  
                  {user?.is_root ? (
                    <Col md="4">
                      <label>Level</label>
                      <SanitizedInput
                        style={fieldCourseError === 'level' ? {borderColor: 'red'} : {}}
                        value={course.level}
                        onChange={(e: any) => {
                          if (fieldCourseError === 'level') setFieldCourseError('');
                          
                          const value = parseInt(`${e.target.value}`);

                          setCourse((prev) => {
                            return {
                              ...prev,
                              level: value,
                            }
                          });
                        }}
                        placeholder={'Level'}
                        type="number"
                        size={50}
                      />
                    </Col>
                  ) : null}
                </Row>

                <Row style={{
                  marginLeft: 10,
                  marginTop: 10,
                }}>
                  <Button disabled={loading} className="animation-on-hover" color="info" onClick={saveCourse} >
                    {loading ? Language(language).Processing : Language(language).Save}
                  </Button>
                </Row>
              </>
            )}
            <hr />

            <Row style={{marginLeft: 10}}>
              <CardTitle tag="h3">
                {Language(language).Modules}
                <Button 
                  style={{padding: 7, marginLeft: 10}}
                  color="info" 
                  className="animation-on-hover" 
                  onClick={() => setRecordDialog(true)}
                >
                  <i className="pi pi-plus" />
                </Button>
              </CardTitle>
            </Row>
            {loadModules ? (<LoadingSpin />) : renderModules()}

            <Row style={{marginLeft: 10}}>
              <CardTitle tag="h3">
                {Language(language).Library}
                <Button 
                  style={{padding: 7, marginLeft: 10}}
                  color="info" 
                  className="animation-on-hover" 
                  onClick={() => setBookDialog(true)}
                >
                  <i className="pi pi-plus" />
                </Button>
              </CardTitle>
            </Row>
            {loadingBooks ? (<LoadingSpin />) : renderBooks()}
          </CardBody>
        </Card>
      </div>

      {/* Modal criar/editar módulo */}
      <Dialog
        visible={recordDialog}
        style={{ 
          width: "600px",
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={record?._id ? Language(language).EditModule : Language(language).CreateModule}
        modal
        className="p-fluid"
        footer={recordDialogFooter}
        onHide={hideRecordDialog}
      >
        <Card style={{padding: 10}}>
          <Row style={{
            marginBottom: 10,
          }}>
            <Col md="6">
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).ModuleName} *</label>
              <SanitizedInput
                style={fieldError === 'name' ? {borderColor: 'red'} : {}}
                value={record.name}
                onChange={(e: any) => {
                  if (fieldError === 'name') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).ModuleName}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row style={{
            marginBottom: 10,
          }}>
            <Col>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Description} ({Language(language).optional})</label>
              <SanitizedInput
                style={fieldError === 'description' ? {borderColor: 'red'} : {}}
                value={record.description}
                onChange={(e: any) => {
                  if (fieldError === 'description') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).Description}
                type="textarea"
                size={300}
              />
            </Col>
          </Row>

          <Row>
            <Col style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Number} ({Language(language).optional})</label>
              <span>{Language(language).RepresentsOrderingModule}</span>
            </Col>
          </Row>
          <Row>
            <Col md='3'>
              <SanitizedInput
                style={fieldError === 'number' ? {borderColor: 'red'} : {}}
                value={record.number}
                onChange={(e: any) => {
                  if (fieldError === 'number') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      number: parseInt(e.target.value),
                    }
                  });
                }}
                placeholder={Language(language).Number}
                type="number"
                size={50}
              />
            </Col>
          </Row>
        </Card>
      </Dialog>

      {/* Modal criar/editar conteudo */}
      <Dialog
        visible={contentDialog}
        style={{ 
          width: "600px",
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={content?._id ? Language(language).EditContent : Language(language).CreateContent}
        modal
        className="p-fluid"
        footer={contentDialogFooter}
        onHide={hideContentDialog}
      >
        <Card style={{padding: 10}}>
          <Row style={{
            marginBottom: 10,
          }}>
            <Col md="6">
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).ContentName} *</label>
              <SanitizedInput
                style={contentError === 'name' ? {borderColor: 'red'} : {}}
                value={content.name}
                onChange={(e: any) => {
                  if (contentError === 'name') setContentError('');

                  setContent((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).ContentName}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row style={{
            marginBottom: 10,
          }}>
            <Col>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Description} ({Language(language).optional})</label>
              <SanitizedInput
                style={contentError === 'description' ? {borderColor: 'red'} : {}}
                value={content.description}
                onChange={(e: any) => {
                  if (contentError === 'description') setContentError('');

                  setContent((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).Description}
                type="textarea"
                size={300}
              />
            </Col>
          </Row>

          <Row>
            <Col style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Number} ({Language(language).optional})</label>
              <span>{Language(language).RepresentsOrderingContent}</span>
            </Col>
          </Row>
          <Row style={{
            marginBottom: 10,
          }}>
            <Col md='3'>
              <SanitizedInput
                style={contentError === 'number' ? {borderColor: 'red'} : {}}
                value={content.number}
                onChange={(e: any) => {
                  if (contentError === 'number') setContentError('');

                  setContent((prev) => {
                    return {
                      ...prev,
                      number: parseInt(e.target.value),
                    }
                  });
                }}
                placeholder={Language(language).Number}
                type="number"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).PreviousClassRequired} ({Language(language).optional})</label>

              <Checkbox
                checked={content.require_last === true}
                style={{
                  marginLeft: 10,
                }}
                onChange={e => {
                  setContent((prev) => {
                    return {
                      ...prev,
                      require_last: e.checked,
                    }
                  });
                }}
              />
            </Col>
          </Row>
          <Row style={{
            marginBottom: 10,
          }}>
            <Col>
              {content.require_last === true ? (
                <>
                  <Row>
                    <Col style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <label style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}>{Language(language).Days} ({Language(language).optional})</label>
                      <span>{Language(language).YouCanAlsoDaysSince}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='3'>
                      <SanitizedInput
                        style={contentError === 'require_days' ? {borderColor: 'red'} : {}}
                        value={content.require_days}
                        onChange={(e: any) => {
                          if (contentError === 'require_days') setContentError('');

                          setContent((prev) => {
                            return {
                              ...prev,
                              require_days: parseInt(e.target.value),
                            }
                          });
                        }}
                        placeholder={Language(language).Number}
                        type="number"
                        size={50}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <span>{Language(language).CheckIfMandatoryPreviousClass}</span>
              )}
            </Col>
          </Row>

          <Row style={{
            marginBottom: 10,
          }}>
            <Col  style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).YouTubeID} ({Language(language).optional})</label>
              <span>{Language(language).IfVideoLinkIs}</span>
              <SanitizedInput
                style={contentError === 'video' ? {borderColor: 'red'} : {}}
                value={content.video}
                onChange={(e: any) => {
                  if (contentError === 'video') setContentError('');

                  setContent((prev) => {
                    return {
                      ...prev,
                      video: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).YouTubeID}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row style={{
            marginBottom: 10,
          }}>
            <Col  style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Text} ({Language(language).optional})</label>
              <SanitizedInput
                style={contentError === 'text' ? {borderColor: 'red'} : {}}
                value={content.text}
                onChange={(e: any) => {
                  if (contentError === 'text') setContentError('');

                  setContent((prev) => {
                    return {
                      ...prev,
                      text: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).YouTubeID}
                type="textarea"
                size={1000}
              />
            </Col>
          </Row>

          <Row style={{
            marginBottom: 10,
          }}>
            <Col  style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>Links ({Language(language).optional})</label>
              <span>{Language(language).ExternalLinks}</span>

              {content.links.map((link, index) => (
                <SanitizedInput
                  value={link}
                  onChange={(e: any) => {
                    setContent((prev) => {
                      return {
                        ...prev,
                        links: content.links.map((item, i) => {
                          if (i === index) return e.target.value;

                          return item;
                        })
                      }
                    });
                  }}
                  placeholder="Link"
                  type="text"
                  size={100}
                />
              ))}

              {content.links.length < 10 ? (
                <CardTitle tag="h4">
                  {Language(language).AddLink}
                  <Button 
                    style={{padding: 7, marginLeft: 10}}
                    color="info" 
                    className="animation-on-hover" 
                    onClick={() => {
                      setContent((prev) => {
                        return {
                          ...prev,
                          links: [...content.links, ''],
                        }
                      })
                    }}
                  >
                    <i className="pi pi-plus" />
                  </Button>
                </CardTitle>
              ) : null}
            </Col>
          </Row>

          <Row style={{
            marginBottom: 10,
          }}>
            <Col  style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Files} ({Language(language).optional})</label>
              <span>{Language(language).AddUpToFiles(10, 100)}</span>

              {content.files.length ? content.files.map((item, index) => {
                return (
                  <CardTitle tag="h4">
                    <a href={item}>{item?.split('/')[10]?.replaceAll('%20', ' ')}</a>
                    {!content.deletingFile ? (
                      <Button 
                        disabled={content.deletingFile}
                        style={{padding: 7, marginLeft: 10}}
                        color="danger" 
                        className="animation-on-hover" 
                        onClick={() => {
                          deleteFile(content._id, index);

                          setContent({
                            ...content,
                            deletingFile: true,
                          })
                        }}
                      >
                        <i className="pi pi-trash" />
                      </Button>
                    ) : (
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem' }} />
                    )}
                  </CardTitle>
                )
              }) : null}

              {content.files.length < 10 ? (
                <FileUpload
                  chooseLabel="Procurar"
                  multiple
                  auto
                  name="file"
                  customUpload={true}
                  uploadHandler={e => setFiles(e.files)}
                  accept=".jpg, .gif, .png, .bmp, .pdf, .zip, .rar, .epub, .xls, .xlsx, .mp3, .doc, .docx, .ppt, .pptx"
                  maxFileSize={100000000}
                />
              ) : null}
            </Col>
          </Row>
        </Card>
      </Dialog>

      {/* Modal pra excluir Conteúdo */}
      <Dialog
        visible={!(typeof contentDelete === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={Language(language).Delete}
        modal
        className="p-fluid"
        footer={deleteContentFooter}
        onHide={() => {
          setContentDelete(undefined);
        }}
      >
        {Language(language).DeleteContentProcessIrreversible}
      </Dialog>

      {/* Modal pra excluir Módulo */}
      <Dialog
        visible={!(typeof moduleDelete === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={Language(language).Delete}
        modal
        className="p-fluid"
        footer={deleteModuleFooter}
        onHide={() => {
          setModuleDelete(undefined);
        }}
      >
        {Language(language).DeleteModuleProcessIrreversible}
      </Dialog>

      {/* Modal pré-visualização do certificado */}
      <Dialog
        visible={!(typeof certificate === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
          width: '100%',
        }}
        header={Language(language).Certificate}
        modal
        className="p-fluid"
        footer={certificateFooter}
        onHide={() => {
          setCertificate(undefined);
        }}
      >
        {certificate ? (
          <CertificatePreview 
            data={certificate} 
            course={course?.name}
            hour={course?.workload}
            title={course?.expert_title}
            expert={`${user?.name} ${user?.lastname}`}
          />
        ) : null}
      </Dialog>

      {/* Modal pra criar/editar Livro */}
      <Dialog
        visible={bookDialog}
        style={{ 
          width: "600px",
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={book?._id ? Language(language).EditBook : Language(language).CreateBook}
        modal
        className="p-fluid"
        footer={bookDialogFooter}
        onHide={() => {
          setBookDialog(false);
        }}
      >
        <Card style={{padding: 10}}>
          <Row style={{
            marginBottom: 10,
          }}>
            <Col md="6">
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).BookName} *</label>
              <SanitizedInput
                style={bookError === 'name' ? {borderColor: 'red'} : {}}
                value={book.name}
                onChange={(e: any) => {
                  if (bookError === 'name') setBookError('');

                  setBook((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).BookName}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row style={{
            marginBottom: 10,
          }}>
            <Col>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Description} ({Language(language).optional})</label>
              <SanitizedInput
                value={book.description}
                onChange={(e: any) => {
                  if (bookError === 'description') setBookError('');

                  setBook((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    }
                  });
                }}
                placeholder={Language(language).Description}
                type="textarea"
                size={300}
              />
            </Col>
          </Row>

          <Row style={{
            marginBottom: 10,
          }}>
            <Col style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>{Language(language).Image} ({Language(language).optional})</label>

              {book.image ? (
                <img src={book.image} style={{
                  width: '35vmin', 
                  aspectRatio: 2/3, 
                  flex: 1,
                }} alt="logo" />
              ) : null}

              <FileUpload
                chooseLabel="Procurar"
                auto
                name="file"
                customUpload={true}
                uploadHandler={e => setBookImage(e.files[0])}
                accept="image/*"
                maxFileSize={10000000}
              />

            </Col>
          </Row>

          <Row style={{
            marginBottom: 10,
          }}>
            <Col>
              <label style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}>Link *</label>

              <view style={{
                display: 'flex',
                flexDirection: 'row',
              }}>
                <Checkbox
                  checked={bookLink}
                  onChange={e => {
                    setBookLink(e.checked === true);
                  }}
                />
                <label style={{fontWeight: 'bold', fontSize: 14}}>
                  {`. ${Language(language).SendFile}`}
                </label>
              </view>

              {!bookLink ? (
                <SanitizedInput
                  value={book.link}
                  style={bookError === 'link' ? {borderColor: 'red'} : {}}
                  onChange={(e: any) => {
                    if (bookError === 'link') setBookError('');

                    setBook((prev) => {
                      return {
                        ...prev,
                        link: e.target.value,
                      }
                    });
                  }}
                  placeholder="Link"
                  type="text"
                  size={50}
                />
              ) : (
                <FileUpload
                  chooseLabel="Procurar"
                  auto
                  name="file"
                  customUpload={true}
                  uploadHandler={e => setBookFile(e.files[0])}
                  accept=".pdf, .epub, .xls, .xlsx, .mp3, .doc, .docx, .ppt, .pptx"
                  maxFileSize={100000000}
                />
              )}

            </Col>
          </Row>
        </Card>
      </Dialog>

      {/* Modal pra excluir Livro */}
      <Dialog
        visible={!(typeof bookDelete === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={Language(language).Delete}
        modal
        className="p-fluid"
        footer={deleteBookFooter}
        onHide={() => {
          setBookDelete(undefined);
        }}
      >
        {Language(language).DeleteContentProcessIrreversible}
      </Dialog>
    </>
  );
}

export default ModulesView;
