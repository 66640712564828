import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import { upload } from "../../api/upload";
import LoadingSpin from "../../components/loading/loading";
import { createGraduation, deleteGraduation, getGraduations, updateGraduation } from "../../api/graduations";
import SanitizedInput from "../../components/sanitizedInput";

function GraduationsView(props: any) {
  const [recordDialog, setRecordDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<iGraduation[]>([]);
  const [fieldError, setFieldError] = useState('');
  const [uploading, setUploading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<string | undefined>();

  const toast = useRef<any>(null);

  const emptyRecord: iGraduation = {
    name: '',
    indications: 0,
    invoicing: 0,
    score: 0,
    childs: [],
    vme: 0,
    level: 0,
  }
  const [record, setRecord] = useState<iGraduation>(emptyRecord);

  const hideDialog = () => {
    setRecord(emptyRecord);
    setRecordDialog(false);
  };

  const saveRecord = async () => {
    if (uploading) return;

    if (record.name.length < 3) {
      setFieldError('name');
      alert('Informe um nome válido');
      return;
    };

    delete record.__v;

    if (!record._id) {
      // Criando novo registro
      await createGraduation(record)
        .then(() => {
          toast.current.show({ 
            severity: "success", 
            summary: "Sucesso", 
            detail: "Registro salvo com sucesso.", 
            life: 3000
          });

          hideDialog();

          loadRecords();

          setRecord(emptyRecord);
        })
        .catch(e => {
          console.log('createGraduation error', e);
          if (e.response?.data) alert(e.response.data);
        })
        .finally(() => setLoading(false));
    } else {
      // Editando registro
      await updateGraduation(record._id, record)
        .then(() => {
          toast.current.show({ 
            severity: "success", 
            summary: "Sucesso", 
            detail: "Registro salvo com sucesso.", 
            life: 3000
          });

          hideDialog();

          loadRecords();
        })
        .catch(e => {
          console.log('updateGraduation error', e);
          if (e.response?.data) alert(e.response.data);
        })
        .finally(() => setLoading(false));
    }
  }

  const loadRecords = async () => {
    await getGraduations()
      .then((response) => {
        setRecords(response.data);
      })
  }

  const deleteRecord = async (id?: string) => {
    if (!id) return;

    setDeleting(true);

    await deleteGraduation(id)
      .then(() => {
        toast.current.show({ 
          severity: "success", 
          summary: "Sucesso", 
          detail: "Registro deletado.", 
          life: 3000
        });

        loadRecords();
      })
      .catch(e => {
        console.log('deleteGraduation error', e);
        if (e.response?.data) alert(e.response.data);
      })
      .finally(() => {
        setDeleting(false);

        setDeleteID(undefined);
      });
  }

  const recordDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={hideDialog} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={saveRecord} >
        {loading ? "Salvando..." : "Salvar"}
      </Button>
    </>
  );

  const deleteDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => {
        hideDialog();

        setDeleteID(undefined);
      }} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => deleteRecord(deleteID)} >
        {deleting ? "Excluindo..." : "Sim, excluir!"}
      </Button>
    </>
  );

  const _renderEmpty = () => {
    return (<span>Sem registros.</span>)
  }

  const _rendergraduationss = () => {
    return records.map((item: iGraduation) => {
      return (
        <tr>
          <th>{item.name}</th>
          <th>{item.indications}</th>
          <th>{item.score}</th>
          <th>{item.vme}</th>
          <th>{item.level}</th>
          <th>
            <Button 
              style={{padding: 7}}
              color="success" 
              className="animation-on-hover" 
              onClick={() => {
                setRecord(item);
                setRecordDialog(true);
              }}
            >
              <i className="pi pi-pencil" />
            </Button>
            {/* {" "}
            <Button 
              style={{padding: 7}}
              color="danger" 
              className="animation-on-hover" 
              onClick={() => setDeleteID(item._id)}
            >
              <i className="pi pi-trash" />
            </Button> */}
          </th>
        </tr>
      );
    })
  }

  useEffect(() => {
    loadRecords();
  }, [])

  return (
    <>
      <div className="content">
        <Toast ref={toast} />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Graduações{"  "}
                  <Button 
                    style={{padding: 7}}
                    color="info" 
                    className="animation-on-hover" 
                    onClick={() => setRecordDialog(true)}
                  >
                    <i className="pi pi-plus" />
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {records.length === 0 ? (
                  _renderEmpty()
                ) : (
                  <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Nome</th>
                        <th>Indicações</th>
                        <th>Pontuação</th>
                        <th>VME</th>
                        <th>Level</th>
                        <th>Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_rendergraduationss()}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Modal adicionar/editar */}
      <Dialog
        visible={recordDialog}
        style={{ 
          width: "600px",
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={record?._id ? "Editar Plano" : "Novo Plano"}
        modal
        className="p-fluid"
        footer={recordDialogFooter}
        onHide={hideDialog}
      >
        <Card style={{padding: 10}}>
          <Row>
            <Col md="6">
              <label>Nome *</label>
              <SanitizedInput
                style={fieldError === 'name' ? {borderColor: 'red'} : {}}
                value={record.name}
                onChange={(e: any) => {
                  if (fieldError === 'name') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    }
                  });
                }}
                placeholder={'Nome'}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row style={{paddingTop: 10}}>
            <Col>
              <label style={{fontWeight: 'bold'}}>Requisitos</label>
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <label>Indicações *</label>
              <SanitizedInput
                style={fieldError === 'indications' ? {borderColor: 'red'} : {}}
                value={record.indications}
                onChange={(e: any) => {
                  if (fieldError === 'indications') setFieldError('');
                  
                  const value = parseFloat(`${e.target.value}`).toFixed(2);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      indications: parseFloat(value),
                    }
                  });
                }}
                placeholder={'Indicações'}
                type="number"
                size={50}
              />
            </Col>
            {/* <Col md="4">
              <label>Faturamento *</label>
              <SanitizedInput
                style={fieldError === 'invoicing' ? {borderColor: 'red'} : {}}
                value={record.invoicing}
                onChange={(e: any) => {
                  if (fieldError === 'invoicing') setFieldError('');
                  
                  const value = parseFloat(`${e.target.value}`).toFixed(2);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      invoicing: parseFloat(value),
                    }
                  });
                }}
                placeholder={'Faturamento'}
                type="number"
                size={50}
              />
            </Col> */}
            <Col md="4">
              <label>Pontuação *</label>
              <SanitizedInput
                style={fieldError === 'score' ? {borderColor: 'red'} : {}}
                value={record.score}
                onChange={(e: any) => {
                  if (fieldError === 'score') setFieldError('');
                  
                  const value = parseFloat(`${e.target.value}`).toFixed(2);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      score: parseFloat(value),
                    }
                  });
                }}
                placeholder={'Pontuação'}
                type="number"
                size={50}
              />
            </Col>
            <Col md="4">
              <label>VME *</label>
              <SanitizedInput
                style={fieldError === 'vme' ? {borderColor: 'red'} : {}}
                value={record.vme}
                onChange={(e: any) => {
                  if (fieldError === 'vme') setFieldError('');
                  
                  const value = parseFloat(`${e.target.value}`).toFixed(2);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      vme: parseFloat(value),
                    }
                  });
                }}
                placeholder={'VME'}
                type="number"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <label>Level *</label>
              <SanitizedInput
                style={fieldError === 'level' ? {borderColor: 'red'} : {}}
                value={record.level}
                onChange={(e: any) => {
                  if (fieldError === 'level') setFieldError('');
                  
                  const value = parseInt(`${e.target.value}`);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      level: value,
                    }
                  });
                }}
                placeholder={'Level'}
                type="number"
                size={50}
              />
            </Col>
          </Row>

          <Row style={{
            display: 'flex',
            alignItems: 'center',
            margin: 10,
          }}>
            Level de Afiliados{"  "}

            <Button 
              style={{padding: 7, marginLeft: 10}}
              color="info" 
              className="animation-on-hover" 
              onClick={() => setRecord((prev) => {
                return {
                  ...prev,
                  childs: [...record.childs, {quant: 0, level: 0}],
                }
              })}
            >
              <i className="pi pi-plus" />
            </Button>
          </Row>

          <Row>
            {record.childs.map((child, index) => (
              <Col md="6" style={{
                display: 'flex',
              }} key={`size ${record._id} ${index}`}>
                <SanitizedInput
                  value={child.quant}
                  onChange={(e: any) => {
                    setRecord((prev) => {
                      return {
                        ...prev,
                        childs: prev.childs.map((c, i) => i !== index ? c : {
                          quant: e.target.value,
                          level: c.level,
                        }),
                      }
                    });
                  }}
                  placeholder={'Quantidade'}
                  type="text"
                  size={50}
                />

                <SanitizedInput
                  value={child.level}
                  onChange={(e: any) => {
                    setRecord((prev) => {
                      return {
                        ...prev,
                        childs: prev.childs.map((c, i) => i !== index ? c : {
                          level: e.target.value,
                          quant: c.quant,
                        }),
                      }
                    });
                  }}
                  placeholder={'Level'}
                  type="text"
                  size={50}
                />

                <Button 
                  style={{padding: 7}}
                  color="danger" 
                  className="animation-on-hover" 
                  onClick={() => {
                    setRecord((prev) => {
                      return {
                        ...prev,
                        childs: prev.childs.filter((s, i) => i !== index),
                      }
                    });
                  }}
                >
                  <i className="pi pi-trash" />
                </Button>
              </Col>
            ))}
          </Row>

          <Row>
            <Col>
              <label>Pin</label>
            </Col>
          </Row>

          {record.pin ? (
            <Row>
              <Col>
                <img src={record.pin} className="App-logo" alt="logo" />
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col>
              <FileUpload
                chooseLabel="Procurar"
                auto
                name="file"
                customUpload={true}
                uploadHandler={e => {
                  setUploading(true);
                  upload(e.files[0], 'graduations/images')
                    .then((response) => {
                      setRecord((prev) => {
                        return {
                          ...prev,
                          pin: response,
                        }
                      });
                    })
                    .finally(() => setUploading(false))
                }}
                accept="image/*"
                maxFileSize={10000000}
              />

              {uploading ? (
                <LoadingSpin />
              ) : null}

            </Col>
          </Row>
        </Card>
      </Dialog>

      {/* Modal pra excluir */}
      <Dialog
        visible={!(typeof deleteID === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={"Excluir?"}
        modal
        className="p-fluid"
        footer={deleteDialogFooter}
        onHide={() => {
          hideDialog();
          setDeleteID(undefined);
        }}
      >
        Excluir registro?
      </Dialog>
    </>
  );
}

export default GraduationsView;
