import DOMPurify from 'dompurify';
import { Input } from 'reactstrap';

// @ts-ignore
const SanitizedInput = (props: iProps) => {
  const sanitizedValue = DOMPurify.sanitize(props.value); // Sanitiza o valor para remover código malicioso

  return (
    <Input
      value={sanitizedValue}
      className={props.className}
      onChange={props.onChange}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      type={props.type}
      size={props.size}
      style={props.style}
      pattern={props.pattern}
      disabled={props.disabled}
    />
  );
};

export default SanitizedInput;

interface iProps {
  disabled?: any,
  value?: any,
  onChange?: any,
  onBlur?: any,
  placeholder?: any,
  type?: any,
  size?: any,
  style?: any,
  className?: any,
  pattern?: any,
}
