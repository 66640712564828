// Certificate models
import cert1 from '../assets/certificates/1.png';
import cert2 from '../assets/certificates/2.png';
import cert3 from '../assets/certificates/3.png';
import cert4 from '../assets/certificates/4.png';
import cert5 from '../assets/certificates/5.png';
import cert6 from '../assets/certificates/6.png';
import cert7 from '../assets/certificates/7.png';
import cert8 from '../assets/certificates/8.png';
import cert9 from '../assets/certificates/9.png';
import cert10 from '../assets/certificates/10.png';
import cert11 from '../assets/certificates/11.png';
import cert12 from '../assets/certificates/12.png';

export function clearMask(target: any) {
  if (typeof target !== "string") target = `${target}`;

  return target.replace(/\D/g, "");
}

export function testCPF(strCPF: string) {
  let Soma = 0;
  let Resto;

  strCPF = clearMask(strCPF);
  strCPF = strCPF.substr(0, 11);

  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export const certificateModels = () => {
  return [
    {id: '1', path: cert1},
    {id: '2', path: cert2},
    {id: '3', path: cert3},
    {id: '4', path: cert4},
    {id: '5', path: cert5},
    {id: '6', path: cert6},
    {id: '7', path: cert7},
    {id: '8', path: cert8},
    {id: '9', path: cert9},
    {id: '10', path: cert10},
    {id: '11', path: cert11},
    {id: '12', path: cert12},
  ]
}
