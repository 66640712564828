import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import { upload } from "../../api/upload";
import LoadingSpin from "../../components/loading/loading";
import { createPlan, deletePlan, getPlans, updatePlan } from "../../api/plans";
import SanitizedInput from "../../components/sanitizedInput";
import { createKit, deleteKit, getKits, updateKit } from "../../api/kits";

function KitsView(props: any) {
  const [recordDialog, setRecordDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<iKit[]>([]);
  const [fieldError, setFieldError] = useState('');
  const [uploading, setUploading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<string | undefined>();

  const toast = useRef<any>(null);

  const emptyRecord: iKit = {
    name: '',
    price: 0,
    percent: 0,
    free_shipping: 0,
  }
  const [record, setRecord] = useState<iKit>(emptyRecord);

  const hideDialog = () => {
    setRecord(emptyRecord);
    setRecordDialog(false);
  };

  const saveRecord = async () => {
    if (uploading) return;

    if (record.name.length < 3) {
      setFieldError('name');
      alert('Informe um nome válido');
      return;
    };

    if (record.price <= 0) {
      setFieldError('price');
      alert('Informe um preço válida');
      return;
    };

    if (record.percent <= 0) {
      setFieldError('percent');
      alert('Informe uma porcentagem válida');
      return;
    };

    delete record.__v;

    if (!record._id) {
      // Criando novo registro
      await createKit(record)
        .then(() => {
          toast.current.show({ 
            severity: "success", 
            summary: "Sucesso", 
            detail: "Registro salvo com sucesso.", 
            life: 3000
          });

          hideDialog();

          loadRecords();

          setRecord(emptyRecord);
        })
        .catch(e => {
          console.log('createKit error', e);
          if (e.response?.data) alert(e.response.data);
        })
        .finally(() => setLoading(false));
    } else {
      // Editando registro
      await updateKit(record._id, record)
        .then(() => {
          toast.current.show({ 
            severity: "success", 
            summary: "Sucesso", 
            detail: "Registro salvo com sucesso.", 
            life: 3000
          });

          hideDialog();

          loadRecords();
        })
        .catch(e => {
          console.log('updateKit error', e);
          if (e.response?.data) alert(e.response.data);
        })
        .finally(() => setLoading(false));
    }
  }

  const loadRecords = async () => {
    await getKits()
      .then((response) => {
        setRecords(response.data);
      })
  }

  const deleteRecord = async (id?: string) => {
    if (!id) return;

    setDeleting(true);

    await deleteKit(id)
      .then(() => {
        toast.current.show({ 
          severity: "success", 
          summary: "Sucesso", 
          detail: "Registro deletado.", 
          life: 3000
        });

        loadRecords();
      })
      .catch(e => {
        console.log('deleteKit error', e);
        if (e.response?.data) alert(e.response.data);
      })
      .finally(() => {
        setDeleting(false);

        setDeleteID(undefined);
      });
  }

  const recordDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={hideDialog} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={saveRecord} >
        {loading ? "Salvando..." : "Salvar"}
      </Button>
    </>
  );

  const deleteDialogFooter = (
    <>
      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => {
        hideDialog();

        setDeleteID(undefined);
      }} >
        Cancelar
      </Button>

      <Button icon="pi pi-times" disabled={loading} className="animation-on-hover" color="info" onClick={() => deleteRecord(deleteID)} >
        {deleting ? "Excluindo..." : "Sim, excluir!"}
      </Button>
    </>
  );

  const _renderEmpty = () => {
    return (<span>Sem registros.</span>)
  }

  const _renderKits = () => {
    return records.map((item: iKit) => {
      return (
        <tr>
          <th>{item.name}</th>
          <th>{item.price}</th>
          <th>{item.percent}</th>
          <th>{item.school_idcombo}</th>
          <th>
            <Button 
              style={{padding: 7}}
              color="success" 
              className="animation-on-hover" 
              onClick={() => {
                setRecord(item);
                setRecordDialog(true);
              }}
            >
              <i className="pi pi-pencil" />
            </Button>
            {" "}
            <Button 
              style={{padding: 7}}
              color="danger" 
              className="animation-on-hover" 
              onClick={() => setDeleteID(item._id)}
            >
              <i className="pi pi-trash" />
            </Button>
          </th>
        </tr>
      );
    })
  }

  useEffect(() => {
    loadRecords();
  }, [])

  return (
    <>
      <div className="content">
        <Toast ref={toast} />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Kits{"  "}
                  <Button 
                    style={{padding: 7}}
                    color="info" 
                    className="animation-on-hover" 
                    onClick={() => setRecordDialog(true)}
                  >
                    <i className="pi pi-plus" />
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {records.length === 0 ? (
                  _renderEmpty()
                ) : (
                  <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Nome</th>
                        <th>Preço</th>
                        <th>Desconto</th>
                        <th>School</th>
                        <th>Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_renderKits()}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Modal adicionar/editar */}
      <Dialog
        visible={recordDialog}
        style={{ 
          width: "600px",
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={record?._id ? "Editar Kit" : "Novo Kit"}
        modal
        className="p-fluid"
        footer={recordDialogFooter}
        onHide={hideDialog}
      >
        <Card style={{padding: 10}}>
          {record._id ? (
            <label>{record._id}</label>
          ) : null}
          <Row>
            <Col md="6">
              <label>Nome *</label>
              <SanitizedInput
                style={fieldError === 'name' ? {borderColor: 'red'} : {}}
                value={record.name}
                onChange={(e: any) => {
                  if (fieldError === 'name') setFieldError('');

                  setRecord((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    }
                  });
                }}
                placeholder={'Nome'}
                type="text"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <label>Preço  *</label>
              <SanitizedInput
                style={fieldError === 'price' ? {borderColor: 'red'} : {}}
                value={record.price}
                onChange={(e: any) => {
                  if (fieldError === 'price') setFieldError('');
                  
                  const value = parseFloat(`${e.target.value}`).toFixed(2);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      price: parseFloat(value),
                    }
                  });
                }}
                placeholder={'Preço'}
                type="number"
                size={50}
              />
            </Col>
            <Col md="4">
              <label>Mensalidade</label>
              <SanitizedInput
                style={{}}
                value={record.monthly}
                onChange={(e: any) => {
                  if (fieldError === 'monthly') setFieldError('');
                  
                  const value = parseFloat(`${e.target.value}`).toFixed(2);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      monthly: parseFloat(value),
                    }
                  });
                }}
                placeholder={'Mensalidade'}
                type="number"
                size={50}
              />
            </Col>
            <Col md="4">
              <label>Desconto *</label>
              <SanitizedInput
                style={fieldError === 'percent' ? {borderColor: 'red'} : {}}
                value={record.percent}
                onChange={(e: any) => {
                  if (fieldError === 'percent') setFieldError('');

                  const value = parseFloat(`${e.target.value}`).toFixed(2);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      percent: parseFloat(value),
                    }
                  });
                }}
                placeholder={'Desconto'}
                type="number"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <label>Frete Grátis</label>
              <SanitizedInput
                style={{}}
                value={record.free_shipping}
                onChange={(e: any) => {
                  if (fieldError === 'free_shipping') setFieldError('');
                  
                  const value = parseFloat(`${e.target.value}`).toFixed(2);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      free_shipping: parseFloat(value),
                    }
                  });
                }}
                placeholder={'Frete Grátis'}
                type="number"
                size={50}
              />
            </Col>
            <Col md="4">
              <label>School Pacote</label>
              <SanitizedInput
                style={fieldError === 'school_idcombo' ? {borderColor: 'red'} : {}}
                value={record.school_idcombo}
                onChange={(e: any) => {
                  if (fieldError === 'school_idcombo') setFieldError('');
                  
                  const value = parseInt(`${e.target.value}`);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      school_idcombo: value,
                    }
                  });
                }}
                placeholder={'School Pacote'}
                type="number"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <label style={{fontWeight: 'bold'}}>% da 1 Geração</label>
              <SanitizedInput
                value={record?.gen1fee}
                onChange={(e: any) => {
                  const value = parseFloat(`${e.target.value}`);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      gen1fee: value,
                    }
                  });
                }}
                placeholder={'% 1 Geração'}
                type="number"
                size={50}
              />
            </Col>
            <Col md="4">
              <label style={{fontWeight: 'bold'}}>% da 2 Geração</label>
              <SanitizedInput
                value={record?.gen2fee}
                onChange={(e: any) => {
                  const value = parseFloat(`${e.target.value}`);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      gen2fee: value,
                    }
                  });
                }}
                placeholder={'% 2 Geração'}
                type="number"
                size={50}
              />
            </Col>
            <Col md="4">
              <label style={{fontWeight: 'bold'}}>% da 3 Geração</label>
              <SanitizedInput
                value={record?.gen3fee}
                onChange={(e: any) => {
                  const value = parseFloat(`${e.target.value}`);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      gen3fee: value,
                    }
                  });
                }}
                placeholder={'% 3 Geração'}
                type="number"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <label style={{fontWeight: 'bold'}}>% da 4 Geração</label>
              <SanitizedInput
                value={record?.gen4fee}
                onChange={(e: any) => {
                  const value = parseFloat(`${e.target.value}`);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      gen4fee: value,
                    }
                  });
                }}
                placeholder={'% 4 Geração'}
                type="number"
                size={50}
              />
            </Col>
            <Col md="4">
              <label style={{fontWeight: 'bold'}}>% da 5 Geração</label>
              <SanitizedInput
                value={record?.gen5fee}
                onChange={(e: any) => {
                  const value = parseFloat(`${e.target.value}`);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      gen5fee: value,
                    }
                  });
                }}
                placeholder={'% 5 Geração'}
                type="number"
                size={50}
              />
            </Col>
            <Col md="4">
              <label style={{fontWeight: 'bold'}}>% da 6 Geração</label>
              <SanitizedInput
                value={record?.gen6fee}
                onChange={(e: any) => {
                  const value = parseFloat(`${e.target.value}`);

                  setRecord((prev) => {
                    return {
                      ...prev,
                      gen6fee: value,
                    }
                  });
                }}
                placeholder={'% 6 Geração'}
                type="number"
                size={50}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <label>Imagem</label>
            </Col>
          </Row>

          {record.image ? (
            <Row>
              <Col>
                <img src={record.image} className="App-logo" alt="logo" />
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col>
              <FileUpload
                chooseLabel="Procurar"
                auto
                name="file"
                customUpload={true}
                uploadHandler={e => {
                  setUploading(true);
                  upload(e.files[0], 'kits/images')
                    .then((response) => {
                      setRecord((prev) => {
                        return {
                          ...prev,
                          image: response,
                        }
                      });
                    })
                    .finally(() => setUploading(false))
                }}
                accept="image/*"
                maxFileSize={10000000}
              />

              {uploading ? (
                <LoadingSpin />
              ) : null}

            </Col>
          </Row>
        </Card>
      </Dialog>

      {/* Modal pra excluir */}
      <Dialog
        visible={!(typeof deleteID === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={"Excluir?"}
        modal
        className="p-fluid"
        footer={deleteDialogFooter}
        onHide={() => {
          hideDialog();
          setDeleteID(undefined);
        }}
      >
        Excluir registro?
      </Dialog>
    </>
  );
}

export default KitsView;
