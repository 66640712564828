import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardText,
  Form,
  FormGroup,
} from "reactstrap";
import { getRank, updateUser } from "../../api/users";
import { useLanguage } from "../../contexts/language/LanguageWrapper";
import Language from "../../language/language";
import SanitizedInput from "../../components/sanitizedInput";
import { useUser } from "../../contexts/user/UserWrapper";
import LoadingSpin from "../../components/loading/loading";

function ProfileView() {
  const toast = useRef<any>(null);

  const { language } = useLanguage();
  const { user, reloadUser } = useUser();

  const [heir1, setHeir1] = useState<string>();
  const [heir2, setHeir2] = useState<string>();
  const [heir3, setHeir3] = useState<string>();

  const [heir1Error, setHeir1Error] = useState(false);
  const [heir2Error, setHeir2Error] = useState(false);
  const [heir3Error, setHeir3Error] = useState(false);

  const [edited, setEdited] = useState(false);
  const [saving, setSaving] = useState(false);

  const submitRegister = async () => {
    if (!user?._id) return;

    setSaving(true);

    const data: iUser = {
      ...user,
    }

    if (heir1) data.heir1 = heir1;
    if (heir2) data.heir2 = heir2;
    if (heir3) data.heir3 = heir3;

    await updateUser(user._id, data)
      .then(response => {
        setEdited(false);

        toast.current.show({ 
          severity: "success", 
          summary: Language(language).Success, 
          detail: Language(language).Concluded, 
          life: 3000
        });

        reloadUser().finally(() => setSaving(false));
      })
      .catch(e => {
        console.log('e', e);
        if (e.response?.data) alert(e.response.data);
      });
  }

  useEffect(() => {
    if (!user) return;

    setHeir1(user.heir1);
    setHeir2(user.heir2);
    setHeir3(user.heir3);
  }, [user]);
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <div>
          <Card style={{padding: 20}}>
            <CardHeader>
              <h4 className="title">Perfil</h4>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <h4>{Language(language).Heirs}</h4>
                </Row>
                <Row>
                  <h5>{Language(language).HeirsDesc}</h5>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>{Language(language).NameAndDocument}</label>

                      <SanitizedInput
                        style={heir1Error ? {borderColor: 'red'} : {}}
                        value={heir1}
                        onChange={(text: any) => {
                          setHeir1(text.target.value)
                          setHeir1Error(false)
                          setEdited(true);
                        }}
                        placeholder={Language(language).NameAndDocument}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>{Language(language).NameAndDocument}</label>

                      <SanitizedInput
                        style={heir2Error ? {borderColor: 'red'} : {}}
                        value={heir2}
                        onChange={(text: any) => {
                          setHeir2(text.target.value)
                          setHeir2Error(false)
                          setEdited(true);
                        }}
                        placeholder={Language(language).NameAndDocument}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>{Language(language).NameAndDocument}</label>

                      <SanitizedInput
                        style={heir3Error ? {borderColor: 'red'} : {}}
                        value={heir3}
                        onChange={(text: any) => {
                          setHeir3(text.target.value)
                          setHeir3Error(false)
                          setEdited(true);
                        }}
                        placeholder={Language(language).NameAndDocument}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {edited ? 
                  saving ? (<LoadingSpin />) : (
                    <Row>
                      <Button 
                        style={{
                          padding: 7,
                          alignSelf: 'center'
                        }}
                        color="primary" 
                        className="animation-on-hover" 
                        onClick={submitRegister}
                      >
                        {Language(language).Save}
                      </Button>
                    </Row>
                  )
                : null}
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ProfileView;
