import axios from "axios"
import config from "../../config"

export const getUserById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}users/getOne/${id}`)

  return response;
}

export const createUser = async (data: iUser) => {
  const response = await axios.post(`${config.API_URL}users/post`, data);

  return response;
}

export const updateUser = async (id: string, data: iUser) => {
  const response = await axios.patch(`${config.API_URL}users/update/${id}`, data);

  return response;
}

export const userLogin = async (filter: any) => {
  const response = await axios.post(`${config.API_URL}users/login`, filter);

  return response;
}

export const userLoginToken = async (filter: any) => {
  const response = await axios.post(`${config.API_URL}users/logintoken`, filter);

  return response;
}

export const getGraduation = async (id: string) => {
  const response = await axios.get(`${config.API_URL}users/graduation/${id}`)

  return response;
}

export const userGraduate = async (id: string) => {
  const response = await axios.get(`${config.API_URL}users/graduate/${id}`)

  return response;
}

export const getChildrens = async (id: string) => {
  const response = await axios.get(`${config.API_URL}users/childrens/${id}`)

  return response;
}

export const usersCounter = async () => {
  const response = await axios.get(`${config.API_URL}users/counter`)

  return response;
}

export const requestCashout = async (id: string, amount: number) => {
  const response = await axios.get(`${config.API_URL}users/cashout/${id}/${amount}`)

  return response;
}

export const usersFinance = async () => {
  const response = await axios.get(`${config.API_URL}users/usersFinance`)

  return response;
}

export const cashoutRequests = async () => {
  const response = await axios.get(`${config.API_URL}users/cashoutRequests`)

  return response;
}

export const cashoutPaid = async (id: string) => {
  const response = await axios.get(`${config.API_URL}users/cashoutPaid/${id}`)

  return response;
}

export const userForgotPassword = async (filter: any) => {
  const response = await axios.post(`${config.API_URL}users/forgot`, filter);

  return response;
}

export const userCheckReset = async (filter: any) => {
  const response = await axios.post(`${config.API_URL}users/checkreset`, filter);

  return response;
}

export const userResetPassword = async (filter: any) => {
  const response = await axios.post(`${config.API_URL}users/resetpass`, filter);

  return response;
}

export const userAddCourse = async (id: string, course_id: string) => {
  const response = await axios.get(`${config.API_URL}users/addCourse/${id}/${course_id}`)

  return response;
}

export const userRemoveCourse = async (id: string, course_id: string) => {
  const response = await axios.get(`${config.API_URL}users/removeCourse/${id}/${course_id}`)

  return response;
}

export const usersAdminFind = async (filter: any) => {
  const response = await axios.post(`${config.API_URL}users/adminfind`, filter);

  return response;
}

export const userAdminFindParent = async (id: string) => {
  const response = await axios.get(`${config.API_URL}users/adminfind/${id}`)

  return response;
}

export const getRank = async () => {
  const response = await axios.get(`${config.API_URL}users/rank`)

  return response;
}
