import axios from "axios"
import config from "../../config"

export const getCategoryById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}categorys/getOne/${id}`)

  return response;
}

export const createCategory = async (data: iCategory) => {
  const response = await axios.post(`${config.API_URL}categorys/post`, data);

  return response;
}

export const getCategorys = async () => {
  const response = await axios.get(`${config.API_URL}categorys/getAll`)

  return response;
}

export const updateCategory = async (id: string, data: iCategory) => {
  const response = await axios.patch(`${config.API_URL}categorys/update/${id}`, data);

  return response;
}

export const deleteCategory = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}categorys/delete/${id}`);

  return response;
}
