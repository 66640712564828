import { CSSProperties, useEffect, useRef, useState } from "react";
import cartIcon from '../../../../assets/icons/ShoppingCart.svg';
import dropIcon from '../../../../assets/icons/arrow_drop_down.svg';
import { Button } from "primereact/button";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import env from '../../../../config';

import './index.css';
import Language from "../../../../language/language";
import { useLanguage } from "../../../../contexts/language/LanguageWrapper";
import { useUser } from "../../../../contexts/user/UserWrapper";
import { createAddress, getAddressByUserId, updateAddress } from "../../../../api/addresses";
import { Dialog } from "primereact/dialog";
import XIcon from "../../../../assets/icons/X.svg";
import userIcon from "../../../../assets/icons/user_min.svg";
import locationIcon from "../../../../assets/icons/location_on.svg";
import phoneIcon from "../../../../assets/icons/phone_min.svg";
import { Row } from "reactstrap";
import SanitizedInput from "../../../../components/sanitizedInput";
import { getCEPAddress } from "../../../../services/cep";
import { Toast } from "primereact/toast";
import LoadingSpin from "../../../../components/loading/loading";
import { quoteShipping } from "../../../../api/melhorEnvio";
import { useConfig } from "../../../../contexts/config/ConfigWrapper";
import { getProdutsTotal } from "../..";
import { createRequest } from "../../../../api/requests";

function Cart(props: iProps) {
  const { produts } = props;
  const { language } = useLanguage();
  const { user, kit, handleUser } = useUser();
  const { config } = useConfig();

  const [addresses, setAddresses] = useState<iAddress[]>([]);
  const [address, setAddress] = useState<iAddress>();
  const [showShipping, setShowShipping] = useState(false);
  const [editAddress, setEditAddress] = useState<iAddress>();
  const [peopleType, setPeopleType] = useState('PF');
  const [inputError, setInputError] = useState('');
  const [open, setOpen] = useState(true);
  const [loadingCEP, setloadingCEP] = useState(false);
  const [savingAddress, setSavingAddress] = useState(false);
  const [quotes, setQuotes] = useState<iMeQuote[]>([]);
  const [quoting, setQuoting] = useState(true);
  const [shipping, setShipping] = useState<iMeQuote & {free: boolean}>();
  const [confirmShip, setConfirmShip] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [checkoutId, setCheckoutId] = useState<string>();

  initMercadoPago(env.MERCADO_PAGO_PUBLIC_KEY);

  const toast = useRef<any>(null);

  const emptyAddress: iAddress = {
    name: '',
    phone: '',
    address: '',
    number: '',
    city: '',
    postal_code: '',
    state_abbr: '',
  }

  const loadAddresses = async () => {
    if (!user?._id) return;

    await getAddressByUserId(user._id)
      .then((response) => {
        setAddresses(response.data);
      })
      .catch((e) => console.log('getAddressByUserId error', e));
  }

  const loadCEP = () => {
    if (!editAddress?.postal_code.length) return;
    if (editAddress.postal_code.length < 8) return;

    setloadingCEP(true);

    getCEPAddress(editAddress.postal_code)
      .then((response) => {
        const CEP: iCEP = response.data;

        setEditAddress((prev) => prev ? ({
          ...prev,
          district: CEP.bairro,
          city: CEP.localidade,
          address: CEP.logradouro,
          state_abbr: CEP.uf,
        }) : ({
          ...emptyAddress,
          district: CEP.bairro,
          city: CEP.localidade,
          address: CEP.logradouro,
          state_abbr: CEP.uf,
        }));
      })
      .catch((e) => console.log('getCEPAddress error', e))
      .finally(() => setloadingCEP(false));
  }

  const saveAddress = () => {
    if (!editAddress || !user?._id) return;

    if (!editAddress.name.includes(' ')) {
      setInputError('name');

      return;
    }

    if (editAddress.phone.length < 11) {
      setInputError('phone');

      return;
    }

    if (
      editAddress.postal_code.length < 8 ||
      !editAddress.state_abbr ||
      editAddress.state_abbr === '' ||
      !editAddress.address ||
      editAddress.address === '' ||
      !editAddress.district ||
      editAddress.district === '' ||
      !editAddress.city ||
      editAddress.city === ''
    ) {
      setInputError('postal_code');

      return;
    }

    if (peopleType === 'PF' &&
      (!editAddress.document || editAddress.document.length < 11)
    ){
      setInputError('document');

      return;
    }

    if (peopleType === 'PJ' &&
      (!editAddress.company_document || editAddress.company_document.length < 11)
    ){
      setInputError('company_document');

      return;
    }

    if (editAddress.number === '') {
      setInputError('number');

      return;
    }

    setSavingAddress(true);

    const data: iAddress = {
      ...editAddress,
      user_id: user._id,
    }

    if (!editAddress._id) {
      createAddress(data)
        .then((response) => {
          loadAddresses();

          setEditAddress(undefined);

          toast.current.show({ 
            severity: "success", 
            summary: Language(language).Success, 
            detail: Language(language).AddressSaved, 
            life: 3000
          });
        })
        .catch((e) => console.log('createAddress error', e))
        .finally(() => setSavingAddress(false));
    } else {
      updateAddress(user._id, data)
        .then((response) => {
          loadAddresses();

          setEditAddress(undefined);

          toast.current.show({ 
            severity: "success", 
            summary: Language(language).Success, 
            detail: Language(language).AddressSaved, 
            life: 3000
          });
        })
        .catch((e) => console.log('updateAddress error', e))
        .finally(() => setSavingAddress(false));
    }
  }

  const requestQuoteShipping = () => {
    if (!address || !produts) return;

    const prods: iMeProdut[] = produts.map((prod) => ({
      id: `${prod.cod}`,
      insurance_value: prod.price,
      quantity: prod.buy_quant || 1,
      width: prod.width,
      height: prod.height,
      length: prod.length,
      weight: prod.weight ? prod.weight / 1000 : undefined,
    }))

    const data: iMeQuoteRequest = {
      to: {
        postal_code: address.postal_code,
      },
      products: prods,
    }

    setQuoting(true);

    quoteShipping(data)
      .then((response) => {
        const resp: iMeQuote[] = response.data;

        resp.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

        const max = resp.filter((_quote, index) => index < 4);

        setQuotes(max);
      })
      .catch((e) => {
        console.log('quoteShipping error:', e);
      })
      .finally(() => setQuoting(false));
  }

  const createCheckout = () => {
    if (!user?._id || !produts || !address) return;

    setRequesting(true);

    createRequest(
      user._id,
      produts,
      address,
      shipping
    )
      .then((response) => setCheckoutId(response.data))
      .catch((e) => {
        console.log('createCheckout error', e);

        if (e.message) {
          alert(e.message);

          toast.current.show({ 
            severity: "danger", 
            summary: 'Erro!', 
            detail: e.message, 
            life: 3000
          });
        }
      })
      .finally(() => setRequesting(false));
  }

  const _renderProduts = () => produts?.map((prod, index) => (
    <div className="prod-container">
      <div style={{
          display: 'flex',
      }}>
        <img  
          src={prod.colors.find((color) => color.name === prod.color)?.images[0] || prod.images[0]}
          className="prod-image"
          alt="logo" 
        />

        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <span className="prod-name">{prod.name}</span>

          <span className="prod-desc">{`${prod.color || ''} ${prod.size ? `${Language(language).Size} ${prod.size}` : ''}`}</span>

          <span className="prod-price">{`R$ ${prod.price.toFixed(2)}`}</span>
        </div>
      </div>

      <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '1rem',
      }}>
        <div style={{
          display: 'flex',
        }}>
          <Button 
            className="buy-button-minum"
            onClick={() => {
              if (prod?.buy_quant && prod.buy_quant > 1) {
                const newProds: iProdut[] = [...produts];

                newProds[index].buy_quant = prod.buy_quant - 1;
  
                handleUser({ ...user, cart: newProds });
              } else if (prod.buy_quant === 1) {
                const newProds = produts.filter((_i, id) => index !== id);
  
                handleUser({ ...user, cart: newProds });
              }
            }}
          >
            <span className="choose-button-text">-</span>
          </Button>

          <div className="buy-quant">{prod.buy_quant}</div>

          <Button 
            className="buy-button-plus"
            onClick={() => {
              const newProds: iProdut[] = [...produts];
              const buy_quant = prod.buy_quant || 1;
  
              newProds[index].buy_quant = buy_quant + 1;
  
              handleUser({ ...user, cart: newProds });
            }}
          >
            <span className="choose-button-text">+</span>
          </Button>
        </div>

        { prod.reference ? (
          <span className="prod-desc">{`Ref: ${prod.reference}`}</span>
        ) : null}
      </div>
    </div>
  ))

  const _renderShippingButton = () => (
    <Button 
      className="shipping-button"
      onClick={() => {setShowShipping(true)}}
    >
      <span className="prod-name">{Language(language).ChooseShipping}</span>
    </Button>
  )

  const _renderCheckoutButton = () => {
    if (!kit && !user?.plan_id) return null;

    if (!produts) return null;

    if (kit && user?.new_kit) {
      const total = getProdutsTotal(produts, kit.percent);

      if (total.discount < kit.price) return (
        <span className="still-text">{Language(language).StillXKit(`R$ ${(kit.price - total.discount).toFixed(2)}`)}</span>
      )
    }

    return checkoutId ? (
      <Wallet 
        initialization={{ preferenceId: checkoutId }} 
        customization={{ texts:{ valueProp: 'smart_option'}}} 
      />
    ) : (
      <Button 
        className="buy-button"
        onClick={createCheckout}
        disabled={requesting}
      >
        {requesting ? (
          <LoadingSpin spin />
        ) : (
          <span className="choose-button-text">{Language().Checkout}</span>
        )}
      </Button>
    )
  }

  const _renderFooter = () => {
    if (!produts) return null;

    const total = getProdutsTotal(produts, kit?.percent || 0);

    let frete = parseFloat(`${shipping?.price}`) || 0;

    if (shipping?.free) frete = 0;
    
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        padding: '1rem',
        paddingTop: 0,
        width: '100%'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'flex-end',
          alignItems: 'flex-end'
        }}>
          {_renderShippingButton()}
  
          <span className="shipping-text" style={{marginTop: '.5rem'}}>
            {shipping ? shipping.free ? `${Language(language).Shipping}: ${shipping.name} (${Language(language).Free.toUpperCase()})` : `${Language(language).Shipping}: ${shipping.name} (${shipping.currency} ${shipping.price})` : `${Language(language).PickupInStore} (R$ 0,00)`}
          </span>

          <span className="shipping-text">
            {`${Language(language).FinalValue}: R$ ${(total.total + total.other_total - total.discount + frete).toFixed(2)}`}
          </span>
        </div>
  
        {_renderCheckoutButton()}
      </div>
    )
  };

  const _renderAddress = () => addresses.map((item, index) => {
    if (index === 0 && !address) setAddress(item);

    return (
      <Button
        className={address?._id === item._id ? 'address-button-selected' : "address-button"}
        onClick={() => setAddress(item)}
      >
        <span className="prod-name">{item.name}</span>
  
        <span className="prod-name">{`${item.address}, ${item.number}${item.complement ? `, ${item.complement}` : ''}, ${item.district}`}</span>
  
        <span className="prod-name">{`${Language(language).CEP} ${item.postal_code}`}</span>
  
        {item.email ? (<span className="prod-name">{item.email}</span>) : null}
  
        {item.document ? (<span className="prod-name">CPF {item.document}</span>) : null}
  
        {item.company_document ? (<span className="prod-name">CNPJ {item.company_document}</span>) : null}
      </Button>
    )
  })

  const _renderShipping = () => {
    if (!produts?.length) return;

    const total = getProdutsTotal(produts, kit?.percent || 0)

    return (
      <>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <span className="modal-title">{Language(language).Address}</span>
  
          <Button 
            className="modal-xicon"
            onClick={() => setShowShipping(false) }
          >
            <img src={XIcon} alt="" />
          </Button>
        </div>
  
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyItems: 'center',
          margin: '1rem',
        }}>
          <Row style={{
            display: 'flex',
            justifySelf: 'center',
            margin: 0,
          }}>{_renderAddress()}</Row>
  
          <Button 
            className="shipping-button"
            onClick={() => setEditAddress(emptyAddress)}
          >
            <span className="prod-name">{Language(language).NewAddress}</span>
          </Button>
          <br />
  
          {quoting ? (<LoadingSpin spin />) : (
            <>
              <span className="modal-title">{Language(language).Shipping}</span>
              <br />
  
              <div className="quote-container">
                <div style={{display: 'flex', alignSelf: 'flex-start'}}>
                  <input
                    type='checkbox'
                    checked={typeof shipping === 'undefined'}
                    style={{
                      marginRight: 5,
                    }}
                    onChange={() => {
                      setShipping(undefined);
                    }}
                  />
  
                  <span className="prod-name">{Language(language).PickupInStore}</span>
                </div>
  
                <span className="prod-name">{`R$ 0,00`}</span>
              </div>
  
              {quotes.map((quote, index) => {
                const free_shipping = kit?.free_shipping || config?.free_shipping;

                return (
                  <div className="quote-container">
                    <div style={{display: 'flex', alignSelf: 'flex-start'}}>
                      <input
                        type='checkbox'
                        checked={shipping?.id === quote.id}
                        style={{
                          marginRight: 5,
                        }}
                        onChange={() => {
                          let free = false;
  
                          if (index === 0 && free_shipping &&
                          (total.total - total.discount + total.other_total) >= free_shipping) free = true;
                          
                          setShipping({
                            ...quote,
                            free,
                          });
                        }}
                      />
                      
                      <span className="prod-name">{quote.name}</span>
                    </div>
    
                    {index === 0 && free_shipping &&
                    (total.total - total.discount + total.other_total) >= free_shipping ?
                      (
                        <span className="prod-name">{`${Language(language).Free.toUpperCase()} - ${quote.delivery_time + (config?.shipDays || 0)} ${Language(language).Days.toLowerCase()}`}</span>
                      ) :
                      (
                        <span className="prod-name">{`${quote.currency} ${quote.price} - ${quote.delivery_time + (config?.shipDays || 0)} ${Language(language).Days.toLowerCase()}`}</span>
                      )
                    }
                  </div>
                )
              })}
  
            <Button 
              className="buy-button"
              onClick={() => {
                setConfirmShip(true);
  
                setShowShipping(false);
              }}
            >
              <span className="choose-button-text">{Language().Confirm}</span>
            </Button>
            </>
          )}
        </div>
      </>
    )
  }

  const _renderEditAddress = () => {
    if (!editAddress) return null;
    
    return (
      <div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <span className="modal-title">{editAddress?._id ? Language(language).EditAddress : Language(language).NewAddress}</span>

          <Button 
            className="modal-xicon"
            onClick={() => setEditAddress(undefined) }
          >
            <img src={XIcon} alt="" />
          </Button>
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          margin: '1rem',
        }}>
          <Row style={{
            marginLeft: -30,
            marginRight: -30,
          }}>
            <SanitizedInput
              style={inputError === 'name' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
              value={editAddress.name}
              onChange={(text: any) => {
                setEditAddress((prev) => prev ? ({
                  ...prev,
                  name: text.target.value,
                }) : ({
                  ...emptyAddress,
                  name: text.target.value,
                }));

                if (inputError === 'name') setInputError('');
              }}
              placeholder={Language(language).RecipientName}
              type="text"
            />

            <img style={leftIcon} src={userIcon} alt='' />
          </Row>

          <Row>
            <Row>
              <SanitizedInput
                style={inputError === 'phone' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={editAddress.phone}
                onChange={(text: any) => {
                  setEditAddress((prev) => prev ? ({
                    ...prev,
                    phone: text.target.value,
                  }) : ({
                    ...emptyAddress,
                    phone: text.target.value,
                  }));

                  if (inputError === 'phone') setInputError('');
                }}
                placeholder={Language(language).Phone}
                type="text"
              />

              <img style={leftIcon} src={phoneIcon} alt='' />
            </Row>

            <Row>
              <SanitizedInput
                style={inputError === 'email' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={editAddress.email}
                onChange={(text: any) => {
                  setEditAddress((prev) => prev ? ({
                    ...prev,
                    email: text.target.value,
                  }) : ({
                    ...emptyAddress,
                    email: text.target.value,
                  }));

                  if (inputError === 'email') setInputError('');
                }}
                placeholder='E-mail'
                type="text"
              />

              <img style={leftIcon} src={userIcon} alt='' />
            </Row>
          </Row>

          <Row>
            <Row>
              <SanitizedInput
                style={inputError === 'postal_code' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={editAddress.postal_code}
                onChange={(text: any) => {
                  const value = text.target.value.replace(/\D/,'')

                  setEditAddress((prev) => prev ? ({
                    ...prev,
                    postal_code: value,
                  }) : ({
                    ...emptyAddress,
                    postal_code: value,
                  }));

                  if (inputError === 'postal_code') setInputError('');
                }}
                placeholder={Language(language).CEP}
                type="text"
                pattern="[0-9*]"
              />

              <img style={leftIcon} src={locationIcon} alt='' />
            </Row>

            <Row>
              <SanitizedInput
                style={inputError === 'state_abbr' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={editAddress.state_abbr}
                onChange={(text: any) => {
                  setEditAddress((prev) => prev ? ({
                    ...prev,
                    state_abbr: text.target.value,
                  }) : ({
                    ...emptyAddress,
                    state_abbr: text.target.value,
                  }));

                  if (inputError === 'state_abbr') setInputError('');
                }}
                placeholder={loadingCEP ? `${Language(language).Wait}...` : Language(language).State}
                type="text"
                disabled={true}
              />

              <img style={leftIcon} src={locationIcon} alt='' />
            </Row>
          </Row>

          <Row style={{
            marginLeft: -30,
            marginRight: -30,
          }}>
            <SanitizedInput
              style={inputError === 'address' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
              value={editAddress.address}
              onChange={(text: any) => {
                setEditAddress((prev) => prev ? ({
                  ...prev,
                  address: text.target.value,
                }) : ({
                  ...emptyAddress,
                  address: text.target.value,
                }));

                if (inputError === 'address') setInputError('');
              }}
              placeholder={loadingCEP ? `${Language(language).Wait}...` : Language(language).Street}
              type="text"
              disabled={true}
            />

            <img style={leftIcon} src={locationIcon} alt='' />
          </Row>

          <Row>
            <Row>
              <SanitizedInput
                style={inputError === 'number' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={editAddress.number}
                onChange={(text: any) => {
                  setEditAddress((prev) => prev ? ({
                    ...prev,
                    number: text.target.value,
                  }) : ({
                    ...emptyAddress,
                    number: text.target.value,
                  }));

                  if (inputError === 'number') setInputError('');
                }}
                placeholder={Language(language).Number}
                type="text"
              />

              <img style={leftIcon} src={locationIcon} alt='' />
            </Row>

            <Row>
              <SanitizedInput
                style={inputError === 'complement' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={editAddress.complement}
                onChange={(text: any) => {
                  setEditAddress((prev) => prev ? ({
                    ...prev,
                    complement: text.target.value,
                  }) : ({
                    ...emptyAddress,
                    complement: text.target.value,
                  }));

                  if (inputError === 'complement') setInputError('');
                }}
                placeholder={Language(language).Complement}
                type="text"
              />

              <img style={leftIcon} src={locationIcon} alt='' />
            </Row>
          </Row>

          <Row style={{
            marginLeft: -30,
            marginRight: -30,
          }}>
            <SanitizedInput
              style={inputError === 'district' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
              value={editAddress.district}
              onChange={(text: any) => {
                setEditAddress((prev) => prev ? ({
                  ...prev,
                  district: text.target.value,
                }) : ({
                  ...emptyAddress,
                  district: text.target.value,
                }));

                if (inputError === 'district') setInputError('');
              }}
              placeholder={loadingCEP ? `${Language(language).Wait}...` : Language(language).District}
              type="text"
              disabled={true}
            />

            <img style={leftIcon} src={locationIcon} alt='' />
          </Row>

          <Row style={{
            marginLeft: -30,
            marginRight: -30,
          }}>
            <SanitizedInput
              style={inputError === 'city' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
              value={editAddress.city}
              onChange={(text: any) => {
                setEditAddress((prev) => prev ? ({
                  ...prev,
                  city: text.target.value,
                }) : ({
                  ...emptyAddress,
                  city: text.target.value,
                }));

                if (inputError === 'city') setInputError('');
              }}
              placeholder={loadingCEP ? `${Language(language).Wait}...` : Language(language).City}
              type="text"
              disabled={true}
            />

            <img style={leftIcon} src={locationIcon} alt='' />
          </Row>

          <Row style={{
            marginLeft: 0,
            marginTop: 10,
            marginBottom: -10,
          }}>
            <input
              type='checkbox'
              checked={peopleType === 'PF'}
              style={{
                marginRight: 5,
              }}
              onChange={() => {
                setPeopleType('PF')
              }}
            />

            <span className="prod-name">{Language(language).NaturalPerson}</span>

            <input
              type='checkbox'
              checked={peopleType === 'PJ'}
              style={{
                marginRight: 5,
              }}
              onChange={() => {
                setPeopleType('PJ')
              }}
            />

            <span className="prod-name">{Language(language).LegalPerson}</span>
          </Row>

          {peopleType === 'PF' ? (
            <Row>
              <Row>
              <SanitizedInput
                style={inputError === 'document' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                value={editAddress.document}
                onChange={(text: any) => {
                  setEditAddress((prev) => prev ? ({
                    ...prev,
                    document: text.target.value,
                  }) : ({
                    ...emptyAddress,
                    document: text.target.value,
                  }));
  
                  if (inputError === 'document') setInputError('');
                }}
                placeholder='CPF'
                type="text"
              />
  
              <img style={leftIcon} src={userIcon} alt='' />
              </Row>
            </Row>
          ) : (
            <Row>
              <Row>
                <SanitizedInput
                  style={inputError === 'company_document' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                  value={editAddress.company_document}
                  onChange={(text: any) => {
                    setEditAddress((prev) => prev ? ({
                      ...prev,
                      company_document: text.target.value,
                    }) : ({
                      ...emptyAddress,
                      company_document: text.target.value,
                    }));

                    if (inputError === 'company_document') setInputError('');
                  }}
                  placeholder='CNPJ'
                  type="text"
                />

                <img style={leftIcon} src={userIcon} alt='' />
              </Row>

              <Row>
                <SanitizedInput
                  style={inputError === 'state_register' ? {borderColor: 'red', ...inputStyle} : {...inputStyle}}
                  value={editAddress.state_register}
                  onChange={(text: any) => {
                    setEditAddress((prev) => prev ? ({
                      ...prev,
                      state_register: text.target.value,
                    }) : ({
                      ...emptyAddress,
                      state_register: text.target.value,
                    }));

                    if (inputError === 'state_register') setInputError('');
                  }}
                  placeholder={Language(language).StateRegister}
                  type="text"
                />

                <img style={leftIcon} src={userIcon} alt='' />
              </Row>
            </Row>
          )}

          {savingAddress ? (<LoadingSpin spin />) : (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <Button 
                className="address-cancel-button"
                onClick={() => setEditAddress(undefined)}
              >
                <span className="prod-name">{Language(language).Cancel}</span>
              </Button>
  
              <Button 
                className="shipping-button"
                onClick={saveAddress}
              >
                <span className="prod-name">{Language(language).Save}</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    loadAddresses();
  }, [user?._id]);

  useEffect(() => {
    loadCEP();
  }, [editAddress?.postal_code]);

  useEffect(() => {
    requestQuoteShipping();
  }, [address]);

  useEffect(() => {
    setCheckoutId(undefined);
  }, [produts?.length])

  if (!produts || produts.length === 0) return null;

  return (
    <>
      <Toast ref={toast} />

      <div style={{
        display: 'flex',
      }}>
        { open ? (
          <div className="cart-container">
            <div className="cart-prod-container">
              {_renderProduts()}
            </div>

            {confirmShip ? _renderFooter() : (
              <div style={{
                alignSelf: 'center'
              }}>
                {_renderShippingButton()}
              </div>
            )}
          </div>
        ) : null }
        
        <Button
          className="cart-button"
          onClick={() => setOpen(!open)}
        >
          <img src={cartIcon} alt="" />

          <span className="cart-button-text">
            {Language().CartItems(produts?.length)}
          </span>

          <img src={dropIcon} alt="" />
        </Button>
      </div>

      {/* Modal Frete */}
      <Dialog
        visible={showShipping}
        modal
        className="shipping-modal"
        onHide={() => setShowShipping(false) }
        content={editAddress ? _renderEditAddress : _renderShipping}
      />
    </>
  );
}

export default Cart;

interface iProps {
  produts?: iProdut[],
}

const inputStyle: CSSProperties = {
  background: '#D9D9D9',
  borderRadius: '9px',
  padding: 10,
  paddingLeft: 40,
  margin: 20,
  marginBottom: 0,
  color: 'black',
  fontSize: '1rem',
  width: '100%',
}

const leftIcon: CSSProperties = {
  position: 'absolute',
  height: '1.7rem',
  margin: 25,
  color: 'black',
}
