import home from '../assets/icons/Home.svg';
import people from '../assets/icons/people.svg';
import cart from '../assets/icons/cart.svg';
import store from '../assets/icons/store.svg';
import course from '../assets/icons/course.svg';
import books from '../assets/icons/books.svg';
import Language from '../language/language';

const routes = [
  {
    path: "/",
    name: "Visão Geral",
    icon: "",
    layout: "/go",
    svg: home,
    title: Language().WellcomeVirtualOffice,
  },
  {
    path: "/affiliates",
    name: "Afiliados",
    icon: "",
    layout: "/go",
    svg: people,
  },
  {
    path: "/requests",
    name: "Pedidos",
    icon: "",
    layout: "/go",
    svg: cart,
    store: true,
  },
  {
    path: "/store",
    name: "Loja",
    icon: "",
    layout: "/go",
    svg: store,
    store: true,
  },
  {
    path: "/courses",
    name: "Cursos",
    title: "Cursos",
    icon: "",
    layout: "/go",
    svg: course,
    is_root: true,
  },
  {
    path: "/mycourses",
    name: "Meus Cursos",
    title: "Meus Cursos",
    icon: "",
    layout: "/go",
    svg: books,
    is_root: true,
  },
  {
    path: "/logistics",
    name: "Logística",
    title: "Logística",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/produts",
    name: "Produtos",
    title: "Produtos",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/expert",
    name: "Criar Curso",
    title: "Expert",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/rank",
    name: "Ranking",
    title: "Ranking",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/kits",
    name: "Kits",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/plans",
    name: "Planos",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/graduations",
    name: "Graduações",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/categorys",
    name: "Categorias",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/finance",
    name: "Financeiro",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/users",
    name: "Usuários",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/config",
    name: "Configuração",
    icon: "",
    layout: "/go",
    is_root: true,
  },
  {
    path: "/icons",
    name: "Ícons",
    icon: "",
    layout: "/go",
    is_root: true,
  },
];

export default routes;
