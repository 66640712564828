import axios from "axios"
import config from "../../config"

export const getContentById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}contents/getOne/${id}`)

  return response;
}

export const createContent = async (module_id: string, data: iContent, files?: any) => {
  const formData = new FormData();

  if (files) files.forEach((element: any) => {
    formData.append('file', element);
  });

  formData.append('body', JSON.stringify(data));
  
  const response = await axios.post(`${config.API_URL}contents/post/${module_id}`, formData);

  return response;
}

export const getContents = async (module_id: string) => {
  const response = await axios.get(`${config.API_URL}contents/getAll/${module_id}`)

  return response;
}

export const updateContent = async (id: string, data: iContent, files?: any) => {
  const formData = new FormData();

  if (files) files.forEach((element: any) => {
    formData.append('file', element);
  });

  formData.append('body', JSON.stringify(data));
  
  const response = await axios.patch(`${config.API_URL}contents/update/${id}`, formData);

  return response;
}

export const deleteContent = async (id: string) => {
  const response = await axios.delete(`${config.API_URL}contents/delete/${id}`);

  return response;
}

export const deleteContentFile = async (id: string, file: number) => {
  const response = await axios.delete(`${config.API_URL}contents/deleteFile/${id}/${file}`);

  return response;
}

export const getContent = async (id: string, user_id: string, count_access: boolean, completed?: string) => {
  const response = await axios.get(`${config.API_URL}contents/accessContent/${id}/${user_id}/${count_access}/${completed || 'no'}`)

  return response;
}
