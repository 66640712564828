import es from "./es";
import ptbr from "./ptbr";
import en from "./en";

export const Language = (language: string = 'ptbr'): iLanguage => {
  if (language === "es") return es;
  if (language === "en") return en;
  return ptbr;
};

export default Language;
