import { useState } from "react";
import { Icon as PlayIcon } from '../../assets/icons/playArrow';
import { Button } from "primereact/button";

function ImagCarousel(props: iProps) {
  const { images, className } = props;

  const [index, setIndex] = useState(0);

  const nextImage = () => {
    if (images.length > index + 1) setIndex(index + 1);

    else setIndex(0);
  }

  const prevImage = () => {
    if (index > 0) setIndex(index - 1);

    else if (images.length) setIndex(images.length - 1);
  }

  if (!images[index]) return null;

  return (
    <div className={className} style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <img  
        src={images[index]} 
        style={{
          height: '35vmin', 
          width: '35vmin',
          borderRadius: '37px',
        }} 
        alt="logo" 
      />

      <div style={{
        display: 'flex',
        position: 'absolute',
        justifyContent: 'space-between',
        width: '35vmin',
      }}>
      {images.length > 1 ? (
        <Button 
          style={{
            // position: 'absolute',
            background: 'none',
            border: 'none',
          }}
          onClick={nextImage}
        >
          <PlayIcon fill='black' transform={'scaleX(-1)'} />
        </Button>
      ) : null}

      {images.length > 1 ? (
        <Button 
          style={{
            // position: 'absolute',
            background: 'none',
            border: 'none',
          }}
          onClick={prevImage}
        >
          <PlayIcon fill='black' />
        </Button>
      ) : null}
      </div>
    </div>
  );
}

export default ImagCarousel;

interface iProps {
  images: string[], // Mostrar apenas o spin
  className?: string,
}
