import { useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext"
import { userLoginToken } from "../../api/users";
import { getPlanById } from "../../api/plans";
import { getKitById } from "../../api/kits";

export default function UserContextWrapper(props: any) {
  const [user, setUser] = useState<iUser | undefined>();
  const [plan, setPlan] = useState<iPlan | undefined>();
  const [kit, setKit] = useState<iKit | undefined>();
  const [loading, setLoading] = useState(true);
  const [accessedCourses, setAccessedCourses] = useState<string[]>([]);

  const handleUser = (newUser: any) => {
    setUser(newUser);
  }

  const reloadUser = async (keep: boolean = false) => {
    if (user) loginToken();

    if (!keep) localStorage.clear();
  }

  const accessCourse = (course: string) => {
    if (accessedCourses.includes(course)) return;

    setAccessedCourses([...accessedCourses, course]);
  }

  const loginToken = () => {
    const local = localStorage.getItem('@winner_current');

    if (local) {
      const localInfo = JSON.parse(local);

      const { id, token } = localInfo;

      if (id && token) {
        const filter = {
          id,
          token,
        }

        userLoginToken(filter)
          .then(response => {
            const localUser: any = response.data;

            delete localUser.__v;

            if (localUser._id) {
              setUser(localUser);
            }
          })
          .catch(e => {
            console.log('loginTokenError', e);
          })
          .finally(() => setLoading(false));

      } else setLoading(false);

    } else setLoading(false);
  }

  const loadPlan = () => {
    if (!user?.plan_id) {
      setPlan(undefined);

      return;
    }

    getPlanById(user.plan_id)
      .then((response) => {
        setPlan(response.data);
      })
      .catch(e => {
        console.log('getPlanById error', e);
      });
  }

  const loadKit = () => {
    if (!user?.kit_id) {
      setKit(undefined);
      
      return;
    }

    getKitById(user.kit_id)
      .then((response) => {
        setKit(response.data);
      })
      .catch(e => {
        console.log('getKitById error', e);
      });
  }

  // Recuperando login via token - Mantendo usuário conectado
  useEffect(() => {
    loginToken();
  }, []);

  useEffect(() => {
    loadPlan();
  }, [user?.plan_id]);

  useEffect(() => {
    loadKit();
  }, [user?.kit_id]);

  return (
    <UserContext.Provider value={{ 
      loading: loading, 
      user: user, 
      plan: plan,
      kit: kit,
      accessedCourses: accessedCourses,
      handleUser: handleUser,
      reloadUser: reloadUser,
      accessCourse: accessCourse,
    }}>
      {props.children}
    </UserContext.Provider>
  );
}

export const useUser = () => useContext(UserContext);
