import axios from "axios"
import config from "../../config"

export const createCourse = async (user_id: string, data: iCourse, file?: any) => {
  const formData = new FormData();

  formData.append('file', file);

  formData.append('body', JSON.stringify(data));
  
  const response = await axios.post(`${config.API_URL}courses/post/${user_id}`, formData)

  return response;
}

export const updateCourse = async (id: string, user_id: string, data: iCourse, file?: any) => {
  const formData = new FormData();

  formData.append('file', file);

  formData.append('body', JSON.stringify(data));
  
  const response = await axios.patch(`${config.API_URL}courses/update/${id}/${user_id}`, formData)

  return response;
}

export const getCoursesByUser = async (id: string) => {
  const response = await axios.get(`${config.API_URL}courses/getByUser/${id}`)

  return response;
}

export const getCoursesByUserLevel = async (id: string) => {
  const response = await axios.get(`${config.API_URL}courses/findByUserLevel/${id}`)

  return response;
}

export const getCoursesByUserCourses = async (id: string) => {
  const response = await axios.get(`${config.API_URL}courses/findByUserCourses/${id}`)

  return response;
}

export const getCourseById = async (id: string) => {
  const response = await axios.get(`${config.API_URL}courses/getOne/${id}`)

  return response;
}

export const getCourseByUser = async (id: string, user_id: string) => {
  const response = await axios.get(`${config.API_URL}courses/getByIdAndUser/${id}/${user_id}`)

  return response;
}

export const getCourseByUserLevel = async (id: string, user_id: string) => {
  const response = await axios.get(`${config.API_URL}courses/getByIdAndUserLevel/${id}/${user_id}`)

  return response;
}

export const getAprovedCourses = async () => {
  const response = await axios.get(`${config.API_URL}courses/getAproved`)

  return response;
}
