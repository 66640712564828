import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
} from "reactstrap";
import Language from "../../language/language";

import { useLanguage } from "../../contexts/language/LanguageWrapper";
import LoadingSpin from "../../components/loading/loading";
import { getCourseByUserLevel } from "../../api/courses";
import { useUser } from "../../contexts/user/UserWrapper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getModules } from "../../api/modules";
import { getContents } from "../../api/contents";
import { getProgress } from "../../api/progress";
import { getCertificate } from "../../api/certificates";
import { Dialog } from "primereact/dialog";
import CertificatePreview from "../../components/certificate";
import { certificateModels } from "../../utils/Functions";
import { getBooks } from "../../api/books";
import { userAddCourse } from "../../api/users";
import { getPlansMinLevel, purchasePlan } from "../../api/plans";
import blockIcon from "../../assets/icons/block.svg";
import "./styles.css";

function CourseModulesView() {
  const { language } = useLanguage();
  const { user, plan } = useUser();
  const { id } = useParams();
  const navigate = useNavigate();

  const [records, setRecords] = useState<iModule[]>([]);
  const [course, setCourse] = useState<iCourse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(true);
  const [loadModules, setLoadModules] = useState<boolean>(true);
  const [progress, setProgress] = useState<iProgress>();
  const [certificate, setCertificate] = useState<any>();
  const certificates = certificateModels();
  const [showCertificate, setShowCertificate] = useState<boolean>(false);
  const [loadingBooks, setLoadingBooks] = useState<boolean>(true);
  const [books, setBooks] = useState<iBook[]>([]);
  const [plans, setPlans] = useState<iPlan[]>([]);
  const [purchasing, setPurchasing] = useState<boolean>(false);
  const [pix, setPix] = useState<any>(undefined);

  const toast = useRef<any>(null);

  const loadRecords = async () => {
    if (!course?._id) return;

    await getModules(course._id)
      .then((response) => {
        setRecords(response.data);

        setLoadModules(false);
      })
      .catch((e) => console.log('getModules error', e));
  }

  const loadCourse = async () => {
    if (!id || !user?._id || !plan?.level) return

    await getCourseByUserLevel(id, user._id)
      .then((response) => {
        setCourse(response.data);

        setLoad(false);
      })
      .catch((e) => console.log('getCourseByUser error', e));
  }

  const loadContents = async (module_id: string) => {
    await getContents(module_id)
      .then((response) => {
        setRecords((prev) => {
          return prev.map((item) => {
            if (item._id === module_id) return {
              ...item,
              contents: response.data,
              loading: false,
            }

            return item;
          })
        })
      })
      .catch((e) => console.log('getCourseByUser error', e));
  }

  const loadProgress = async () => {
    if (!user?._id || !course?._id) return;

    await getProgress(user._id, course._id)
      .then((response) => {
        setProgress(response.data);
      })
      .catch((e) => console.log('getCourseByUser error', e));
  }

  const loadBooks = async () => {
    if (!course?._id) return;

    await getBooks(course._id)
      .then((response) => {
        setBooks(response.data);

        setLoadingBooks(false);
      })
      .catch((e) => console.log('getBooks error', e));
  }

  const loadPlans = async () => {
    if (course?.level) await getPlansMinLevel(course.level)
      .then((response) => {
        setPlans(response.data);
      })
      .catch(e => console.log('getNextPlans error', e));
  }

  const purchase = async (plan_id: string) => {
    if (!user?._id) {
      alert(`${Language(language).SomethingWrong} ${Language(language).RefreshPage}`);
      return;
    }

    setPurchasing(true);

    await purchasePlan(user._id, plan_id)
      .then((response) => {
        setPix(response.data);
      })
      .catch((e) => {
        console.log('purchase error', e);
      })
      .finally(() => setPurchasing(false));
  }

  const loadCertificate = async () => {
    if (!course?.certificate_id) return;

    // await getCertificate(course.certificate_id)
    //   .then((response) => {
    //     setCertificate(response.data);
    //   })
    //   .catch((e) => {
    //     console.log('getCertificate error', e);
    //   });

    const cert = certificates.find((item) => 
      item.id === course.certificate_id
    )

    setCertificate(cert);
  }

  const _renderPlans = () => {
    const sorted = plans.sort((a: iPlan, b: iPlan) => {
      return b.fee - a.fee;
    });

    return sorted.map((data: iPlan) => (
      <div className="plan-container">
        {data.image ? (
          <img  src={data.image} className="plan-image" alt="logo" />
        ) : null}

        <label className="plan-name">{data.name}</label>

        {plan?._id === data._id ? null : (
          <span className="plan-name">{`R$ ${data.fee.toFixed(2)}`}</span>
        )}

        {plan?._id === data._id ? null : (
          <span className="plan-info">{`(${data.price.toFixed(2)}/${Language(language).month})`}</span>
        )}

        {purchasing ? (<LoadingSpin spin />) : (
          <Button
            color="info"
            className="plan-purchase-button"
            onClick={() => purchase(data._id || '')}
          >
            <span className="plan-purchase-button-text">{Language(language).SelectPlan}</span>
          </Button>
        )}
      </div>
    ))
  }

  const renderSchoolList = () => {
    if (!course?.school_class || !Array.isArray(course.school_class) || course.school_class.length === 0) return null;

    return course.school_class.map((item, index) => (
      <div style={{
        display: 'flex',
        justifyItems: 'center',
        marginBottom: '.5rem',
        borderRadius: '11px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '.5rem',
        paddingLeft: '1rem',
      }}>
        <label style={{
          margin: 0,
          fontSize: 16,
        }}>{`${index + 1} - `}</label>
        {" "}
        <label style={{
          margin: 0,
          fontSize: 16,
        }}>{item.aula}</label>
      </div>
    ))
  }

  const renderModules = () => {
    if (records.length === 0) return(
      <label style={{
        fontWeight: 'bold',
        fontSize: 16,
      }}>{Language(language).CourseUnderConstruction}</label>
    )

    return records.map((data) => (
      <Card style={{
        backgroundColor: '#1f1a2a',
        padding: 5,
        paddingLeft: 15,
      }}>
        <Row>
          <Col md='6'>
            <label style={{
              fontWeight: 'bold',
              fontSize: 16,
            }}>{`${data.number} - `}</label>
            {" "}
            <label style={{
              fontWeight: 'bold',
              fontSize: 16,
            }}>{data.name}</label>
          </Col>

          {progress?.modules && progress.modules[`${data._id}`] ? (
            <Col>
              <label style={progress.modules[`${data._id}`].completeds > 0 &&
                progress.modules[`${data._id}`].completeds === progress.modules[`${data._id}`].total ? {
                  color: '#1ea97c',
                  fontWeight: 'bold',
                  fontSize: 16,
                } : {
                fontWeight: 'bold',
                fontSize: 16,
              }}>{`${parseInt(`${(progress.modules[`${data._id}`].completeds / progress.modules[`${data._id}`].total) * 100}`)}%`}</label>
            </Col>
          ) : null}

          <Col>
            {!plan?.level ? null : course?.level && course.level > plan.level ? null : progress ? (
              !progress.modules[`${data._id}`] ||
              progress.modules[`${data._id}`].completeds === 0 ? (
                <Button 
                  style={{padding: 7, marginLeft: 10}}
                  color="info" 
                  className="animation-on-hover" 
                  onClick={() => {
                    if (progress.modules[`${data._id}`].next_content)
                      navigate(`/go/learn/${progress.modules[`${data._id}`].next_content}/${data.course_id}`);
                  }}
                >
                  {Language(language).Start}
                </Button>

              ) : progress.modules[`${data._id}`].completeds < progress.modules[`${data._id}`].total ? (
                <Button 
                  style={{padding: 7, marginLeft: 10}}
                  color="info" 
                  className="animation-on-hover" 
                  onClick={() => {
                    if (progress.modules[`${data._id}`].next_content)
                      navigate(`/go/learn/${progress.modules[`${data._id}`].next_content}/${data.course_id}`);
                  }}
                >
                  {Language(language).Continue}
                </Button>
              ) : (
                <>
                  <Button 
                    style={{padding: 7, marginLeft: 10}}
                    color="success" 
                    onClick={() => {}}
                  >
                    {Language(language).Concluded}
                  </Button>
                </>
              )

            ) : (<LoadingSpin spin />)}
          </Col>
        </Row>

        {data.description ? (
          <Row>
            <Col>
              <label style={{
                fontSize: 16,
              }}>{data.description}</label>
            </Col>
          </Row>
        ) : null}
        
        {!data.contents ? (
          <Row>
            <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Button 
                disabled={data.loading}
                onClick={() => {
                  loadContents(data._id);

                  setRecords((prev) => {
                    return prev.map((item) => {
                      if (item._id === data._id) return {
                        ...item,
                        loading: true,
                      }

                      return item;
                    })
                  })
                }}
              >
                {data.loading ? (
                  <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem' }} />
                ) : (
                  <i className="pi pi-chevron-down" />
                )}
              </Button>
            </Col>
          </Row>
        ) : renderContents(data.contents)}
      </Card>
    ))
  }

  const renderContents = (contents: iContent[]) => {
    if (contents.length === 0) return(
      <label style={{
        fontWeight: 'bold',
        fontSize: 16,
      }}>{Language(language).ModuleUnderConstruction}</label>
    )

    return contents.map((item) => {
      return (
        <Row style={{marginLeft: 10}}>
          <Col>
            <CardTitle tag="h4" style={
              progress && progress.contents?.includes(item._id) ? {color: '#1ea97c'} : {}
            }>
              {`${item.number} - ${item.name}`}

              {progress && progress.contents?.includes(item._id) ? (
                <>
                  {' '}
                  <i style={{color: '#1ea97c'}} className="pi pi-check" />
                </>
              ) : null}

              {!plan?.level ? null : course?.level && course.level > plan.level ? null : progress ? 
                progress.availables?.includes(item._id) ? (
                  <Button 
                    style={{padding: 7, marginLeft: 10}}
                    color="info" 
                    className="animation-on-hover" 
                    onClick={() => navigate(`/go/learn/${item._id}/${course?._id}`)}
                  >
                    {Language(language).AccessContent}
                  </Button>
                ) : (
                  <span style={{
                    color: 'red',
                  }}>{" - "}{Language(language).Unavailable}</span>
                )
              : (<LoadingSpin spin />)}
            </CardTitle>
          </Col>
        </Row>
      )
    })
  }

  const renderBooks = () => {
    return (
      <Row style={{marginLeft: 10}}>
        {books.map((data) => (
          <view style={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            margin: 5,
            width: '35vmin',
            textAlign: 'center',
          }}>
            <Button 
              style={{
                margin: 5,
                width: '35vmin',
              }}
              color={"info"}
              onClick={
                () => {window.open(data.link)}
              }
            >
              <view style={{display: 'flex', flexDirection: 'column'}}>
                {data.image ? (
                  <img  src={data.image} style={{height: '25vmin', aspectRatio: 2/3, flex: 1}} alt="logo" />
                ) : null}

                <label style={{
                  alignSelf: 'center',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  marginTop: 5,
                }}>{data.name}</label>
              </view>
            </Button>

            {data.description ? (
              <label style={{
                fontSize: 16,
              }}>{data.description}</label>
            ) : null}
          </view>
        ))}
      </Row>
    )
  }

  useEffect(() => {
    loadCourse();
  }, [user?._id]);

  useEffect(() => {
    if (!course?._id) return;

    loadRecords();

    loadProgress();

    loadCertificate();

    loadBooks();

    loadPlans();
  }, [course?._id])
  
  return (
    <>
      <Toast ref={toast} />
      <div className="content">
        <Card className="module-container">
          <CardBody>
            {load || !course ? (<LoadingSpin />) : (
              <>
                <Row>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      {course.image ? (
                        <img src={course.image} style={{
                          width: '35vmin', 
                          aspectRatio: 2/3, 
                          marginLeft: 10,
                        }} alt="logo" />
                      ) : null}
                      {plan?.level && course.level && course.level > plan.level ? (
                        <img src={blockIcon} alt="" className="block-icon-modules" />
                      ) : null}
                    </div>

                    {!plan?.level ? null : course.level && course.level > plan.level ? null : course.school_id ? (
                      <Link style={{width: '100%', margin: 0}} to={course.school_link || ''} target="_blank">
                        <Button 
                          style={{
                            margin: 5,
                            width: '100%',
                            marginTop: '2rem',
                            borderRadius: '5px',
                            background: 'linear-gradient(#511010 100%, #902A2B 100%)',
                            border: '1px solid #CCAA50',
                          }}
                          className="animation-on-hover" 
                          onClick={() => {
                            userAddCourse(user?._id || '', course._id);
                          }}
                        >
                          {Language(language).Access}
                        </Button>
                      </Link>
                    ) : progress ? (
                      progress.completeds === 0 ? (
                        <Button 
                          style={{
                            margin: 5,
                            width: '100%',
                            marginTop: '2rem',
                            borderRadius: '5px',
                            background: 'linear-gradient(#511010 100%, #902A2B 100%)',
                            border: '1px solid #CCAA50',
                          }}
                          className="animation-on-hover" 
                          onClick={() => {
                            userAddCourse(user?._id || '', course._id);
                            
                            if (progress.next_content)
                              navigate(`/go/learn/${progress.next_content}/${course?._id}`);
                          }}
                        >
                          {Language(language).Start}
                        </Button>

                      ) : progress.completeds < progress.total ? (
                        <Button 
                          style={{
                            margin: 5,
                            width: '100%',
                            marginTop: '2rem',
                            borderRadius: '5px',
                            background: 'linear-gradient(#511010 100%, #902A2B 100%)',
                            border: '1px solid #CCAA50',
                          }}
                          className="animation-on-hover" 
                          onClick={() => {
                            if (progress.next_content)
                              navigate(`/go/learn/${progress.next_content}/${course?._id}`);
                          }}
                        >
                          {Language(language).Continue}
                        </Button>
                      ) : (
                        <>
                          {" "}
                          <label style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                          }}>{Language(language).YouCompletedCourse}</label>

                          <Button 
                            style={{padding: 7, marginLeft: 10}}
                            color="success" 
                            className="animation-on-hover" 
                            onClick={() => {}}
                          >
                            {Language(language).Concluded}
                          </Button>
                        </>
                      )

                    ) : (<LoadingSpin spin />)}
                  </div>
                  <Col md='6' style={{marginLeft: 10}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Row style={{
                        marginLeft: 5,
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <label style={{
                          fontWeight: 'bold',
                          fontSize: 20,
                        }}>{course.name}</label>
                      </Row>
                      {progress && progress.completeds > 0 ? (
                        <label style={progress.total === progress.completeds ? {
                          fontWeight: 'bold',
                          color: '#1ea97c',
                          fontSize: 16,
                        } : {
                          fontWeight: 'bold',
                          fontSize: 16,
                        }}>{`${Language(language).Progress}: ${
                          parseInt(`${(progress.completeds / progress.total) * 100}`)
                        }%`}</label>
                      ) : null}
                      <hr />

                      {progress && progress.completeds > 0 && progress.total === progress.completeds ? (
                        <>
                          <Button 
                            style={{padding: 7, marginLeft: 10}}
                            color="primary" 
                            className="animation-on-hover" 
                            onClick={() => setShowCertificate(true)}
                          >
                            {Language(language).IssueCertificate}
                          </Button>
                          <hr />
                        </>
                      ) : null}

                      <label style={{
                        fontSize: 16,
                      }}>{course.description}</label>
                      <hr />

                      {course.level && plan?.level && course.level > plan.level ? (
                        <>
                          <Row>
                            <label style={{
                                fontWeight: 'bold',
                                fontSize: 16,
                            }}>{Language(language).YouNeedLargerPlanToCourse}</label>
                          </Row>
                          <Row>
                            {_renderPlans()}
                          </Row>
                        </>
                      ) : null}

                      {course.url ? (
                        <a href={course.url} style={{
                          fontWeight: 'bold',
                          fontSize: 14,
                        }}>{Language(language).LearnMoreClikHere}</a>
                      ) : null}
                      <hr />

                      <Row>
                        <Col style={{marginLeft: 10}}>
                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            <label style={{
                              fontWeight: 'bold',
                              fontSize: 16,
                            }}>{Language(language).NeedHelpContactCourseSupport}</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {course.email ? (
                          <Col>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              <label style={{
                                fontWeight: 'bold',
                                fontSize: 16,
                              }}>{Language(language).SupportEmail}</label>

                              <label style={{
                                fontSize: 16,
                              }}>{course.email}</label>
                            </div>
                          </Col>
                        ) : null}

                        {course.phone ? (
                          <Col>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              <label style={{
                                fontWeight: 'bold',
                                fontSize: 16,
                              }}>{Language(language).SupportPhone}</label>

                              <label style={{
                                fontSize: 16,
                              }}>{course.phone}</label>
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <hr />
            {course?.school_id ? renderSchoolList() : (
              <>
                <Row style={{marginLeft: 10}}>
                  <CardTitle tag="h3">
                    {Language(language).Modules}
                  </CardTitle>
                </Row>
                {loadModules ? (<LoadingSpin />) : renderModules()}
              </>
            )}

            {loadingBooks ? (<LoadingSpin />) : (
              <>
                {books.length > 0 ? (
                  <Row style={{marginLeft: 10}}>
                    <CardTitle tag="h3">
                      {Language(language).Library}
                    </CardTitle>
                  </Row>
                ) : null}
                {renderBooks()}
              </>
            )}
          </CardBody>
        </Card>
      </div>

      {/* Modal pré-visualização do certificado */}
      <Dialog
        visible={showCertificate}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
          width: '100%',
        }}
        header={Language(language).Certificate}
        modal
        className="p-fluid"
        onHide={() => {
          setShowCertificate(false);
        }}
      >
        {course && user ? (
          <CertificatePreview 
            data={certificate} 
            student={`${user.name} ${user.lastname}`}
            course={course.name}
            hour={course.workload}
            title={course.expert_title}
            expert={course.author}
            print
          />
        ) : (<LoadingSpin />)}
      </Dialog>

      {/* Modal PIX */}
      <Dialog
        visible={!(typeof pix === 'undefined')}
        style={{ 
          backgroundColor: '#393939',
          padding: 5,
          borderRadius: 10,
        }}
        header={"PIX"}
        modal
        className="p-fluid"
        onHide={() => {
          setPix(undefined);
        }}
      >
        <view style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          {pix?.payload ? (
            <img 
              style={{height: '40vmin', width: '40vmin', marginBottom: 30}}
              src={`data:image/jpeg;base64,${pix.payload.point_of_interaction.transaction_data.qr_code_base64}`} 
              alt="QR Code"
            />
          ) : null}

          <label>{Language(language).PayAccessNewNow}</label>
          <Button 
            color="info" 
            className="animation-on-hover" 
            onClick={() => {
              navigator.clipboard.writeText(
                pix.payload.point_of_interaction.transaction_data.qr_code
              );

              toast.current.show({ 
                severity: "success", 
                summary: Language(language).Success, 
                detail: Language(language).QRCodeCopied, 
                life: 3000
              });
            }}
          >
            {Language(language).CopyQRCode}
          </Button>
        </view>
      </Dialog>
    </>
  );
}

export default CourseModulesView;
