export const en: iLanguage = {
  CompleteRegistration: 'Complete your registration',
  Loading: 'Loading...',
  Name: 'Name',
  Lastname: 'Last name',
  Username: 'User name',
  Password: 'Password',
  RetypePassword: 'Retype password',
  Send: 'Send',
  PIXkey: 'PIX key',
  EnterPIXagain: 'Enter the PIX key again',
  InvalidData: 'invalid data',
  AcceptTerms: 'I accept the terms of use.',
  AcceptTermsOfUse: 'Accept the terms of use',
  AcceptThe: 'I accept the ',
  InvalidParameter: 'Invalid parameter!',
  RegisteredSuccess: 'User registered successfully! You can now log in.',
  MembersArea: 'Members Area',
  EmailOrUsername: 'Email or username',
  TypePassword: 'Type your password',
  Enter: 'Enter',
  EnterEmailOrUsername: 'Enter your email or username to log in',
  EnterPasswordToLogin: 'Enter your password to log in',
  SomethingWrongTryAgain: 'Something went wrong! Try again',
  StayConnected: 'Stay connected',
  Wait: 'Wait',
  AboutUs: 'About Us',
  SelectAPlan: 'Select a plan',
  month: 'month',
  Purchase: 'Purchase',
  SomethingWrong: 'Something went wrong!',
  RefreshPage: 'Refresh the page',
  PayAccessNow: 'Pay with PIX and get access to our content right now!',
  PayAccessNewNow: 'Pay with PIX and get access to new content right now!',
  PayContinueAccessNewNow: 'Pay with PIX and continue accessing our content right now!',
  CopyQRCode: 'Copy QR Code',
  QRCodeCopied: 'QR Code copied!',
  Success: 'Success',
  Gains: 'Total Gains',
  Balance: 'Balance',
  TotalAffiliates: 'Total Affiliates',
  Losses: 'Lost Bonus',
  Graduation: 'Graduation',
  CurrentPlan: 'Current Plan',
  Affiliates: 'Affiliates',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  NoAffiliates: 'No affiliates',
  ReferralLink: 'Referral link',
  Copied: 'Copied!',
  ReferralLinkCopied: 'Referral link copied!',
  RequestCashout: 'Request cashout',
  Cancel: 'Cancel',
  Request: 'Request',
  Processing: 'Processing...',
  WithdrawalAmount: 'Withdrawal amount',
  RequestSentWitinXDays: (t: any) => `Solicitação enviada. Enviaremos um PIX em até ${t} dias.`,
  Hi: 'Hi',
  welcome: 'welcome',
  DirectReferrals: 'Direct referrals',
  CreateCourse: 'Create Course',
  EditCourse: 'Edit Course',
  Create: 'Create',
  CourseName: 'Course Name',
  Description: 'Description',
  optional: 'optional',
  DiscoverCourse: 'Discover the course',
  InsertLinkDescribesCourse: 'Insert here a link to a page that describes your course.',
  PageLink: 'Page link',
  Support: 'Support',
  SetContactSupport: 'Set the contact information that will appear for students who need support',
  SupportEmail: 'Support email',
  SupportPhone: 'Support phone',
  Image: 'Image',
  EnterValidName: 'Please enter a valid name',
  EnterValidDescription: 'Enter a valid description',
  NotCreatedCourses: "You haven't created any courses yet. Create a course and become an Expert right now!",
  RecordSaved: 'Record saved',
  Save: 'Save',
  Modules: 'Modules',
  ThisCourseNotHaveModules: 'This course does not yet have modules. Create modules and add content to your course.',
  CreateModule: 'Create Module',
  EditModule: 'Edit Module',
  ModuleName: 'Module Name',
  Number: 'Number',
  RepresentsOrderingModule: 'Represents the ordering of the module. Leave it blank, and the module will be numbered automatically.',
  Content: 'Content',
  EditContent: 'Edit Content',
  CreateContent: 'Create Content',
  ContentName: 'Content Name',
  RepresentsOrderingContent: 'Represents the ordering of the content. Leave it blank, and the content will be numbered automatically.',
  YouTubeID: 'YouTube video ID',
  IfVideoLinkIs: "If the video link is 'https://www.youtube.com/watch?v=fV3u-UotCJI' the video ID would be: 'fV3u-UotCJI'",
  Text: 'Text',
  ExternalLinks: 'External links with content complementary to that of your class.',
  AddLink: 'Add Link',
  Files: 'Files',
  AddUpToFiles: (qt: any, size: any) => `Add up to ${qt} files of maximum ${size}MB each.`,
  NoContentInThisModule: 'There is no content in this module. Add content for your students.',
  Delete: 'Delete?',
  DeleteContentProcessIrreversible: 'Are you sure you want to delete the content? This process is irreversible',
  YesDelete: 'Yes, delete!',
  DeletedRecord: 'Deleted record',
  DeleteModuleProcessIrreversible: 'Are you sure you want to delete the Module and all its contents? This process is irreversible',
  Publish: 'Publish?',
  Courses: 'Courses',
  LearnMoreClikHere: 'Learn more about the course? Click here',
  NeedHelpContactCourseSupport: 'Need help? Contact course support',
  CourseUnderConstruction: 'This course is still under construction. You will be able to access your content soon.',
  ModuleUnderConstruction: 'This module is still under construction. You will be able to access your content soon.',
  Start: 'Start!',
  Continue: 'Continue',
  Certificate: 'Certificate',
  YouCompletedCourse: 'You have completed this course!',
  Concluded: 'Concluded!',
  AccessContent: 'Access content',
  ExtraContent: 'Extra Content',
  YouCompletedModule: 'You have completed this module!',
  Progress: 'Progress',
  TermsUse: 'Terms of Use',
  PreviousClassRequired: 'Previous class required?',
  CheckIfMandatoryPreviousClass: 'Check this option if completion of the previous class is mandatory to release this content',
  Days: 'Days',
  YouCanAlsoDaysSince: 'You can also set the number of calendar days since the completion of the previous class to release this content',
  Unavailable: 'Unavailable',
  ContentNotAvailable: 'This content is not available',
  ReleaseCertificate: 'Release Certificate?',
  NeedChooseCertificateTemplate: 'You need to choose a certificate template',
  CertificateText: (course: string, hour: number | string) => `Who masterfully completed the ${course} course with a ${hour}-hour workload, through the knowledge transmitted by the online platform GO WINNER`,
  Title: 'Title',
  TitleCertificateDesc: 'This is the title that will appear below your name on the certificate. (Teacher, Instructor, etc.)',
  Workload: 'Workload',
  Author: 'Author',
  IssueCertificate: 'Issue Certificate',
  Print: 'Print',
  Library: 'Library',
  CreateBook: 'Create Book',
  EditBook: 'Edit Book',
  BookName: "Book's name",
  SendFile: 'Send file?',
  EnterValidLink: 'Enter a valid link',
  SelectFile: 'Select a file',
  LogOut: 'Log out',
  ForgotPassword: 'Forgot your password?',
  EnterMailORUsername: 'Enter your email OR username',
  EmailORUsername: 'Email OR username',
  EmailWithResetLinkSent: (mail: string) => `An email with a password reset link has been sent to ${mail}`,
  ResetPassword: 'Reset Password',
  PasswordResetSuccess: 'Password reset successfully! You can now log in',
  NewPassword: 'New Password',
  ShowName: 'Show name',
  Access: 'Access!',
  OurPlans: 'Our plans',
  SelectPlan: 'Select Plan',
  ShowMore: 'Show more',
  Remove: 'Remove',
  AreYouSure: 'Are you sure?',
  RemoveCouseFromList: 'Remove this course from your list?',
  YesRemove: 'Yes, remove!',
  Removed: 'Removed',
  YouHaventAccessedCourses: "You haven't accessed any courses yet.",
  MyCourses: 'My Courses',
  PasswordMustBe: 'Your password must be at least 8 digits long',
  Graduate: 'Graduate!',
  YouNeedLargerPlanToCourse: 'You need a larger plan to access this course.',
  Search: 'Search',
  PIXKeyType: 'PIX key type',
  Phone: 'Phone',
  RandomKey: 'Random Key',
  Category: 'Category',
  All: 'All',
  WeeklyRank: 'Weekly Ranking',
  Indications: 'Indications',
  Birthdate: 'Birthdate',
  Profile: 'Profile',
  Heirs: 'Heirs',
  HeirsDesc: 'Name and document of a maximum of 3 people who can inherit your Go Winner account in the event of your death',
  NameAndDocument: 'Name and document',
  RememberMe: 'Remember me',
  Register: 'Register',
  WellcomeVirtualOffice: 'Welcome to your Go Winner Virtual Office!',
  Points: 'Points',
  YourAffiliates: 'Your affiliates',
  XXXAffiliates: (t: string) => `${t} Affiliates`,
  Choose: 'Choose',
  Buy: 'Buy',
  CartItems: (t: number | string) => `Cart - ${t} items`,
  AddedToCart: 'Added to cart!',
  Size: 'Size',
  Color: 'Color',
  SelectAKit: 'Select a Kit',
  Or: 'Or',
  ValueOfProducts: 'Value of products',
  Discount: 'Discount',
  KitValue: 'Kit Value',
  Products: 'Products',
  Accessories: 'Accessories',
  ChooseShipping: 'Choose Shipping',
  NewAddress: 'New Address',
  Address: 'Address',
  EditAddress: 'Edit Address',
  RecipientName: 'Recipient Name',
  Street: 'Street',
  Complement: 'Complement',
  District: 'District',
  City: 'City',
  CEP: 'Zip Code',
  State: 'State',
  AddressSaved: 'Address saved!',
  NaturalPerson: 'Natural Person',
  LegalPerson: 'Legal Person',
  StateRegister: 'State Register',
  Shipping: 'Shipping',
  PickupInStore: 'Pick up in store',
  Confirm: 'Confirm',
  Checkout: 'Checkout',
  FinalValue: 'Final Value',
  StillXKit: (t: string | number) => `There is still ${t} left in discount to close the kit`,
  OurKits: 'Our Kits',
  CurrentKit: 'Current Kit',
  SelectKit: 'Select Kit',
  Free: 'Free',
}

export default en;
