// import React from "react";
import { Route, Routes } from "react-router-dom";
import App from "../App";
import Register from "../pages/register";
import ResetPassword from "../pages/resetPassword";
import Login from "../pages/login";
// import AdminLayout from "../layouts/admin";
import CustomLayout from "../layouts/custom";
import SelectPlan from "../pages/selectPlan";

const Router = () => {
  return (
    <Routes>
      <Route path="/register/:referral" element={<Register />} />
      <Route path="/resetpass/:id/:token" element={<ResetPassword />} />
      <Route path="/login" element={<Login />} />
      <Route path="/selectPlan" element={<SelectPlan />} />
      <Route path="/go/*" element={<CustomLayout />} />
      <Route path="*" element={<App />} />
    </Routes>
  );
}

export default Router;
