export const ptbr: iLanguage = {
  CompleteRegistration: 'Complete seu cadastro',
  Loading: 'Carregando...',
  Name: 'Nome',
  Lastname: 'Sobrenome',
  Username: 'Nome de usuário',
  Password: 'Senha',
  RetypePassword: 'Redigite a senha',
  Send: 'Enviar',
  PIXkey: 'Chave PIX',
  EnterPIXagain: 'Digite a chave PIX novamente',
  InvalidData: 'Dados inválidos',
  AcceptTerms: 'Aceito os termos de uso.',
  AcceptTermsOfUse: 'Aceite os termos de uso',
  AcceptThe: 'Aceito os ',
  InvalidParameter: 'Parametro inválido!',
  RegisteredSuccess: 'Usuário cadastrado com sucesso! Você já pode fazer login.',
  MembersArea: 'Área de Membros',
  EmailOrUsername: 'E-mail ou nome de usuário',
  TypePassword: 'Digite sua senha',
  Enter: 'Entrar',
  EnterEmailOrUsername: 'Informe seu e-mail ou nome de usuário para fazer login',
  EnterPasswordToLogin: 'Digite sua senha para fazer login',
  SomethingWrongTryAgain: 'Algo deu errado! Tente novamente',
  StayConnected: 'Permanecer conectado',
  Wait: 'Aguarde',
  AboutUs: 'Sobre nós',
  SelectAPlan: 'Selecione um plano',
  month: 'mês',
  Purchase: 'Comprar',
  SomethingWrong: 'Algo deu errado!',
  RefreshPage: 'Atualize a página',
  PayAccessNow: 'Pague com PIX e tenha acesso ao nosso conteúdo agora mesmo!',
  PayAccessNewNow: 'Pague com PIX e tenha acesso ao novo conteúdo agora mesmo!',
  PayContinueAccessNewNow: 'Pague com PIX e continue acessando nosso conteúdo agora mesmo!',
  CopyQRCode: 'Copiar QR Code',
  QRCodeCopied: 'Código QR Code copiado!',
  Success: 'Sucesso',
  Gains: 'Ganhos Total',
  Balance: 'Saldo',
  TotalAffiliates: 'Total de Afiliados',
  Losses: 'Bônus Perdido',
  Graduation: 'Graduação',
  CurrentPlan: 'Plano Atual',
  Affiliates: 'Afiliados',
  INACTIVE: 'INATIVO',
  ACTIVE: 'ATIVO',
  NoAffiliates: 'Nenhum afiliado',
  ReferralLink: 'Link de indicação',
  Copied: 'Copiado!',
  ReferralLinkCopied: 'Link de indicação copiado!',
  RequestCashout: 'Solicitar Saque', 
  Cancel: 'Cancelar',
  Request: 'Solicitar',
  Processing: 'Processando...',
  WithdrawalAmount: 'Valor do saque R$',
  RequestSentWitinXDays: (t: any) => `Solicitação enviada. Enviaremos um PIX em até ${t} dias úteis.`,
  Hi: 'Olá',
  welcome: 'seja bem vindo',
  DirectReferrals: 'Indicações diretas',
  CreateCourse: 'Criar Curso',
  EditCourse: 'Editar Curso',
  Create: 'Criar',
  CourseName: 'Nome do Curso',
  Description: 'Descrição',
  optional: 'opcional',
  DiscoverCourse: 'Conheça o curso',
  InsertLinkDescribesCourse: 'Insira aqui o link de uma página que descreve seu curso.',
  PageLink: 'Link da página',
  Support: 'Suporte',
  SetContactSupport: 'Defina as informações de contato que irão aparecer para os alunos que precisarem de suporte',
  SupportEmail: 'E-mail de suporte',
  SupportPhone: 'Telefone de suporte',
  Image: 'Imagem',
  EnterValidName: 'Informe um nome válido',
  EnterValidDescription: 'Informe uma descrição válida',
  NotCreatedCourses: 'Você ainda não criou nenhum curso. Crie um curso e torne-se um Expert agora mesmo!',
  RecordSaved: 'Registro salvo',
  Save: 'Salvar',
  Modules: 'Módulos',
  ThisCourseNotHaveModules: 'Este curso ainda não possui módulos. Crie módulos e adicione conteúdo ao seu curso.',
  CreateModule: 'Criar Módulo',
  EditModule: 'Editar Módulo',
  ModuleName: 'Nome do Módulo',
  Number: 'Número',
  RepresentsOrderingModule: 'Representa a ordenação do módulo. Deixe em branco, e o módulo será numerado automaticamente.',
  Content: 'Conteúdo',
  EditContent: 'Editar Conteúdo',
  CreateContent: 'Criar Conteúdo',
  ContentName: 'Nome do Conteúdo',
  RepresentsOrderingContent: 'Representa a ordenação do conteúdo. Deixe em branco, e o conteúdo será numerado automaticamente.',
  YouTubeID: 'ID do vídeo no YouTube',
  IfVideoLinkIs: "Se o link do vídeo é 'https://www.youtube.com/watch?v=fV3u-UotCJI' o ID do vídeo seria: 'fV3u-UotCJI'",
  Text: 'Texto',
  ExternalLinks: 'Links externos com conteúdos complementares ao da sua aula.',
  AddLink: 'Adicionar Link',
  Files: 'Arquivos',
  AddUpToFiles: (qt: any, size: any) => `Adicione até ${qt} arquivos de no máximo ${size}MB cada.`,
  NoContentInThisModule: 'Não há conteúdo nesse módulo. Adicione conteúdo para seus alunos.',
  Delete: 'Excluir?',
  DeleteContentProcessIrreversible: 'Tem certeza de que quer excluir o conteúdo? Este processo é irreversível',
  YesDelete: 'Sim, excluir!',
  DeletedRecord: 'Registro deletado',
  DeleteModuleProcessIrreversible: 'Tem certeza de que quer excluir o Módulo e todo seu conteúdo? Este processo é irreversível',
  Publish: 'Publicar?',
  Courses: 'Cursos',
  LearnMoreClikHere: 'Saber mais sobre o curso? Clique aqui',
  NeedHelpContactCourseSupport: 'Precisa de ajuda? Entre em contato com o suporte do curso',
  CourseUnderConstruction: 'Este curso ainda está em construção. Você poderá acessar seus conteúdos em breve.',
  ModuleUnderConstruction: 'Este módulo ainda está em construção. Você poderá acessar seus conteúdos em breve.',
  Start: 'Começar!',
  Continue: 'Continuar',
  Certificate: 'Certificado',
  YouCompletedCourse: 'Você completou esse curso!',
  Concluded: 'Concluído!',
  AccessContent: 'Acessar conteúdo',
  ExtraContent: 'Conteúdo Extra',
  YouCompletedModule: 'Você completou esse módulo!',
  Progress: 'Progresso',
  TermsUse: 'Termos de Uso',
  PreviousClassRequired: 'Requer aula anterior?',
  CheckIfMandatoryPreviousClass: 'Marque essa opção se for obrigatório a conclusão da aula anterior para liberar este conteúdo',
  Days: 'Dias',
  YouCanAlsoDaysSince: 'Você também pode definir o número de dias corridos desde a conclusão da aula anterior para liberar este conteúdo',
  Unavailable: 'Indisponível',
  ContentNotAvailable: 'Este conteúdo não está disponível',
  ReleaseCertificate: 'Liberar Certificado?',
  NeedChooseCertificateTemplate: 'Você precisa escolher um modelo de certificado',
  CertificateText: (course: string, hour: number | string) => `Que concluiu com maestria o curso de ${course} com carga horária de ${hour} horas, através dos conhecimentos transmitidos pela plataforma on-line GO WINNER`,
  Title: 'Título',
  TitleCertificateDesc: 'Este é o título que aparecerá abaixo do seu nome no certificado. (Professor, Instrutor, etc)',
  Workload: 'Carga horária',
  Author: 'Autor',
  IssueCertificate: 'Emitir Certificado',
  Print: 'Imprimir',
  Library: 'Biblioteca',
  CreateBook: 'Criar Livro',
  EditBook: 'Editar Livro',
  BookName: 'Nome do Livro',
  SendFile: 'Enviar arquivo?',
  EnterValidLink: 'Informe um link válido',
  SelectFile: 'Selecione um arquivo',
  LogOut: 'Sair',
  ForgotPassword: 'Esqueceu sua senha?',
  EnterMailORUsername: 'Digite seu email OU nome de usuário',
  EmailORUsername: 'Email OU nome de usuário',
  EmailWithResetLinkSent: (mail: string) => `Um email com o link de redefinição de senha foi enviado para ${mail}`,
  ResetPassword: 'Redefinir Senha',
  PasswordResetSuccess: 'Senha redefinida com sucesso! Você já pode fazer login',
  NewPassword: 'Nova Senha',
  ShowName: 'Exibir nome',
  Access: 'Acessar!',
  OurPlans: 'Nossos Planos',
  SelectPlan: 'Selecionar Plano',
  ShowMore: 'Mostrar mais',
  Remove: 'Remover',
  AreYouSure: 'Tem certeza? ',
  RemoveCouseFromList: 'Remover este curso da sua lista?',
  YesRemove: 'Sim, remover!',
  Removed: 'Removido',
  YouHaventAccessedCourses: 'Você ainda não acessou nenhum curso.',
  MyCourses: 'Meus Cursos',
  PasswordMustBe: 'Sua senha precisa ter pelo menos 8 digitos',
  Graduate: 'Graduar!',
  YouNeedLargerPlanToCourse: 'Você precisa de um plano maior para acessar este curso.',
  Search: 'Procurar',
  PIXKeyType: 'Tipo de chave PIX',
  Phone: 'Telefone',
  RandomKey: 'Chave Aleatória',
  Category: 'Categoria',
  All: 'Todos',
  WeeklyRank: 'Ranking Semanal',
  Indications: 'Indicações',
  Birthdate: 'Data de nascimento',
  Profile: 'Perfil',
  Heirs: 'Herdeiros',
  HeirsDesc: 'Nome e documento de no máximo 3 pessoas que podem herdar sua conta Go Winner em caso de falecimento',
  NameAndDocument: 'Nome e documento',
  RememberMe: 'Lembrar-me',
  Register: 'Cadastro',
  WellcomeVirtualOffice: 'Bem-vindo(a) ao seu Escritório Virtual Go Winner!',
  Points: 'Pontos',
  YourAffiliates: 'Seus afiliados',
  XXXAffiliates: (t: string) => `Afiliados de ${t}`,
  Choose: 'Escolher',
  Buy: 'Comprar',
  CartItems: (t: number | string) => `Carrinho - ${t} itens`,
  AddedToCart: 'Adicionado ao carrinho!',
  Size: 'Tamanho',
  Color: 'Cor',
  SelectAKit: 'Selecione um Kit',
  Or: 'Ou',
  ValueOfProducts: 'Valor dos produtos',
  Discount: 'Desconto',
  KitValue: 'Valor do Kit',
  Products: 'Produtos',
  Accessories: 'Acessórios',
  ChooseShipping: 'Escolha o Frete',
  NewAddress: 'Novo Endereço',
  Address: 'Endereço',
  EditAddress: 'Editar Endereço',
  RecipientName: 'Nome do Destinatário',
  Street: 'Rua',
  Complement: 'Complemento',
  District: 'Bairro',
  City: 'Cidade',
  CEP: 'CEP',
  State: 'Estado',
  AddressSaved: 'Endereço salvo!',
  NaturalPerson: 'Pessoa Física',
  LegalPerson: 'Pessoa Jurídica',
  StateRegister: 'Inscrição Estadual',
  Shipping: 'Frete',
  PickupInStore: 'Retirar na Loja',
  Confirm: 'Confirmar',
  Checkout: 'Finalizar Compra',
  FinalValue: 'Valor Final',
  StillXKit: (t: string | number) => `Ainda falta ${t} em desconto para fechar o kit`,
  OurKits: 'Nossos Kits',
  CurrentKit: 'Kit Atual',
  SelectKit: 'Selecionar Kit',
  Free: 'Grátis',
}

export default ptbr;
