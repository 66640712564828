export const Icon = (pros: iProps) => (
  <svg style={pros.transform ? {
    transform: pros.transform,
  } : {}} width="24" height="24" viewBox="0 0 24 24" fill={pros.fill || 'none'} xmlns="http://www.w3.org/2000/svg">
    <path d="M8 19V5L19 12L8 19Z" fill={pros.fill || 'none'}/>
  </svg>
)

interface iProps {
  fill?: string,
  transform?: any,
}
